import React, { } from 'react';
import { AreaLineChart as LineChart } from "../../../components/Charts/index";
import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { dateFormatter } from "../../../utils/common-functions";

const OTIF = (props) => { 
    const { data, isLoading } = props;
    if (isLoading) {
        return (
            <DataLoader
                fullPage={false}
                message="Fetching data"
            />
        );
    }
    if (data?.length === 0) {
        return <NoDataFound message={"OTIF data set is empty!!"} />;
    }
    
    const changeLabelDateFormat = [];
    if (data.x_label.length > 0) {
      for (var index = 0; index < data.x_label.length; index++) {
        const element = dateFormatter(data.x_label[index], 'MM/DD/YY');
        changeLabelDateFormat.push(element);
      }
    }
    
    return (
        <LineChart
            chartLabels={changeLabelDateFormat}
            chartDataSet={
                [
                    {
                        label: 'OT Orders Actual %',
                        data: data.y_ontime_orders,
                        backgroundColor: [
                            'rgba(31, 124, 17, 0.56)',
                        ],
                        borderColor: [
                            'rgb(31, 124, 17)',
                        ],
                        borderWidth: 1,
                        fill: false,
                        yAxisID: 'y'
                    },
                    {
                        label: 'IF Orders Actual %',
                        data: data.y_infull_orders,
                        backgroundColor: [ 'rgba(255, 99, 132, 0.5)' ],
                        borderColor: [ 'rgb(255,99,132)'],
                        borderWidth: 1,
                        fill: false,
                        yAxisID: 'y'
                    },
                    {
                        type: 'bar',
                        label: 'OTIF Actual %',
                        data: data.y_otif_actual,
                        backgroundColor: ['rgba(53, 162, 235, 0.5)'],
                        borderColor: [ 'rgb(53, 162, 235)' ],
                        borderWidth: 1,
                        fill: false,
                        yAxisID: 'y'
                    }
                ]
            }
            showXAxis={true}
            graphTitle="On Time, in Full, OTIF"
            chartHeight={90}
            showSecondaryY={false}
        />
    );

}
export default OTIF;