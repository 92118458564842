//Imported moment for date conversion
import moment from 'moment-timezone';
import utcmoment from "moment"

//05 Jan 2023 Set default time zone to UTC + 0 which is equivalent to Europe/London
const setTimeZone = "Europe/London"

//Set default time zone to Eastern Standard Time in Parsippany-Troy Hills, NJ, USA (GMT-5)
//moment.tz.setDefault("America/New_York")
//moment.tz.setDefault("Asia/Kolkata")   

/*****
 * This function is used to sanitize array object so that it won't throw any error in UI
 */
export const cleanArrObject = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const removeItemOnce = (arr, value) => {
  // var index = arr.indexOf(value);
  // if (index > -1) {
  //   arr.splice(index, 1);
  // }
  // return arr;
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
};

/*****
 * This function is used to return array of code instead of array object with name, code
 */
export const fn_code_array = (arr) => {
  var newArr = [];
  arr.length > 0 &&
    arr.map((obj) => {
      if (obj.code !== null) {
        newArr.push(obj.code);
      }
    });
  return newArr;
};

/*****
 * This function is used to encode URL before sending data to API URL
 */
export const urlEncoder = (str) => {
  return encodeURIComponent(JSON.stringify(str));
};

/*****
 * This function is used to return date in specified format
 */
export const dateFormatter = (postDate, format) => {
  // 05 Jan 2023 : Date Modified From America Newyork to UTC+0 which is equivalent to Europe/London - based on Ticket RBCPFR2-93  
  return moment(postDate).format(format);
};

/*****
 * This function is used to return get all dates needed for last saturday computation
 */
export const getDates = () => {
  ////////Moment Date to get last saturday based on Eastern Standard Time in Parsippany-Troy Hills, NJ, USA (GMT-5)
  ////////const todayWithTime = moment().tz(setTimeZone);
  const today = moment().tz(setTimeZone).format("YYYY-MM-DD");
  const isTodaySaturday = moment().tz(setTimeZone).day() == 6; 
  const findLastSaturday = moment().tz(setTimeZone).endOf('week').subtract(7, 'days').format("YYYY-MM-DD");
  //////// If today is saturday then last saturday should be today. 
  //////// The above code findLastSaturday does not handle this logic. So, even if today is saturday it fetches the previous saturday. 
  //////// Handle the above issue with below code  
  const lastSaturday = isTodaySaturday ? today : findLastSaturday;
  //////// This date used to find the nth week of the last saturday
  // 27 March 2023 Date picker not supporting tz(setTimeZone). Changes made to accomodate
  const getLastSaturdayDatepicker = moment().tz(setTimeZone).endOf('week').subtract(7, 'days'); 
  
  return { lastSaturday, getLastSaturdayDatepicker };
}

export const todayDate = () => {  
  const { lastSaturday } = getDates()
  //Important uncomment the below line when you want to remove hardcoding date
  //return lastSaturday;   

  ///////////////////////////////////////////////////////////////////
  //Important remove this line . Date hard coded for urpose of demo
  let lastSaturdayTemp = '2023-02-04'
  return lastSaturdayTemp;
  //Important remove above line . Date hard coded for urpose of demo
  ///////////////////////////////////////////////////////////////////
};

export const todayDatePicker = () => {  
  //const { getLastSaturdayDatepicker } = getDates()
  //const  getLastSaturdayDatepicker = moment().tz(setTimeZone).endOf('week').subtract(7, 'days').toDate();
  // 27 March 2023 Date picker not supporting tz(setTimeZone). Changes made to accomodate
  const  getLastSaturdayDatepicker = moment().tz(setTimeZone).endOf('week').subtract(7, 'days')//.toDate();
  
  //Important uncomment below if hardcoding removed
  //return convertDateToUTC(getLastSaturdayDatepicker);    
  
  ///////////////////////////////////////////////////////////////////
  //Important remove below line . Date hard coded for urpose of demo
  return convertDateToUTC(moment('2023-02-05').tz(setTimeZone));
  //Important remove above line . Date hard coded for urpose of demo
  ///////////////////////////////////////////////////////////////////
};

// 28 March 2023 Fix Date picker to show date based on Europe/London. 
// Issue was : Datepicker showed different local dates based on the user accessing from, whereas the payload passed the correct date as saturday
export const convertDateToUTC = (date) => {  
  date = new Date(date);
  date = new Date(date.getUTCFullYear(),date.getUTCMonth(),date.getUTCDate());
  return date;
}

/***** 
 * This is used to return last nth week date in YMD format
 */
export const getLastnthWeeksDate = (wkNumber) => {
  const nthWk = parseInt(wkNumber) * 7;
  const { getLastSaturdayDatepicker } = getDates();
  let dateOfLast13thWeek = moment(getLastSaturdayDatepicker).tz(setTimeZone).subtract(nthWk, 'days').startOf('day').format("YYYY-MM-DD");
  
  ///////////////////////////////////////////////////////////////////
  //Important remove this line . Date hard coded for urpose of demo
  dateOfLast13thWeek = '2022-11-01'
  //Important remove above line . Date hard coded for urpose of demo
  ///////////////////////////////////////////////////////////////////
  
  return dateOfLast13thWeek;
};

export const getLastnthWeeksDatePicker = (wkNumber) => {
  const nthWk = parseInt(wkNumber) * 7;
  const { getLastSaturdayDatepicker } = getDates();
  //let dateOfLast13thWeek = moment(getLastSaturdayDatepicker).tz(setTimeZone).subtract(nthWk, 'days').startOf('day');
  // 27 March 2023 Date picker not supporting tz(setTimeZone). Changes made to accomodate
  let dateOfLast13thWeek = moment(getLastSaturdayDatepicker).tz(setTimeZone).subtract(nthWk, 'days').startOf('day')//.toDate();
  //Important Uncomment below line if no hardcoding needed
  //return convertDateToUTC(dateOfLast13thWeek);
  
  ///////////////////////////////////////////////////////////////////
  //Important remove below line . Date hard coded for urpose of demo
  return convertDateToUTC(moment('2022-11-02').tz(setTimeZone));
  //Important remove above line . Date hard coded for urpose of demo
  ///////////////////////////////////////////////////////////////////
  
};

/*****
 * This function is used to return number with 1000 comma separator
 */
export const numberWithCommas = (x) => {
  if (x === null || x === undefined)
    return 0;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/*****
 * This function is used to return number with 1000 comma separator for DATATABLE Items
 */
export const DT_CellFormatterWithComma = (cell, row) => {
  const intNum = convertDecToInt(cell);
  return intNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const convertDecToInt = (x) => {
  return Math.trunc(x);
};

/*****
 * This function is used to add % to the columns
 */
export const DT_CellFormatterWithPerc = (cell, row) => {
  if (typeof cell !== 'undefined')
    if (typeof cell === 'string') {
      return ((typeof cell === 'string') ? cell + "%" : cell?.toString() + "%");
    } else if (typeof cell === 'number') {
      return cell?.toFixed(1) + "%";
    }
};

export const DT_CellFormatterFixPrecision = (cell, row) => {
  if (typeof cell !== 'undefined')
    if (typeof cell === 'string') {
      return ((typeof cell === 'string') ? cell : cell?.toString());
    } else if (typeof cell === 'number') {
      return cell?.toFixed(1);
    }
};

// Jan-10-2023 Remove decimals in all POS dollar occurances
export const DT_CellFormatterWithDollar = (cell, row) => {
  if (typeof cell !== 'undefined') {
    const intNum = (typeof cell === 'string' && !undefined) ? convertDecToInt(cell) : convertDecToInt(cell);
    const str = intNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return ("$" + str);
  }
};

// export const DT_CellFormatterWithDollar = (cell, row) => {
//   if (typeof cell !== 'undefined') {
//     const intNum = (typeof cell === 'string' && !undefined) ? convertDecToInt(cell) : convertDecToInt(cell);
//     const str = intNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     return ("$" + str);
//   }
// };

/*****
 * This function is used to return number with M ( million ), K ( thousands ).
 */
export const numFormatterForChart = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M "; // convert to M for number from > 1 million
  } else if (num < 900) {
    // return num; // if value < 1000, nothing to do
    return num + " ";
  }
};
