import React, { Component } from 'react';
import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";

import PivotTableUI from "../../../components/react-pivottable/PivotTableUI";
import PivotTable from "../../../components/react-pivottable/PivotTable";
class PivotTableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideConstructor: true,
            pivotTableState: {
                rows: [
                    "Customer",
                    "Bu",
                    "Brand",
                    "Segment"
                ],
                cols: ["KPIs"],
                aggregatorName: "Sum",
                vals: ['colVal'],
                rowKeys: [
                    "Customer"
                ]
            }
        };
    }
    render() {

        const TableComponent = this.state.hideConstructor
            ? PivotTable
            : PivotTableUI;
        return (
            <div className='react-bootstrap-table'>

                <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between">
                        <h4 className="card-title">Pivot Table View</h4>
                        <span className="card-description ml-1">
                            (Latest Week)
                        </span>
                    </div>
                    <button
                        onClick={() =>
                            this.setState({ hideConstructor: !this.state.hideConstructor })
                        }
                        className='btn btn-gradient-info label-right font-weight-bold px-lg-4 px-3'
                    >
                        {this.state.hideConstructor ? 'Enable' : 'Disable'} Drag/Drop Elements
                    </button>
                </div>
                {(this.props.isLoading) ? (
                    <DataLoader
                        fullPage={false}
                        message="Fetching data"
                    />
                ) : (
                    (this.props.data?.length === 0) ? (
                        <div className='pvtTable'>
                            <NoDataFound message={"There is no data for selected filters!"} />
                        </div>
                    ) : (
                        <TableComponent
                            data={this.props.data}
                            onChange={s => this.setState({ pivotTableState: s })}
                            {...this.state.pivotTableState}
                        />
                    )
                )}
            </div>
        );
    }

}
export default PivotTableComponent;