/*
PieChart is used to display Doughnut chart

This component can handle below props:
- chartHeading
- chartDataSet
- chartLabels

Currently this component got imported in below pages:
/src/js/screens/Otif/Components/InFullAccountability.js
/src/js/screens/Otif/Components/OnTimeAccountability.js
*/

import React, { Component } from 'react'
// Import chart Js
import {
    Chart as Chartjs,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';



Chartjs.register(ArcElement, Tooltip,
    Legend,
    Title);


export default function PieChart(props) {
    const { chartHeading, chartDataSet, chartLabels } = props;
    const options = {
        responsive: true,
        plugins: {
            title: {
                display: false,
                text: chartHeading,
            },
            legend: {
                display: true,
                position: "bottom"
              }
        },
    };


    const chartData = {
        labels: chartLabels,
        datasets: chartDataSet,
    };

    return (
        <Doughnut data={chartData} options={options} />
    )
}