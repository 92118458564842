import { commonTypes } from "../types/commonTypes";
import { forecastTypes } from "../types/forecastTypes";
import { POST } from "../../utils/axios";
import { getSocketStatus } from './socketActions';

// Import data & utils
// import forecastData from "../../../assets/data/forecast.json";
// import mbDistributionData from "../../../assets/data/mbDistribution.json";
// import natData from "../../../assets/data/nat.json";
// import forecastDistributionDataJson from "../../../assets/data/forecastDistribution.json";

const setLoad = (isLoadingRequestIds) => {
  return {
    type: commonTypes.SET_LOADING,
    payload: isLoadingRequestIds,
  };
};

//forecast KPI data
export const getForecastKpiData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isForecastKpiDataLoaded: true }));
    const apiRes = await getForecastKpiDataReq(postData);
    dispatch(setForecastKpiData(apiRes));
    dispatch(setLoad({ isForecastKpiDataLoaded: false }));
  };
};
const getForecastKpiDataReq = async (postData) => {
  try {
    const apiRes = await POST(`/forecastkpi`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getForecastKpiDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("Forecast KPI ERROR: ", err);
    return [];
  }
};
export const setForecastKpiData = (forecastKpiData) => {
  return {
    type: forecastTypes.SET_FORECAST_KPI_DATA,
    payload: forecastKpiData,
  };
};

//forecast graph data
export const getForecastData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isForecastdataLoaded: true }));
    const apiRes = await getForecastDataReq(postData);
    dispatch(setForecastData(apiRes));
    dispatch(setLoad({ isForecastdataLoaded: false }));
  };
};
const getForecastDataReq = async (postData) => {
  try {
    const apiRes = await POST(`/forecast`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getForecastDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("Forecast Graph ERROR: ", err);
    return [];
  }
};
export const setForecastData = (forecastChartData) => {
  return {
    type: forecastTypes.SET_FORECAST,
    payload: forecastChartData,
  };
};

// //mb distribution graph data
// export const getMbDitributionData = ( ) => {
//     return async ( dispatch ) => {
//         dispatch(setLoad({ "isMbDistributionLoading": true }));
//         const mbDistributionData = await getMbDitributionDataReq();
//         setTimeout(() => {
//             dispatch(setMbDitributionData(mbDistributionData));
//             dispatch(setLoad({ "isMbDistributionLoading": false }));
//         }, 2000)

//     }
// }
// const getMbDitributionDataReq = async () => {
//     try {
//         //const forecastData = await GET(`/mb-distribution`);
//         if(mbDistributionData.status.statusCode === "200" ){
//             return mbDistributionData.data;
//         }
//         return [];
//     } catch (err) {
//         return [];
//     }
// }
// export const setMbDitributionData = ( mbDistributionChartData ) => {
//     return {
//         type: forecastTypes.SET_MB_DISTRIBUTION,
//         payload: mbDistributionChartData
//     }
// }

// //NAT data
// export const getNatData = () => {
//     return async ( dispatch ) => {
//         dispatch(setLoad({ "isNatLoading": true }));
//         const NatData = await getNatDataReq();
//         dispatch(setNatData(NatData));
//         dispatch(setLoad({ "isNatLoading": false }));
//     }
// }
// const getNatDataReq = async () => {
//     try {
//         //const forecastData = await GET(`/mb-distribution`);
//         if(natData.status.statusCode === "200" ){
//             return natData.data;
//         }
//         return [];
//     } catch (err) {
//         return [];
//     }
// }
// const setNatData = ( NatData ) => {
//     return {
//         type: forecastTypes.SET_BIAS_MAPE,
//         payload: NatData
//     }
// }

//forecast distribution data
export const getForecastDitributionData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isForecastDistributionLoading: true }));
    const apiRes = await getForecastDitributionDataReq(postData);
    dispatch(setForecastDitributionData(apiRes));
    dispatch(setLoad({ isForecastDistributionLoading: false }));
  };
};
const getForecastDitributionDataReq = async (postData) => {
  try {
    const apiRes = await POST(`/forecastDistribution`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    }
    else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getForecastDitributionDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("Forecast Distribution ERROR: ", err);
    return [];
  }
};
export const setForecastDitributionData = (forecastDistributionData) => {
  return {
    type: forecastTypes.SET_FORECAST_DISTRIBUTION,
    payload: forecastDistributionData,
  };
};

export const getForecastDataForExport = async (postData) => {
  try {
    const apiRes = await POST(`/forecastDataForExport`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getForecastDataForExport(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("ForecastDataForExport ERROR: ", err);
    return [];
  }
};

export const getForecastBrandDataForExport = async (postData) => {
  try {
    const apiRes = await POST(
      `/forecastBrandDataForExport `,
      postData
    );
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getForecastBrandDataForExport(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("ForecastBrandDataForExport ERROR: ", err);
    return [];
  }
};

export const getForecastSegmentDataForExport = async (postData) => {
  try {
    const apiRes = await POST(`/forecastSegmentDataForExport `, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getForecastSegmentDataForExport(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("ForecastSegmentDataForExport ERROR: ", err);
    return [];
  }
};

export const setSearchValues = (searchValues) => {
  return {
    type: forecastTypes.SET_SEARCH_VALUES,
    payload: searchValues,
  };
};
