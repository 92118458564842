import React, { Component } from 'react';
import { Formik } from "formik";
import { Link, Redirect } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import logo from "../../../assets/images/logo.svg";
import { login } from "../../redux/actions/authActions";


export default function Login() {
    const dispatch = useDispatch();
    const authState = useSelector(({ authReducer }) => authReducer);
    if (authState.isLoggedIn) {
        return <Redirect to="/home" />;
    }
    return (
        <Formik
            initialValues={{
                username: '',
                password: ''
            }}
            validate={(values) => {
                const errors = {};
                if (!values.username) {
                    errors.username = "This field is required!";
                }
                if (!values.password) {
                    errors.password = "This field is required!";
                }
                return errors;
            }}
            onSubmit={(values) => {
                const { username, password } = values;
                var formData = { username, password };
                dispatch(login(formData)).then(() => {

                }).catch(() => {

                });
            }}
            onReset={(values) => { }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
            }) => (
                <div>
                    <div className="d-flex align-items-center auth px-0">
                        <div className="row w-100 mx-0">
                            <div className="col-lg-4 mx-auto">
                                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                    <div className="brand-logo">
                                        <img src={logo} alt="logo" />
                                    </div>
                                    <h4>Hello! let's get started</h4>
                                    <h6 className="font-weight-light">Sign in to continue.</h6>
                                    <form className="form-sample" onSubmit={handleSubmit}>
                                        <div className='form-group'>
                                            <input
                                                type="text"
                                                name="username"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.username}
                                                placeholder="Username"
                                                className="form-control"
                                            />
                                            <p className="error">
                                                {errors.username && touched.username && errors.username}
                                            </p>
                                        </div>
                                        <div className='form-group'>
                                            <input
                                                type="password"
                                                name="password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                                placeholder="password"
                                                className="form-control"
                                            />
                                            <p className="error">
                                                {errors.password && touched.password && errors.password}
                                            </p>
                                        </div>

                                        <div className='form-group'>
                                            <button type="button"
                                                onClick={() => handleSubmit()}
                                                className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                                disabled={authState.isLoading}
                                            >
                                                {authState.isLoading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                <span>SIGN IN</span>

                                            </button>
                                        </div>
                                        {authState.message && (
                                            <div className="form-group">
                                                <div className="alert alert-danger" role="alert">
                                                    {authState.message}
                                                </div>
                                            </div>
                                        )}
                                        <div className="my-2 d-flex justify-content-between align-items-center">
                                            <div className="form-check">
                                                <label className="form-check-label text-muted">
                                                    <input type="checkbox" className="form-check-input" />
                                                    <i className="input-helper"></i>
                                                    Keep me signed in
                                                </label>
                                            </div>
                                            <a href="!#" onClick={event => event.preventDefault()} className="auth-link text-black">Forgot password?</a>
                                        </div>
                                        <div className="text-center mt-4 font-weight-light">
                                            Don't have an account? <Link to="/user-pages/register" className="text-primary">Create</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    )
}