/*
DcList is a single select dropdown used to list Dcs from which user can select a brand

This component can handle below props:
- onUpdate
- localRef
- error

Currently this component got imported in below pages:
/src/js/screens/Cockpit/Filters/GlobalFilters.js
/src/js/screens/Instock/Filters/InStockGlobalFilters.js
/src/js/screens/Forecast/Filters/ForecastFilters.js
/src/js/screens/Otif/Filters/GlobalFilter.js
*/

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  setDefaultDcList,
  setDefaultSearchValues,
} from "../../redux/actions/masterActions";
import { fn_code_array } from "../../utils/common-functions";
import ReactSelect from "./ReactSelect";
import { Option, MultiValue } from "./CommonFn";
import { WindowedMenuList } from "react-windowed-select";

export default function DcList({ onUpdate, localRef, error }) {
  const dcList = useSelector(({ masterReducer }) => masterReducer.dcList);
  const dcDefaultList = useSelector(
    ({ masterReducer }) => masterReducer.dcDefaultList
  );
  const isDataLoading = useSelector(
    ({ masterReducer }) => masterReducer.isLoadingRequestIds
  );
  const [SelectedVal, setSelectedVal] = useState([]);
  const dispatch = useDispatch();
  return (
    <Form.Group className="row">
      <div className="col-sm-12">
        <ReactSelect
          options={dcList.filter((elements) => {
            return elements.code != null;
          })}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
            //MultiValue,
            MenuList: WindowedMenuList,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onMenuClose={(isMenuClose) => { }}
          onChange={(val) => {
            setSelectedVal(val);
            onUpdate && onUpdate(val.length > 0 ? val : "");
            dispatch(setDefaultDcList(val));

            const setDefaultSearchVal = { dc: fn_code_array(val) };
            dispatch(setDefaultSearchValues(setDefaultSearchVal));
          }}
          allowSelectAll={true}
          value={dcDefaultList}
          isLoading={isDataLoading.includes("isDcDataLoaded") ? true : false}
          placeholder="Choose a DC..."
          localRef={localRef}
        />
        <div className="error">{error}</div>
      </div>
    </Form.Group>
  );
}
