import React, {  } from 'react'
import { HorizontalBarChart } from "../../../components/Charts/index";
import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";

const SalesBySku = ( props ) => {
    const { data, isLoading } = props;
    if( isLoading ) {
        return (
            <DataLoader
                fullPage={false}
                message="Fetching data"
            />
        );
    }
    if (data?.length === 0) {
        return <NoDataFound message={"There is no data for selected filters!"} />;
    }
    return(
        <div >
            <HorizontalBarChart
                Min={(data.Min)?data.Min:''}
                Max={(data.Max)?data.Max:''}
                chartLabels={data.x_label}
                chartDataSet={data.y_label}
                salesType="sku"
            />
        </div>
    );
}
export default SalesBySku;