import React, { Component } from "react";

// Import redux
import { connect } from "react-redux";

// Import custom components
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import OtifGlobalFilter from "./Filters/OtifGlobalFilter";
import DateRangeFilter from "./Filters/DateRangeFilter";
import OTIFTopBottomFilter from "./Filters/OtifTopBottomFilter";

// Import Child Components
import OnTimeAccountability from "./Components/OnTimeAccountability";
import OnTimeHistogram from "./Components/OnTimeHistogram";
import InFullAccountability from "./Components/InFullAccountability";
import InFullHistogram from "./Components/InFullHistogram";
import OTIF from "./Components/OTIF";

class Otif extends Component {
  state = {};
  componentDidMount() {}

  handleChange = (date) => {
    this.setState({ startDate: date });
  };
  
  render() {
    return (
      <div className="row">
        <div className="col-md-12 grid-margin">
          <OtifGlobalFilter />
        </div>
        <div className="col-lg-12 grid-margin  stretch-card">
          <div className="card border-bottom">
            <div className="card-body">
              <div className="header-sm d-flex justify-content-between align-items-center">
                <div className="header-sm d-flex justify-content-start align-items-center">
                  <h4 className="card-title">On Time Accountability</h4>
                  <div className="align-items-center ml-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="align-items-center">
                        <h3 className="font-weight-medium text-success">
                          {this.props.otifObj.onTimeStats.ontime_percentage
                            ? this.props.otifObj.onTimeStats.ontime_percentage
                            : "NA"}
                        </h3>
                      </div>
                      <p className="mb-1 text-dark">
                        <i className="mdi mdi-truck-delivery text-primary icon-sm"></i>{" "}
                        ON TIME
                      </p>
                    </div>
                    <div className="align-items-center row">
                        <div className="col-md-12">
                          <h6 className="text-dark">
                          {this.props.otifObj.onTimeStats.percentage_diff
                            ? this.props.otifObj.onTimeStats.percentage_diff
                            : "NA"}{" "}
                          <small> verses LW </small>
                        </h6>
                    </div>
                    </div>
                  </div>
                </div>
                <OTIFTopBottomFilter
                  handleInvetoryDD={(val) => this.handleInvetoryDD(val)}
                />
              </div>
              <div class = "row">
                 <div class="col-md-8">
                  <ErrorBoundary>
                      <OnTimeAccountability
                        data={this.props.otifObj.ontimeaccountability}
                        isLoading={this.props.otifObj.isLoadingRequestIds.includes(
                          "isOnTimeAccountabilityDataLoaded"
                        )}
                        data3={this.props.otifObj.ontimeBarChartData}
                        data3_isLoading={this.props.otifObj.isLoadingRequestIds.includes(
                          "isOntimeBarChartLoading"
                        )}
                      />
                  </ErrorBoundary>
                 </div>
                 <div class="col-md-4">
                    <ErrorBoundary>
                        <OnTimeHistogram
                          data={this.props.otifObj.ontimeaccountability}
                          isLoading={this.props.otifObj.isLoadingRequestIds.includes(
                            "isOnTimeAccountabilityDataLoaded"
                          )}
                          data3={this.props.otifObj.ontimeBarChartData}
                          data3_isLoading={this.props.otifObj.isLoadingRequestIds.includes(
                            "isOntimeBarChartLoading"
                          )}
                        />
                      </ErrorBoundary>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 grid-margin stretch-card ">
          <div className="card">
            <div className="card-body">
              <div className="header-sm d-flex justify-content-start align-items-center">
                <h4 className="card-title mb-0">In Full Accountability</h4>
                <div className="align-items-center ml-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="align-items-center">
                      <h3 className="font-weight-medium text-success">
                        {this.props.otifObj.inFullStats.infull_percentage
                          ? this.props.otifObj.inFullStats.infull_percentage
                          : "NA"}
                      </h3>
                    </div>
                    <p className="mb-1 text-dark">
                      <i className="mdi mdi-truck-delivery text-primary icon-sm"></i>{" "}
                      IN FULL
                    </p>
                  </div>
                  <div className="align-items-center">
                    <h6 className="text-dark">
                      {this.props.otifObj.inFullStats.percentage_diff
                        ? this.props.otifObj.inFullStats.percentage_diff
                        : "NA"}{" "}
                      <small> verses LW </small>
                    </h6>                    
                  </div>
                </div>
                {/* <OTIFTopBottomFilter handleInvetoryDD={
                                    (val) => this.handleInvetoryDD(val)
                                } /> */}
              </div>
              <div class = "row">
                 <div class="col-md-8">
                    <ErrorBoundary>
                        <InFullAccountability
                          data={this.props.otifObj.infullaccountability}
                          isLoading={this.props.otifObj.isLoadingRequestIds.includes(
                            "isInFullAccountabilityDataLoaded"
                          )}
                          data3={this.props.otifObj.infullBarChartData}
                          data3_isLoading={this.props.otifObj.isLoadingRequestIds.includes(
                            "isInfullBarChartLoading"
                          )}
                    />
                    </ErrorBoundary>
                 </div>
                 <div class="col-md-4">
                    <ErrorBoundary>
                        <InFullHistogram
                          data={this.props.otifObj.infullaccountability}
                          isLoading={this.props.otifObj.isLoadingRequestIds.includes(
                            "isInFullAccountabilityDataLoaded"
                          )}
                          data3={this.props.otifObj.infullBarChartData}
                          data3_isLoading={this.props.otifObj.isLoadingRequestIds.includes(
                            "isInfullBarChartLoading"
                          )}
                    />
                    </ErrorBoundary>
                 </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">On Time, In Full, OTIF</h4>
              <DateRangeFilter />
              <ErrorBoundary>
                <OTIF
                  data={this.props.otifObj.OTIFChartData}
                  isLoading={this.props.otifObj.isLoadingRequestIds.includes(
                    "isOTIFChartDataLoaded"
                  )}
                />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    otifObj: state.otifReducer,
    analystLandingObjInExec: state.analystLandingReducer,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Otif);
