import React from "react";
import { useDispatch } from "react-redux";
import GlobalFilter from "../../../components/Filter/GlobalFilter";
import {
  getMissingProdMapData,
  getMissingStoreMapData,
  getInactiveStoreSalesData,
  getInactiveStoreInventoryData,
  setGlobalSearchValues,
} from "../../../redux/actions/alertActions";

import {
  cleanArrObject,
} from "../../../utils/common-functions";

export default function AlertGlobalFilters() {
  const dispatch = useDispatch();
  const processFilterData = (values) => {
    values = { region: values.regionMulti, customer: values.customerMulti };
    dispatch(setGlobalSearchValues(values));
    
    // // //get  Errors and Alerts Table
    //Important Uncomment to call all APIs
    // dispatch(getMissingProdMapData(values));
    // dispatch(getMissingStoreMapData(values));
    // dispatch(getInactiveStoreSalesData(values)); 
    // dispatch(getInactiveStoreInventoryData(values)); 
  };

  const resetFilterData = (values) => {
    // dispatch(getMissingProdMapData([]));
    // dispatch(getMissingStoreMapData([]));  
    // dispatch(getInactiveStoreSalesData([])); 
    // dispatch(getInactiveStoreInventoryData([])); 

    //Feb 2 2023
    values = { region: values?.regionMulti, customer: values?.customerMulti };
    dispatch(setGlobalSearchValues(values));    
    //Important Uncomment to call all APIs
    // // //get  Errors and Alerts Table
    // dispatch(getMissingProdMapData(values));
    // dispatch(getMissingStoreMapData(values));
    // dispatch(getInactiveStoreSalesData(values)); 
    // dispatch(getInactiveStoreInventoryData(values));  
  };

  return (
    <GlobalFilter
      filterPage={"alerts"}
      onFormSubmit={(formData) => processFilterData(formData)}
      onFormReset={(formData) => resetFilterData(formData)}
      fields={["regionMultiList", "customerMultiList"]}
      //fields={["region", "customer"]}
    />
  );
}
