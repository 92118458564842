import { commonTypes } from "../types/commonTypes";
import { forecastTypes } from "../types/forecastTypes";
import { removeItemOnce } from '../../utils/common-functions';

export default function (state = {}, action) {

  switch (action.type) {
    case commonTypes.SET_LOADING:
      var key = Object.keys(action.payload)[0];
      var value = action.payload[Object.keys(action.payload)[0]];
      return {
        ...state,
        isLoadingRequestIds: value ? [...state.isLoadingRequestIds, key] : removeItemOnce(state.isLoadingRequestIds, key) //state.isLoadingRequestIds.splice(state.isLoadingRequestIds.indexOf(key)).slice()
      };
    case forecastTypes.SET_FORECAST_KPI_DATA:
      return {
        ...state,
        forecastKpiData: action.payload,
      }
    case forecastTypes.SET_FORECAST:
      return {
        ...state,
        forecastChartData: action.payload,
      }
    case forecastTypes.SET_MB_DISTRIBUTION:
      return {
        ...state,
        mbDistributionChartData: action.payload,
      }
    case forecastTypes.SET_BIAS_MAPE:
      return {
        ...state,
        biasMapeData: action.payload,
      }
    case forecastTypes.SET_FORECAST_DISTRIBUTION:
      return {
        ...state,
        forecastDistributionData: action.payload,
      }
    case forecastTypes.SET_SEARCH_VALUES:
      return {
        ...state,
        forecastSearchValues: action.payload,
      }
    default:
      return state;
  }
}
