import React, { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalystPOSChartData,
  getAnalystOOSChartData,
  getAnalystOTIFChartData,
  getAnalystKpiData,
  getAnalystLandingAggregateData,
} from "../../../redux/actions/analystLandingActions";
import { setDateRangeSearchValues } from "../../../redux/actions/executivePageActions";
import {
  fn_code_array,
  todayDate,
  getLastnthWeeksDate,  
  getLastnthWeeksDatePicker,
  todayDatePicker,
  cleanArrObject,
  dateFormatter,
} from "../../../utils/common-functions";
import SkuList from "../../../components/DropdownList/SkuList";

export default function InventoryFilters(props) {
  const { filterTitle } = props;
  
  // const [fromDate, setfromDate] = useState(new Date(getLastnthWeeksDate(13)));
  // const [toDate, settoDate] = useState(new Date(todayDate()));

  //27 march 2023 Datepicker not suuporting moment js. Changes are added to test the behaviour of date in datepicker
  const [fromDate, setfromDate] = useState(getLastnthWeeksDatePicker(13));
  const [toDate, settoDate] = useState(todayDatePicker());

  const analystSearchValues = useSelector(
    ({ analystLandingReducer }) =>
      analystLandingReducer.analystGlobalSearchValues
  );
  const executiveSearchValues = useSelector(
    ({ executivePageReducer }) =>
      executivePageReducer.executiveGlobalSearchValues
  );
  //const executiveDateSearchValues = useSelector(({ executivePageReducer }) => executivePageReducer.executiveDateRangeSearchValues);
  const dispatch = useDispatch();
  const skuTypeheadRef = useRef();

  const myCustomhandleResetFn = () => {
    setfromDate("");
    settoDate("");
    skuTypeheadRef.current.select.select.clearValue();
    const applyDefaultFilter = {
      ...executiveSearchValues,
      from_date: getLastnthWeeksDate(13),
      to_date: todayDate(),
    };
    dispatch(setDateRangeSearchValues({ from_date: "", to_date: "" }));
    
    //Important 27 April 2023 commented the dispatch method as these are calling dremio queries 
    //UnComment when connected to connector dataource 
    // dispatch(getAnalystPOSChartData(applyDefaultFilter));
    // dispatch(getAnalystOOSChartData(applyDefaultFilter));
    // dispatch(getAnalystOTIFChartData(applyDefaultFilter));
  };

  return (
    <Formik
      initialValues={{
        from_date:getLastnthWeeksDate(13),
        to_date: todayDate(),
      }}
      validate={(values) => {
        const errors = {};
        const fromDateNew = new Date(values.from_date);
        const toDateNew = new Date(values.to_date);
                
        if (!values.from_date) {
          errors.from_date_error = "This field is required!";
        }
        if (!values.to_date) {
          errors.to_date_error = "This field is required!";
        }
        if (
          values.from_date !== "" &&
          values.to_date !== "" &&
          fromDateNew > toDateNew
        ) {
          errors.from_date_error = "To date should be greater than from date!";
        }
        return errors;
      }}
      onSubmit={(values) => {
        const { from_date, to_date, sku } = values;
        const params = { from_date, to_date, sku };
        const newFilter = {
          ...cleanArrObject(analystSearchValues),
          ...cleanArrObject(params),
        };
        
        //Important 27 April 2023 commented the dispatch method as these are calling dremio queries 
        //UnComment when connected to connector dataource 
        // dispatch(getAnalystKpiData(newFilter));
        // dispatch(getAnalystPOSChartData(newFilter));
        // dispatch(getAnalystOOSChartData(newFilter));
        // dispatch(getAnalystOTIFChartData(newFilter));
        // dispatch(getAnalystLandingAggregateData(newFilter));
      }}
      onReset={(values) => {}}
    >
      {({
        values,
        errors,
        setFieldValue,
        handleSubmit,
        handleReset,
        setErrors,
      }) => (
        <form className="form-sample" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-3">
              <SkuList
                onUpdate={(skuCode) => {
                  setFieldValue("sku", fn_code_array(skuCode));
                }}
                localRef={skuTypeheadRef}         
              />
            </div>
            <div className="col-md-3">
              <Form.Group className="row">
                <div className="col-sm-12">
                  <label className="sr-only" htmlFor="inlineFormInputName2">
                    From Date
                  </label>
                  <DatePicker
                    id="executiveDateFrom"
                    className="form-control w-100"
                    placeholderText="From Date"
                    selected={fromDate}
                    onChange={(val) => {
                      setfromDate(val);
                      setFieldValue(
                        "from_date",
                        dateFormatter(val, "YYYY-MM-DD")
                          ? val
                              .toLocaleDateString("en-GB")
                              .replaceAll("/", "-")
                              .split("-")
                              .reverse()
                              .join("-")
                          : ""
                      );
                    }}
                  />
                  <div className="error">{errors.from_date_error}</div>
                </div>
              </Form.Group>
            </div>
            <div className="col-md-3">
              <Form.Group className="row">
                <div className="col-sm-12">
                  <label className="sr-only" htmlFor="inlineFormInputName2">
                    To Date
                  </label>
                  <DatePicker
                    className="form-control w-100"
                    placeholderText="To Date"
                    selected={toDate}
                    onChange={(val) => {
                      settoDate(val);
                      setFieldValue(
                        "to_date",
                        dateFormatter(val, "YYYY-MM-DD")
                          ? val
                              .toLocaleDateString("en-GB")
                              .replaceAll("/", "-")
                              .split("-")
                              .reverse()
                              .join("-")
                          : ""
                      );
                    }}
                  />
                  <div className="error">{errors.to_date_error}</div>
                </div>
              </Form.Group>
            </div>
            <div className="col-md-3 justify-content-between align-item-center d-flex">
              <button
                type="reset"
                className="btn btn-outline-secondary btn-sm custom-btn-height"
                onClick={() => {
                  setErrors({});
                  myCustomhandleResetFn();
                }}
              >
                {" "}
                Reset{" "}
              </button>
              <button
                type="button"
                className="btn btn-gradient-danger btn-sm custom-btn-height"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {" "}
                Filter{" "}
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
