// Import store
import store from "../storeFactory";

import SocketClient from "../../socket/SocketClient";
const socketClient = new SocketClient();
let socket = socketClient;

export const startSocketConnetion = () => {
    return async () => {
        await socket.connect();
    }
}

export const closeSocketConnetion = () => {
    return async () => {
        await socket.disconnect();
    }
}

const delay = (time) => {
    return new Promise(resolve => setTimeout(resolve, time));
}
export const subscribeToEvent = async (eventName) => {
    socket.emit('subscribe', eventName);
    await delay(5000);
    return true;

}

export const openSocketForAnEvent = (eventName, callbackfun) => {
    socket.on(eventName, callbackfun);
}

export const getSocketStatus = async (reqSocketKey) => {
    await subscribeToEvent(reqSocketKey);
    let splitReqKey = reqSocketKey.split("/");
    return new Promise((resolve, reject) => {
        subscribeToEvent(reqSocketKey);
        //console.log("internal.........Inside socket promise...........");
        return openSocketForAnEvent('updateKpi', (socketResponse) => {
            console.log("internal.........checking socket data...........", socketResponse);
            const splitKey = socketResponse.key.split("/");
            if ((socketResponse.jobState === "COMPLETED" || socketResponse.jobState === "FAILED") && splitKey[0] === splitReqKey[0]) {
                console.log("socket process:...........DONE");
                return resolve(socketResponse);
            }
        })
    })
}

export const recursiveExecutiveKpisData = async () => {
    const stateData = store.getState();
    const isKpiDataLoaded = stateData.executivePageReducer.isLoadingRequestIds;
    //console.log("--------1");
    return new Promise((resolve, reject) => {
        if (!isKpiDataLoaded.includes('isCustomerKpiDataLoaded') && !isKpiDataLoaded.includes('isBuKpiDataLoaded') && !isKpiDataLoaded.includes('isBrandKpiDataLoaded') && !isKpiDataLoaded.includes('isSegmentKpiDataLoaded')) {
            //console.log("--------2");
            const custData = stateData.executivePageReducer.customerKpiData;
            const buData = stateData.executivePageReducer.buKpiData;
            const brandData = stateData.executivePageReducer.brandKpiData;
            const segmentData = stateData.executivePageReducer.segmentKpiData;
            const mergedStateData = Object.values([...custData, ...buData, ...brandData, ...segmentData]);
            return resolve(mergedStateData);
        } else {
            //console.log("--------3");
            setTimeout(async () => {
                return resolve(recursiveExecutiveKpisData());
            }, 3000)
        }
    })
}