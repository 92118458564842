import React from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { DT_CellFormatterDisplayNull } from "../../../utils/common-functions";

const tbl_headers = [
  {
    dataField: "region",
    text: "Region",
  },
  {
    dataField: "retailer_id",
    text: "Retailer ID",
  },
  {
    dataField: "retailer_product_id",
    text: "Retailer Product ID",
  },
  {
    dataField: "reckitt_product_code",
    text: "Reckitt Product Code",
  },
  {
    dataField: "consumer_product_code",
    text: "Consumer Product Code",
  },
  {
    dataField: "description",
    text: "Description",
  },
  {
    dataField: "brand_name",
    text: "Brand Name",
  },
  {
    dataField: "bu",
    text: "BU",
  },
  {
    dataField: "segment_description",
    text: "Segment Description",
  },
  {
    dataField: "missing_flag",
    text: "Missing Flag",
  },
];
const pagination_options = {
  // pageStartIndex: 0,
  sizePerPage: 5,
  hideSizePerPage: true,
  hidePageListOnlyOnePage: true,
};
const MissingProductMapping = (props) => {
  const { data, isLoading } = props;
  if (isLoading) {
    return <DataLoader fullPage={false} message="Fetching data" />;
  }
  if (data?.length === 0) {
    return <NoDataFound message={"There is no data for selected filters!"} />;
  }

  return (
    <ToolkitProvider keyField="sku" data={data} columns={tbl_headers}>
      {(props) => (
        <div>
          <BootstrapTable
            striped
            hover
            condensed
            classes="table-responsive"
            {...props.baseProps}
            pagination={paginationFactory(pagination_options)}
            noDataIndication="There is nos data for selected filters!"
          />
        </div>
      )}
    </ToolkitProvider>
  );
};
export default MissingProductMapping;
