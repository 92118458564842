import io from 'socket.io-client';
import config_local from "../../conf/local_config.json";
// Example conf. You can move this to your config file.
const host = config_local.socket_host; //"http://localhost:4001";
const socketPath = config_local.socket_path; //"/socket.io"; //'update-api-data';

export default class socketAPI {
    socket;
    connect() {
        this.socket = io.connect(host, {
            //secure: true,
            //reconnection: true,
            withCredentials: true,
            reconnectionDelay: 10000,
            reconnection: true,
            reconnectionAttempts: 20,
            agent: false, // [2] Please don't set this to true
            upgrade: false,
            rejectUnauthorized: false,
            //path: socketPath,
            transports: ['polling'],

        }); //io.connect(host, { path: socketPath, transports: ['websocket'] });
        return new Promise((resolve, reject) => {
            this.socket.on('connect', () => { console.log("Socket connection has been established!"); resolve() });
            this.socket.on('connect_error', (error) => { console.log("Socket Connection ERROR: ", error); reject(error) });
        });
    }

    disconnect() {
        return new Promise((resolve) => {
            this.socket.disconnect(() => {
                console.log("disconnect")
                this.socket = null;
                resolve();
            });
        });
    }
    // sending message to the server
    emit(event, data) {
        //console.log("emit", this.socket)
        return new Promise((resolve, reject) => {
            if (!this.socket) { return reject('No socket connection.'); }
            //console.log("api Data", event)
            return this.socket.emit(event, data, (response) => {
                // Response is the optional callback that you can use with socket.io in every request. See 1 above.
                //console.log(response);
                if (response?.error) {
                    return reject(response.error);
                }
                return resolve(response);
            });
        });
    }
    //receive message from the server
    on(event, fun) {

        // No promise is needed here, but we're expecting one in the middleware.
        return new Promise((resolve, reject) => {
            try{
                if (!this.socket) return reject('No socket connection.');
                this.socket.on(event, fun);
                resolve(fun);
            }catch(error){
                console.log("Socket Error Caught is ",error)
            }           
        });
    }
}