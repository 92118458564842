import { commonTypes } from "../types/commonTypes";
import { analystLandingTypes } from "../types/analystLandingTypes";
import { removeItemOnce } from '../../utils/common-functions';

export default function (state = {}, action) {

  switch (action.type) {
    case commonTypes.SET_LOADING:
      var key = Object.keys(action.payload)[0];
      var value = action.payload[Object.keys(action.payload)[0]];
      return {
        ...state,
        isLoadingRequestIds: value ? [...state.isLoadingRequestIds, key] : removeItemOnce(state.isLoadingRequestIds, key)
      };
    case analystLandingTypes.SET_ANALYST_KPI:
      return {
        ...state,
        analystKpiData: action.payload,
      }
    case analystLandingTypes.SET_ANALYST_POS_CHART:
      return {
        ...state,
        analystPOSChartData: action.payload,
      }
    case analystLandingTypes.SET_ANALYST_OOS_CHART:
      return {
        ...state,
        analystOOSChartData: action.payload,
      }
    case analystLandingTypes.SET_ANALYST_OTIF_CHART:
      return {
        ...state,
        analystOTIFChartData: action.payload,
      }
    case analystLandingTypes.SET_ANALYST_LANDING_AGGREGATE:
      return {
        ...state,
        analystLandingAggregateData: action.payload,
      }
    case analystLandingTypes.SET_GLOBAL_SEARCH_VALUES:
      return {
        ...state,
        analystGlobalSearchValues: action.payload,
      }

    case analystLandingTypes.SET_DATERANGE_SEARCH_VALUES:
      return {
        ...state,
        analystDateRangeSearchValues: action.payload,
      }
    default:
      return state;
  }
}
