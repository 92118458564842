import React from "react";
import { useDispatch } from "react-redux";
import GlobalFilter from "../../../components/Filter/GlobalFilter";
import {
  getCustomerKpiData,
  getBuKpiData,
  getBrandKpiData,
  getSegmentKpiData,
  setCustomerKpiData,
  getExecutivePivotData,
  setExecutivePivotData,
  setBuKpiData,
  setBrandKpiData,
  setSegmentKpiData,
  setGlobalSearchValues,
  setDateRangeSearchValues,
} from "../../../redux/actions/executivePageActions";

import {
  getAnalystKpiData,
  getAnalystPOSChartData,
  getAnalystOOSChartData,
  getAnalystOTIFChartData,
  getAnalystLandingAggregateData,
  setAnalystKpiData,
  setAnalystPOSChartData,
  setAnalystOOSChartData,
  setAnalystOTIFChartData,
  setAnalystLandingAggregateData,
} from "../../../redux/actions/analystLandingActions";
import {
  todayDate,
  getLastnthWeeksDate,
  cleanArrObject,
} from "../../../utils/common-functions";

export default function CockpitGlobalFilters() {
  const dispatch = useDispatch();
  const processFilterData = (values) => {    
    values = { region: values.region, customer: values.customer, bu: values.bu, brand: values.brand };
    dispatch(setGlobalSearchValues(values));

    const dateFilter = {
      from_date: getLastnthWeeksDate(13),
      to_date: todayDate(),
    };

    const newFilter = { ...values, ...dateFilter };

    //Important 27 April 2023 commented the dispatch method as these are calling dremio queries 
    //UnComment when connected to connector dataource 
    dispatch(setDateRangeSearchValues(dateFilter));
    dispatch(getAnalystKpiData(newFilter));
    dispatch(getAnalystPOSChartData(newFilter));
    dispatch(getAnalystOOSChartData(newFilter));
    dispatch(getAnalystOTIFChartData(newFilter));
    dispatch(getAnalystLandingAggregateData(newFilter));

    //Get executive kpis
    //21 03 2023 Add the date filter to     
    
    dispatch(getCustomerKpiData(newFilter));
    dispatch(getBuKpiData(newFilter));
    dispatch(getBrandKpiData(newFilter));
    dispatch(getSegmentKpiData(newFilter));

    // Executive APIs commented temporarily due to insufficient memory in dremio. This is solved    
    dispatch(getExecutivePivotData(newFilter));
  };

  const resetFilterData = () => {
    dispatch(setAnalystKpiData([]));
    dispatch(setAnalystPOSChartData([]));
    dispatch(setAnalystOOSChartData([]));
    dispatch(setAnalystOTIFChartData([]));
    dispatch(setAnalystLandingAggregateData([]));

    //set executive kpis
    dispatch(setCustomerKpiData([]));
    dispatch(setBuKpiData([]));
    dispatch(setBrandKpiData([]));
    dispatch(setSegmentKpiData([]));
    dispatch(setExecutivePivotData([]));
  };
  return (
    <GlobalFilter
      filterPage={"cockpit"}
      onFormSubmit={(formData) => processFilterData(formData)}
      onFormReset={() => resetFilterData()}
      fields={["region", "customer", "bu", "brand"]}
    />
  );
}
