/*
HorizontalBarChart is used to display horiontal bar graph

This component can handle below props:
- Min
- Max
- chartLabels
- chartDataSet
- salesType

Currently this component got imported in below pages:
/src/js/screens/Instock/Components/SalesByDc.js
/src/js/screens/Instock/Components/SalesBySku.js
/src/js/screens/Instock/Components/SalesByStore.js
*/

import React, { } from 'react'

import { useSelector } from 'react-redux';
// Import chart Js
import {
  Chart as Chartjs,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

Chartjs.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function VeriticleBarChart(props) { 
  const {  chartDataSet, chartLabels, salesType } = props;

  const getSkuData = useSelector(({ instockReducer }) => instockReducer.skuLevelData);
  //const getInventoryData = useSelector(({ instockReducer }) => instockReducer.inventoryData);

  const barChartOptions = {
    barThickness:(chartLabels.length > 10 ) ? 12: 12,
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: false,
        text: 'Chart.js Horizontal Bar Chart',
      },
    },
};

const getColorCodesForSku = () => {
  const backGroundColorArr = [];
  if( getSkuData.length > 0 ){
      getSkuData.map((obj, index) => {
        const findInArr = chartLabels.find( element => element === obj.sku );
          if( findInArr !== undefined ){
              if( obj.percentage_oos >= 5  && obj.percentage_oos <= 10) {
                backGroundColorArr.push('rgba(252, 249, 0, 0.5)'); // yellow
              } else if( obj.percentage_oos > 10 ){
                backGroundColorArr.push('rgba(252, 0, 0, 0.5)'); //red
              } else if( obj.percentage_oos <= 5 ){
                backGroundColorArr.push('rgba(13, 252, 0, 0.5)'); //green
              } else {
                backGroundColorArr.push('rgba(149, 149, 149, 0.34)'); //grey default 
              }
          }
      })
  } else {
    backGroundColorArr.push('rgba(149, 149, 149, 0.34)'); //grey default 
  }
  return backGroundColorArr;
}

const getColorCodesForStore = () => {
  const backGroundColorArr = [];
  backGroundColorArr.push('rgba(252, 0, 0, 0.5)'); //red
  // if( getInventoryData.length > 0 ){
  //   getInventoryData.map((obj, index) => {
  //       const findInArr = chartLabels.find( element => element === obj.store );
  //         if( findInArr !== undefined ){
  //             if( obj.store >= 5  && obj.store <= 10) {
  //               backGroundColorArr.push('rgba(252, 249, 0, 0.5)'); // yellow
  //             } else if( obj.store > 10 ){
  //               backGroundColorArr.push('rgba(252, 0, 0, 0.5)'); //red
  //             } else if( obj.store <= 5 ){
  //               backGroundColorArr.push('rgba(13, 252, 0, 0.5)'); //green
  //             } else {
  //               backGroundColorArr.push('rgba(149, 149, 149, 0.34)'); //grey default 
  //             }
  //         }
  //     })
  // } else {
  //   backGroundColorArr.push('rgba(149, 149, 149, 0.34)'); //grey default 
  // }
  return backGroundColorArr;
}

const getColorCodesForDc = () => {
  const backGroundColorArr = [];
  // if( getInventoryData.length > 0 ){
  //   getInventoryData.map((obj, index) => {
  //       const findInArr = chartLabels.find( element => element === obj.dc );
  //         if( findInArr !== undefined ){
  //             if( obj.dc >= 5  && obj.dc <= 10) {
  //               backGroundColorArr.push('rgba(252, 249, 0, 0.5)'); // yellow
  //             } else if( obj.dc > 10 ){
  //               backGroundColorArr.push('rgba(252, 0, 0, 0.5)'); //red
  //             } else if( obj.dc <= 5 ){
  //               backGroundColorArr.push('rgba(13, 252, 0, 0.5)'); //green
  //             } else {
  //               backGroundColorArr.push('rgba(149, 149, 149, 0.34)'); //grey default 
  //             }
  //         }
  //     })
  // } else {
  //   backGroundColorArr.push('rgba(149, 149, 149, 0.34)'); //grey default 
  // }
  backGroundColorArr.push('rgba(252, 0, 0, 0.5)'); //red
  return backGroundColorArr;
}

var backGroundColorArr;
switch (salesType) {
  case 'sku':
    backGroundColorArr = getColorCodesForSku();
    break;
  case 'store':
    backGroundColorArr = getColorCodesForStore();
    break;
  case 'dc':
    backGroundColorArr = getColorCodesForDc();
    break;
  default:
    break;
}

const FinalDataSet = 
  [{
    label: 'Total Sales',
    data: chartDataSet,
    backgroundColor: backGroundColorArr,
    borderColor: backGroundColorArr,
    borderWidth: 1,
    fill: true
  }]
  const barChartData = {
    labels: chartLabels,
    datasets: FinalDataSet,
  };

  return (
    <Bar data={barChartData} options={barChartOptions} height="120" /> 
  )
}