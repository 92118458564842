import React, { } from 'react';

import { KPIComponent } from "../../../components/KPIComponent/index";
import { KPIFiveBox } from "../../../components/KPIComponent/index";

import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { numberWithCommas, DT_CellFormatterWithPerc, DT_CellFormatterFixPrecision } from '../../../utils/common-functions';


const KPIOTIF = (props) => {
  let { otifData, isLoading } = props;
  if (isLoading) {
    return (
      <DataLoader
        fullPage={false}
        message="Fetching data"
      />
    );
  }
  if (otifData?.length === 0) {
    otifData = [
      {
        "kpiTitle": "In Stock",
        "actualValue": "0",
        "variance": "0"
      },
      {
        "kpiTitle": "Out of Stock",
        "actualValue": "0",
        "variance": "0"
      },
      {
        "kpiTitle": "Total",
        "actualValue": "0",
        "variance": "0"
      },
      {
        "kpiTitle": "WOC",
        "actualValue": "0",
        "variance": "0"
      },
      {
        "kpiTitle": "Orders",
        "actualValue": "0",
        "variance": "0"
      },
      
      {
        "kpiTitle": "POS (Cons.)",
        "actualValue": "0",
        "variance": "0"
      },
      // {
      //   "kpiTitle": "POS Forecast vs Actual",
      //   "actualValue": "0",
      //   "variance": "0"
      // },
      // {
      //   "kpiTitle": "Order Forecast vs Actual",
      //   "actualValue": "0",
      //   "variance": "0"
      // },
      {
        "kpiTitle": "On Time",
        "actualValue": "0",
        "variance": "0"
      },
      {
        "kpiTitle": "In Full",
        "actualValue": "0",
        "variance": "0"
      },
      {
        "kpiTitle": "OTIF",
        "actualValue": "0",
        "variance": "0"
      }
    ];
  }
 
 return (    
    <div className="card-body-custom">
    <div className="row">
        {otifData.map((obj, index) =>          
          // (index !== 6 && index !== 7 )?
          ((index > 3))?
            // (<div key={index} className={(index===2)?"col ver_divider":"col"}>
            (<div key={index} className="col">
              <KPIFiveBox kpiData={{
                    //kpiTitle: (index===3)?'WOC':obj.kpiTitle,
                    kpiTitle: (index===5)?'POS (Cons.)':obj.kpiTitle,
                    actualValue: (index === 0 || index === 1 || index === 8 || index === 9 || index === 10)? DT_CellFormatterWithPerc(obj.actualValue) : numberWithCommas(obj.actualValue),
                    //added % symbol in KPIFiveBox component and hence below commented
                    //variance: obj.variance+'%',
                    
                    //26 April 2023 Format percentage precision cosmetic changes ...
                    //variance: obj.variance+'', 
                    variance: (index===5) ? DT_CellFormatterFixPrecision(obj.variance) : obj.variance+'',
                    //comparison: 'KPI Vs Lastweek'
                }}
              />
          </div>)
         : null
    )} 
    </div>
    </div>
  );

}
export default KPIOTIF;