import React, { Component } from "react";
import { CSVLink } from "react-csv";

import { connect } from "react-redux";
import { getSegmentLevelDataForExport } from "../../../redux/actions/instockActions";

import { cleanArrObject } from "../../../utils/common-functions";

const headers = [
  {
    key: "region",
    label: "Region",
  },
  {
    key: "customer",
    label: "Customer",
  },
  {
    key: "segment",
    label: "Segment",
  },
  {
    key: "brand_name",
    label: "Brand Name",
  },
  {
    key: "bu",
    label: "BU",
  },
  {
    key: "total_stores",
    label: "Total Stores",
  },
  {
    key: "oos_stores",
    label: "OOS Stores",
  },
  {
    key: "percentage_oos",
    label: "% OOS",
  },
  {
    key: "pos_per_store_4w_avg",
    label: "POS per store(4W Avg)",
  },
  {
    key: "fill_rate",
    label: "Fill Rate",
  },
  {
    key: "on_time_rate",
    label: "On Time Rate",
  },
  {
    key: "in_transit_inventory",
    label: "In Transit Inventory",
  },
  {
    key: "dc_inventory",
    label: "DC Inventory",
  },
  {
    key: "store_inventory",
    label: "Store Inventory",
  },
  {
    key: "total_inventory",
    label: "Total Inventory",
  },
  {
    key: "in_transit_inventory_perc",
    label: "In Transit Inventory %",
  },
  {
    key: "dc_inventory_perc",
    label: "DC Inventory %",
  },
  {
    key: "store_inventory_perc",
    label: "Store Inventory %",
  },
  {
    key: "dc_week_of_supply",
    label: "DC WOC (4w avg)",
  },
  {
    key: "store_week_of_supply",
    label: "Store WOC (4w avg)",
  },
  {
    key: "sku_woc_historical",
    label: "Total WOC (4w avg)",
  },
  {
    key: "pos_lw",
    label: "POS(LW)",
  },
  {
    key: "avg_pos_historical_4w",
    label: "Average POS Historical(4W)",
  },
  {
    key: "sales_variance_4w",
    label: "Sales Variance(4W)",
  },
  //avg_pos_forecast_8w , avg_pos_historical_13w removed due to optimization of queries
  //projected_order_qty_8w renamed as sum_order_forecast_4w
  {
    key: "sum_order_forecast_4w",
    label: "Sum Order Forecast 4 weeks ",
  },
  {
    key: "avg_pos_forecast_4w",
    label: "Average POS Forecast ( 4 weeks)",
  },
  {
    key: "sku_woc_forecast",
    label: "SKU WOC Forecast",
  },
  {
    key: "future_inventory",
    label: "Future Inventory",
  },
  {
    key: "future_woc",
    label: "Future WOC",
  },
  {
    key: "future_risk",
    label: "Future Risk",
  },
  {
    key: "lost_sales",
    label: "Lost Sales",
  },
  {
    key: "pos_value",
    label: "POS Value $",
  },
];

class ExportSegmentCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      IsDataLoading: false,
    };
    this.csvLinkEl = React.createRef();
  }

  fetchDataFromServer = async () => {
    var filterItems = this.getFilteredValues();
    const apiRes = await getSegmentLevelDataForExport(filterItems);
    return apiRes;
  };

  downloadReport = async () => {
    if (!this.state.IsDataLoading) {
      this.setState({ IsDataLoading: true });
      const data = await this.fetchDataFromServer();
      this.setState({ data: data, IsDataLoading: false }, () => {
        setTimeout(() => {
          this.csvLinkEl.current.link.click();
        });
      });
    }
  };

  getFilteredValues = () => {
    var instockSearchValues =
      this.props.inStockDataObj.instockGlobalSearchValues;
    var instockInventorySearchValues =
      this.props.inStockDataObj.instockInventorySearchValues;
    var instockDateRangeSearchValues =
      this.props.inStockDataObj.instockDateRangeSearchValues;
    
    //27 Jan 2023 Ensure Same payload for API and Export API to avoid dremio queries getting called
    //const newArr = { limit: 0 };
    const allFilterObj = {
      ...cleanArrObject(instockSearchValues),
      ...cleanArrObject(instockInventorySearchValues),
      //...cleanArrObject(instockDateRangeSearchValues),
      //...newArr,
    };
    return allFilterObj;
  };

  render() {
    const { data } = this.state;
    return (
      <div>
        <input
          type="button"
          value={
            this.state.IsDataLoading ? "Loading csv..." : "Export All Data"
          }
          className="btn btn-gradient-info label-right font-weight-bold px-lg-4 px-3"
          onClick={this.downloadReport}
        />
        <CSVLink
          headers={headers}
          filename={"ExportedSegmentData.csv"}
          asyncOnClick={true}
          data={data}
          ref={this.csvLinkEl}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    inStockDataObj: state.instockReducer,
  };
}
const mapDispatchToProps = { getSegmentLevelDataForExport };
export default connect(mapStateToProps, mapDispatchToProps)(ExportSegmentCSV);
