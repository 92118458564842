import { commonTypes } from "../types/commonTypes";
import { executivePageTypes } from "../types/executivePageTypes";
import { removeItemOnce } from "../../utils/common-functions";

export default function (state = {}, action) {
  switch (action.type) {
    case commonTypes.SET_LOADING:
      var key = Object.keys(action.payload)[0];
      var value = action.payload[Object.keys(action.payload)[0]];
      return {
        ...state,
        isLoadingRequestIds: value
          ? [...state.isLoadingRequestIds, key]
          : removeItemOnce(state.isLoadingRequestIds, key), //state.isLoadingRequestIds.splice(state.isLoadingRequestIds.indexOf(key)).slice()
      };

    case executivePageTypes.SET_CUSTOMER_KPI:
      return {
        ...state,
        customerKpiData: action.payload,
      };
    case executivePageTypes.SET_BU_KPI:
      return {
        ...state,
        buKpiData: action.payload,
      };
    case executivePageTypes.SET_BRAND_KPI:
      return {
        ...state,
        brandKpiData: action.payload,
      };
    case executivePageTypes.SET_SEGMENT_KPI:
      return {
        ...state,
        segmentKpiData: action.payload,
      };
    case executivePageTypes.SET_GLOBAL_SEARCH_VALUES:
      return {
        ...state,
        executiveGlobalSearchValues: action.payload,
      };
    case executivePageTypes.SET_DATERANGE_SEARCH_VALUES:
      return {
        ...state,
        executiveDateRangeSearchValues: action.payload,
      };
    case executivePageTypes.SET_EXECUTIVE_PIVOT_DATA:
      return {
        ...state,
        executivePivotData: action.payload,
      };
    default:
      return state;
  }
}
