/*
ReactSelect is a master component based on which child components such as CustomerList, BuList, Brandlist etc are derived

This component can handle below props:
 - isMulti
 - closeMenuOnSelect
 - hideSelectedOptions
 - components
 - allowSelectAll
 - MultiValue
 - isLoading
 - placeholder
 - localRef

Currently this component got imported in below pages:
/src/js/screens/Cockpit/Filters/GlobalFilters.js
/src/js/screens/Instock/Filters/InStockGlobalFilters.js
/src/js/screens/Forecast/Filters/ForecastFilters.js
/src/js/screens/Otif/Filters/GlobalFilter.js
*/


import React from "react";
import PropTypes from "prop-types";
import { createFilter } from "react-select";
import CreatableSelect from 'react-select/creatable'
//import AsyncSelect from "react-select/async";

import './ReactSelect.scss';

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    height: "30px",
    //'overflow-y': 'scroll' 
    overflowY: "scroll"
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 1000,
  }),
  option: (provided, state) => ({
    ...provided,
    //color: state.isSelected ? 'red' : '#212529',
    padding: 5,
    color: '#212529',
    backgroundColor: 'white'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#007bff'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e7f4ff',
    color: '#007bff'
  }),
  multiValueLabel: (provided, state) => ({
    color: '#007bff'
  }),
  loadingIndicator: () => ({
    color: '#007bff',
    fontSize: 10
  }),
}

//const customOptions = props.options.length>0 ?[props.allOption, ...props.options] : [];


const ReactSelect = props => {

  if (props.allowSelectAll) {
    return (
      <CreatableSelect
        {...props}
        //options={[props.allOption, ...props.options]}
        options={props.options.length > 0 ? [props.allOption, ...props.options] : []}

        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].code === props.allOption.code) {
              return props.onChange([props.allOption, ...props.options]);
            }
            let result = [];
            if (selected.length === props.options.length) {
              if (selected.includes(props.allOption)) {
                result = selected.filter(
                  option => option.code !== props.allOption.code
                );
              } else if (event.action === "select-option") {
                result = [props.allOption, ...props.options];
              }
              return props.onChange(result);
            }
          }

          return props.onChange(selected);
        }}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.code}
        styles={customStyles}
        DropdownIndicator={() => null}
        ref={props.localRef}
        filterOption={createFilter({ ignoreAccents: false })}
        //menuIsOpen ={true}
        classNamePrefix="custom-select"
        captureMenuScroll={false}
        onMenuClose={() => {
          return props.onMenuClose(true);
        }}
        //01 Feb 2022 default options in alerts page
        defaultOptions={true}
      />
    );
  }

  return <CreatableSelect {...props} />;
};

ReactSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onMenuClose: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string
  }),
  //01 Feb 2022 default options in alerts page
  defaultValue : PropTypes.any,
  defaultOptions: PropTypes.bool,
};

ReactSelect.defaultProps = {
  allOption: {
    name: "Select all",
    code: "*"
  },  
};


export default ReactSelect;
