import React, { } from 'react'
import DataLoader from "../../../components/Loader/DataLoader";
import { KPIComponent } from "../../../components/KPIComponent/index";
import { numberWithCommas } from '../../../utils/common-functions';

const StockStatistics = (props) => {
    let { data, isLoading } = props;
    if (isLoading) {
        return (
            <DataLoader
                fullPage={false}
                message="Fetching data"
            />
        );
    }

    data = [
        {
            kpiTitle: 'OOS %',
            actualValue: (data.out_of_stock) ? numberWithCommas(data.out_of_stock) : "0",
            variance: (data.out_of_stock && data.total != 0) ? ((data.out_of_stock / data.total) * 100).toFixed(1) + '%' : "0%",
        },
        {
            kpiTitle: 'Under Stock %',
            actualValue: (data.under_stock) ? numberWithCommas(data.under_stock) : "0",
            variance: (data.under_stock && data.total != 0) ? ((data.under_stock / data.total) * 100).toFixed(1) + '%' : "0%",
        },
        {
            kpiTitle: 'At Stock %',
            actualValue: (data.at_stock) ? numberWithCommas(data.at_stock) : "0",
            variance: (data.at_stock && data.total != 0) ? ((data.at_stock / data.total) * 100).toFixed(1) + '%' : "0%", 
        },
        {
            kpiTitle: 'Over Stock %',
            actualValue: (data.over_stock) ? numberWithCommas(data.over_stock) : "0",
            variance: (data.over_stock && data.total != 0) ? ((data.over_stock / data.total) * 100).toFixed(1) + '%' : "0%",
        },
        {
            kpiTitle: 'Total %',
            actualValue: (data.total) ? numberWithCommas(data.total) : "0",
            variance: (data.total && data.total != 0) ? ((data.total / data.total) * 100).toFixed(1) + '%' : "0%",
        },        
        {
            kpiTitle: 'Store Inv %',
            actualValue: (data.store_inventory) ? numberWithCommas(data.store_inventory) : "0",
            variance: (data.store_inventory && data.total_inventory != 0) ? ((data.store_inventory / data.total_inventory) * 100).toFixed(1) + '%' : "0%",
        },
        {
            kpiTitle: 'In-transit %',
            actualValue: (data.in_transit) ? numberWithCommas(data.in_transit) : "0",
            variance: (data.in_transit && data.total_inventory != 0) ? ((data.in_transit / data.total_inventory) * 100).toFixed(1) + '%' : "0%",
        },
        {
            kpiTitle: 'DC %',
            actualValue: (data.dc) ? numberWithCommas(data.dc) : "0",
            variance: (data.dc && data.total_inventory != 0) ? ((data.dc / data.total_inventory) * 100).toFixed(1) + '%' : "0%",
        },
        {
            kpiTitle: 'Total Inv.',
            actualValue: (data.total_inventory) ? numberWithCommas(data.total_inventory) : "0",
            variance: (data.total_inventory && data.total_inventory != 0) ? ((data.total_inventory / data.total_inventory) * 100).toFixed(1) + '%' : "0%",
        },
        {
            kpiTitle: 'WOC (latest wk)',
            actualValue: (data.woc) ? data.woc : "0",
            variance: "-",
        }
      ];
   
      return (
        
        <div className="container">
            
             <div className='mt-2 d-flex justify-content-around'>
                <label className="text-dark text-center text-dark-custom">Store inventory</label>
                <label className="text-dark text-center text-dark-custom">Inventory Distribution</label>
             </div>
            <div className="row">
                
                {data.map((obj, index) =>
                    <div key={index} className={(index===4)?"col ver_divider":"col"}>
                    <KPIComponent key={index} kpiData={{
                        kpiTitle: obj.kpiTitle,
                        actualValue: obj.actualValue,
                        variance: obj.variance,
                        //comparison: 'KPI Vs Lastweek'
                    }}
                    />
                    </div>
                )}
                
                </div>
               
           </div>     
    );
}
export default StockStatistics;