// Import required libraries
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Import routes
import Routes from "./routes/Routes";

import {
  getRegionList,
  getBuList,
  getBrandList,
  getSkuList,

} from "./redux/actions/masterActions";


// Import action creators
import { updateUserInfo } from "./redux/actions";

// Import utils
import { config } from "../app/config/config";

// Import Configuration
import config_local from "../conf/local_config.json";

//Import custom components
import Loader from "./components/Loader/Loader";

function App(props) {
  
  const history = useHistory();
  const [authResolved, setAuthResolved] = useState(true);
  const user = JSON.parse(localStorage.getItem("user"));
  
  useEffect(async () => {
    if (user) {
      await props.getRegionList();      
      await props.getBuList("");
    }
  }, [user]);
  return (
    <Routes />
  );
}

App.propTypes = {
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = { updateUserInfo,  getRegionList, getBuList };

export default connect(mapStateToProps, mapDispatchToProps)(App);
