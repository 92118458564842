import { commonTypes } from "../types/commonTypes";
import { alertTypes } from "../types/alertTypes";
import { removeItemOnce } from '../../utils/common-functions';

export default function (state = {}, action) {

    switch (action.type) {
        case commonTypes.SET_LOADING:
            var key = Object.keys(action.payload)[0];
            var value = action.payload[Object.keys(action.payload)[0]];
            return {
                ...state,
                isLoadingRequestIds: value ? [
                    ...state.isLoadingRequestIds,
                    key
                ] : removeItemOnce(state.isLoadingRequestIds, key)
            };
        case alertTypes.SET_MISSING_PRODUCT_MAPPING:
            return {
                ...state,
                missingProdMapData: action.payload
            }
        case alertTypes.SET_MISSING_STORE_MAPPING:
            return {
                ...state,
                missingStoreMapData: action.payload
            }
        case alertTypes.SET_INACTIVE_STORE_SALES:
                return {
                    ...state,
                inactiveStoreSalesData: action.payload
            }
        case alertTypes.SET_INACTIVE_STORE_INVENTORY:
                return {
                    ...state,
                inactiveStoreInventoryData: action.payload
            }  
        case alertTypes.SET_GLOBAL_SEARCH_VALUES:
                return {
                  ...state,
                  alertGlobalSearchValues: action.payload,
                };       
        default:
            return state;
    }
}


