import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { cleanArrObject } from '../../../utils/common-functions';

import { getOntimeBarChartData, getInfullBarChartData } from '../../../redux/actions/otifActions';

export default function OTIFTopBottomFilter(props) {
  //const {  } = props;

  const otifSearchValues = useSelector(({ otifReducer }) => otifReducer.otifGlobalSearchValues);
  const dispatch = useDispatch();

  const [category, setCategory] = useState('dc');
  const [orderBy, setorderBy] = useState('top');

  const filterDataCategoryWise = (selectedValue) => {
    const filterForGraph = { "category": selectedValue, "limit": 10, 'orderby':orderBy }
    const newfilterForGraph = { ...cleanArrObject(otifSearchValues), ...filterForGraph };
    //Important 27 April 2023 commented the dispatch method as these are calling dremio queries 
    //UnComment when connected to connector dataource 
    dispatch(getOntimeBarChartData(newfilterForGraph));
    dispatch(getInfullBarChartData(newfilterForGraph));
  }
  const filterDataOrderBy = (selectedValue) => {
    const filterForGraph = { "category": category, "limit": 10, 'orderby':selectedValue }
    const newfilterForGraph = { ...cleanArrObject(otifSearchValues), ...filterForGraph };
    //Important 27 April 2023 commented the dispatch method as these are calling dremio queries 
    //UnComment when connected to connector dataource 
    dispatch(getOntimeBarChartData(newfilterForGraph));
    dispatch(getInfullBarChartData(newfilterForGraph));
  }
  return (
    <Formik
      initialValues={{category:"dc", orderBy:'top'}}
      validate={values => {}}
      onSubmit={values => {}}
      onReset={(values) => {}}
    >
      {({ values, errors, setFieldValue, handleSubmit, handleReset, setErrors }) => (
        <form className="form-sample" onSubmit={handleSubmit} >
          <div className='row'>
            <div className="col-md-6">
              <Form.Group className="">
                  <select className="form-control"
                      onChange={(e) => { setFieldValue('category', e.target.value); setCategory(e.target.value); filterDataCategoryWise(e.target.value); }}
                  >
                    <option value="dc">DC</option>
                    <option value="bu">BU</option>
                    <option value="brand">Brand</option>
                  </select>
                
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className="">
                  <select className="form-control"
                      onChange={(e) => { setFieldValue('orderBy', e.target.value); setorderBy(e.target.value); filterDataOrderBy(e.target.value); }}
                  >
                    <option value="top">Top 10</option>
                    <option value="bottom">Bottom 10</option>
                  </select>
              </Form.Group>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}   