import config_local from "../../conf/local_config.json";
/*-------------------------Actual APIs-------------------------*/
const backendApi = "/api";
const signInUrl = `/auth/sign_in?post_sign_in_url=https%3A%2F%2F`;
const signOutUrl = `/auth/sign_out`;
const authUrl = `${backendApi}/isauthenticated`;
const userInfoUrl = `${backendApi}/userinfo?post_sign_in_url=https%3A%2F%2F`;
const authoriseUrl = `${backendApi}/isauthorised?post_sign_in_url=https%3A%2F%2F`;
const demoAppUrl = `${backendApi}/demoApp`;
const masterAppUrl = `/master/demoApp`;
const apiURL = `${config_local.api_domain}${backendApi}`;

const messages = {
  workInProgressMessage: "Welcome to CPFR Internal.",
  authenticatingUserMessage: "Authenticating user",
  loginPrimaryText: "Log in to find out more!",
  UnauthorisedMessage:
    "You don't have access to this app. Please contact support for the access.",
};

const hardCoded = {
  //homeLocation: "http://local-forecast-frontend.net:5000/home/",
  //homeLocation: "http://cpfr.heliosz.ai/home/",
  homeLocation: "",
  appName: "Base App",
};

const api = {
  authUrl,
  userInfoUrl,
  signInUrl,
  signOutUrl,
  demoAppUrl,
  masterAppUrl,
  authoriseUrl,
  apiURL
};

const config = {
  footerText: "Powered by InternalApp",
  footerLink: "InternalApp",
  api,
  messages,
  hardCoded,
};

export { config };
