import { authTypes } from "../types/authTypes";

export default function (state = {}, action) {
    switch (action.type) {
        case authTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case authTypes.IS_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.payload,
            }
        case authTypes.SET_USER_DATA:
            return {
                ...state,
                userData: action.payload,
            }
        case authTypes.SET_MESSAGE:
            return {
                ...state,
                message: action.payload,
            }
        default:
            return state;
    }
}