import { authTypes } from "../types/authTypes";
import { POST } from '../../utils/axios';

// dummy data
import authDummyData from "../../../assets/data/auth/user.json";

export const setLoad = (isLoading) => {
    return {
        type: authTypes.SET_LOADING,
        payload: isLoading,
    };
};

export const setMessage = (msgData) => {
    return {
        type: authTypes.SET_MESSAGE,
        payload: msgData,
    };
};

// Authentication API
export const login = (postData) => {
    return async (dispatch) => {
        dispatch(setLoad(true));
        dispatch(setMessage(""));
        const apiResData = await loginReq(postData);
        dispatch(setLoggedInState(apiResData.is_authenticated));
        dispatch(setLoggedInUserData(apiResData.userData));
        dispatch(setMessage(apiResData.message));
        dispatch(setLoad(false));
    };
}

const loginReq = async (postData) => {
    try {
        const apiResData = authDummyData // await POST(`/isauthenticated`, postData);
        if (apiResData.status.statusCode === "200") {
            window.localStorage.setItem("user", JSON.stringify(apiResData.data.userData));
            var finalData = { ...apiResData.data, message: 'success' };
            return finalData;
        }
        return {
            is_authenticated: false,
            userData: [],
            message: 'Error!'
        };
    } catch (error) {
        return {
            is_authenticated: false,
            userData: [],
            message: error.toString()
        };
    }
}
export const setLoggedInState = (data) => {
    return {
        type: authTypes.IS_LOGGED_IN,
        payload: data,
    };
};

export const setLoggedInUserData = (data) => {
    return {
        type: authTypes.SET_USER_DATA,
        payload: data,
    };
};

export const logout = () => {
    localStorage.removeItem("user");
}