export const otifTypes = {
     SET_GLOBAL_SEARCH_VALUES: 'SET_GLOBAL_SEARCH_VALUES',
     SET_ON_TIME_ACCOUNTABILITY: 'SET_ON_TIME_ACCOUNTABILITY',
     SET_IN_FULL_ACCOUNTABILITY: 'SET_IN_FULL_ACCOUNTABILITY',
     SET_OTIF_CHART_DATA:'SET_OTIF_CHART_DATA',
     SET_OTIF_ONTIME_BAR_CHART: 'SET_OTIF_ONTIME_BAR_CHART',
     SET_OTIF_INFULL_BAR_CHART: 'SET_OTIF_INFULL_BAR_CHART',
     SET_DATERANGE_SEARCH_VALUES: 'SET_DATERANGE_SEARCH_VALUES',      
     SET_ON_TIME_STATS: 'SET_ON_TIME_STATS',
     SET_IN_FULL_STATS: 'SET_IN_FULL_STATS'
}