import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { logout } from "../../redux/actions/authActions";
import { userFromLocalStorage } from "../../config/localStorage";
// Import data/utils
//import logo from "../../../assets/images/logo.svg";
import logo from "../../../assets/images/heliosz-logo.jpg";
//import mini_logo from "../../../assets/images/logo-mini.svg";
import mini_logo from "../../../assets/images/heliosz-mini-logo.jfif";
import profileImg from "../../../assets/images/faces/face1.jpg";
class Header extends Component {
    toggleOffcanvas() {
        document.querySelector('.sidebar-offcanvas').classList.toggle('active');
    }
    toggleRightSidebar() {
        document.querySelector('.right-sidebar').classList.toggle('open');
    }
    render() {
        return (
            <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                    <Link className="navbar-brand brand-logo" to="/"><img src={logo} alt="logo" /></Link>
                    <Link className="navbar-brand brand-logo-mini" to="/"><img src={mini_logo} alt="logo" /></Link>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-stretch">
                    <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
                        <span className="mdi mdi-menu"></span>
                    </button>
                    <div className="search-field d-none d-md-block">
                        <form className="d-flex align-items-center h-100" action="#">
                            <div className="input-group">
                                <div className="input-group-prepend bg-transparent">
                                    <i className="input-group-text border-0 mdi mdi-magnify"></i>
                                </div>
                                <input type="text" className="form-control bg-transparent border-0" placeholder="Search projects" />
                            </div>
                        </form>
                    </div>
                    <ul className="navbar-nav navbar-nav-right">
                        {/* <li className="nav-item">
                            <Dropdown alignRight>
                                <Dropdown.Toggle className="nav-link count-indicator">
                                    <i className="mdi mdi-bell-outline"></i>
                                    <span className="count-symbol bg-danger"></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                                    <h6 className="p-3 mb-0"><Trans>Notifications</Trans></h6>
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                                        <div className="preview-thumbnail">
                                            <div className="preview-icon bg-success">
                                                <i className="mdi mdi-calendar"></i>
                                            </div>
                                        </div>
                                        <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                                            <h6 className="preview-subject font-weight-normal mb-1"><Trans>Event today</Trans></h6>
                                            <p className="text-gray ellipsis mb-0">
                                                <Trans>Just a reminder that you have an event today</Trans>
                                            </p>
                                        </div>
                                    </Dropdown.Item>
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                                        <div className="preview-thumbnail">
                                            <div className="preview-icon bg-warning">
                                                <i className="mdi mdi-settings"></i>
                                            </div>
                                        </div>
                                        <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                                            <h6 className="preview-subject font-weight-normal mb-1"><Trans>Settings</Trans></h6>
                                            <p className="text-gray ellipsis mb-0">
                                                <Trans>Update dashboard</Trans>
                                            </p>
                                        </div>
                                    </Dropdown.Item>
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                                        <div className="preview-thumbnail">
                                            <div className="preview-icon bg-info">
                                                <i className="mdi mdi-link-variant"></i>
                                            </div>
                                        </div>
                                        <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                                            <h6 className="preview-subject font-weight-normal mb-1"><Trans>Launch Admin</Trans></h6>
                                            <p className="text-gray ellipsis mb-0">
                                                <Trans>New admin wow</Trans>!
                                            </p>
                                        </div>
                                    </Dropdown.Item>
                                    <div className="dropdown-divider"></div>
                                    <h6 className="p-3 mb-0 text-center cursor-pointer"><Trans>See all notifications</Trans></h6>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li> */}
                        <li className="nav-item nav-profile">
                            <Dropdown alignRight>
                                <Dropdown.Toggle className="nav-link">
                                    {/* <div className="nav-profile-img">
                                        <img src={profileImg} alt="user" />
                                        <span className="availability-status online"></span>
                                    </div> */}
                                    <div className="nav-profile-text">
                                        <p className="mb-1 text-black"><Trans>{userFromLocalStorage('name')}</Trans></p>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="navbar-dropdown">
                                    {/* <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                                        <i className="mdi mdi-cached mr-2 text-success"></i>
                                        <Trans>Activity Log</Trans>
                                    </Dropdown.Item> */}
                                    <Dropdown.Item href="/login" onClick={evt => logout()}>
                                        <i className="mdi mdi-logout mr-2 text-primary"></i>
                                        <Trans>Signout</Trans>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    </ul>

                </div>
            </nav>
        );
    }
}

export default Header;
