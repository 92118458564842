import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getOTIFChartData, setDateRangeSearchValues } from '../../../redux/actions/otifActions';

import { todayDate, getLastnthWeeksDate, cleanArrObject, getLastnthWeeksDatePicker, todayDatePicker } from '../../../utils/common-functions';

export default function OtifFilters(props) {
    //const { } = props;
    const otifGlobalSearchValues = useSelector(({ otifReducer }) => otifReducer.otifGlobalSearchValues);
    //const otifDateSearchValues = useSelector(({ otifReducer }) => otifReducer.otifDateRangeSearchValues);
    const dispatch = useDispatch();

    // const [fromDate, setfromDate] = useState(new Date(getLastnthWeeksDate(13)));
    // const [toDate, settoDate] = useState(new Date(todayDate()));

    //27 march 2023 Datepicker not suuporting moment js. Changes are added to test the behaviour of date in datepicker
    const [fromDate, setfromDate] = useState(getLastnthWeeksDatePicker(13));
    const [toDate, settoDate] = useState(todayDatePicker());

    // useEffect(() => {
        
    //     if( otifDateSearchValues.from_date !== "" && otifDateSearchValues.to_date !== ""){
    //         setfromDate(new Date(otifDateSearchValues.from_date));
    //         settoDate(new Date(otifDateSearchValues.to_date));
    //     }
    // },[otifDateSearchValues])

    
    const myCustomhandleResetFn = () => {
        setfromDate('');
        settoDate('');
        dispatch(setDateRangeSearchValues({'from_date':'', 'to_date':''}));
        //Important 27 April 2023 commented the dispatch method as these are calling dremio queries 
        //UnComment when connected to connector dataource 
        //dispatch(getOTIFChartData(otifGlobalSearchValues));
    }

    return (
        <Formik
            initialValues={{
                from_date: new Date(getLastnthWeeksDate(13)),
                to_date: new Date(todayDate())
            }}
            validate={(values) => {
                const errors = {};
                const fromDateNew = new Date(values.from_date);
                const toDateNew = new Date(values.to_date);

                if (!values.from_date) {
                    errors.from_date_error = 'This field is required!';
                } 
                if( !values.to_date ){
                    errors.to_date_error = 'This field is required!';
                } 
                if ((values.from_date !== "" && values.to_date !== "") && (fromDateNew > toDateNew)) {
                    errors.from_date_error = 'To date should be greater than from date!';
                }
                return errors;
            }}
            onSubmit={values => {
                const { from_date, to_date } = values;
                const params = { from_date, to_date };  
                //Important 27 April 2023 commented the dispatch method as these are calling dremio queries 
                //UnComment when connected to connector dataource               
                //dispatch(setDateRangeSearchValues(values));
                const newFilter = { ...cleanArrObject(otifGlobalSearchValues), ...cleanArrObject(params) };
                //dispatch(getOTIFChartData(newFilter));
            }}

            onReset={(values) => {}}

        >
            {({ values, errors, setFieldValue, handleSubmit, handleReset, setErrors }) => (
                <form className="form-sample" onSubmit={handleSubmit}>
                    <div className='row'>
                    <div className="col-md-5">
                        {/* <h4 className="card-title">Date Range Filter</h4> */}
                    </div>
                    
                        <div className="col-md-2">
                            <Form.Group className="row">
                                <div className="col-sm-12">
                                    <label className="sr-only" htmlFor="inlineFormInputName2">From Date</label>
                                    <DatePicker className="form-control w-100"
                                        placeholderText='From Date'
                                        selected={fromDate}
                                        onChange={(val) => { setfromDate(val); setFieldValue('from_date', (val) ? val.toLocaleDateString('en-GB').replaceAll('/', '-').split('-').reverse().join('-') : '') }}
                                    />
                                    <div className='error'>{errors.from_date_error}</div>
                                </div>
                                
                            </Form.Group>
                            
                        </div>
                        <div className="col-md-2">
                            <Form.Group className="row">
                                <div className="col-sm-12">
                                    <label className="sr-only" htmlFor="inlineFormInputName2">To Date</label>
                                    <DatePicker className="form-control w-100"
                                        placeholderText='To Date'
                                        selected={toDate}
                                        onChange={(val) => { settoDate(val); setFieldValue('to_date', (val) ? val.toLocaleDateString('en-GB').replaceAll('/', '-').split('-').reverse().join('-') : '') }}
                                    />
                                    <div className='error'>{errors.to_date_error}</div>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3 text-left">
                            <button type="reset" className="btn btn-outline-secondary btn-md"
                                onClick={() => { setErrors({}); myCustomhandleResetFn(); }}
                            > Reset </button>
                            <button type="button" className="btn btn-gradient-danger btn-md"
                                onClick={() => { handleSubmit(); }}
                            > Filter </button>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}   