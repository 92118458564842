import React, { Component } from "react";
import { CSVLink } from "react-csv";

import { connect } from "react-redux";
import { getInventoryDataForExport } from "../../../redux/actions/instockActions";

import { cleanArrObject } from "../../../utils/common-functions";

const headers = [
  {
    key: "region",
    label: "Region",
  },
  {
    key: "customer",
    label: "Customer",
  },
  {
    key: "segment",
    label: "Segment",
  },
  {
    key: "sku",
    label: "SKU",
  },
  {
    key: "retailer_sku",
    label: "Retailer SKU",
  },
  {
    key: "product_description",
    label: "SKU Name",
  },
  {
    key: "store",
    label: "Store Number",
  },
  {
    key: "dc",
    label: "DC Number",
  },
  {
    key: "brand_name",
    label: "Brand Name",
  },
  //Remove pos_average
  //Rename average_forecast to avg_pos_forecast_4w
  //dc_order_forecast to "sum_order_forecast_4w" in Frontend
  
  {
    key: "avg_pos_forecast_4w",
    label: "Average Forecast POS (4W)",
    style: {},
  },
  {
    key: "sum_order_forecast_4w",
    label: "Sum Order Forecast 4W",
  },
  {
    key: "store_level_inventory",
    label: "Store Inv. LW",
  },
  {
    key: "store_week_of_supply",
    label: "Store WOC (POS 4W AVG)",
  },
  {
    key: "dc_inventory",
    label: "DC Inventory",
  },
  {
    key: "dc_week_of_supply",
    label: "DC WOC (POS 4W AVG)",
  },
  // Add avg_pos_historical_4w_store, avg_pos_historical_4w_dc, dc_future_woc
  {
    key: "avg_pos_historical_4w_store",
    label: "Store (POS Historical 4W AVG )",
  },
  {
    key: "avg_pos_historical_4w_dc",
    label: "DC (POS Historical 4W AVG )",
  },
  {
    key: "dc_future_woc",
    label: "DC Future WOC",
  },
  {
    key: "dc_current_suggestion",
    label: "DC Current Suggestion",
  },
  {
    key: "dc_future_suggestion",
    label: "DC Future Suggestion",
  },
  {
    key: "risk",
    label: "Risk",
  },
  {
    key: "pos_conversion_factor",
    label: "Case Pack",
  },
  {
    key: "pos_conversion_factor_pallets",
    label: "Pallet Qty",
  }
];
  


class ExportInventoryCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      IsDataLoading: false,
      hasData: false
    };
    this.csvLinkEl = React.createRef();
  }
  
  fetchDataFromServer = async () => {
    var filterItems = this.getFilteredValues();
    //const limit = 0;
    const queryParams = {
      batch: 1,
      batchSize: 100,
      download: true
    }
    const apiRes = await getInventoryDataForExport(filterItems, queryParams);
    return apiRes;
  };
  

  downloadReport = async () => {  
    if (!this.state.IsDataLoading) {      
      this.setState({ IsDataLoading: true });      
      let data = await this.fetchDataFromServer();
      
      //this.csvLinkEl.current.link.click();
      //Feb 13 2023 To handle huge volume issue
      // If the downloaded data not available display "No Data to Export" error
      if (data && data.length > 0) {

        this.setState({ hasData: true, errorMessage: '' });

        this.setState({ data: data, IsDataLoading: false }, () => {
          setTimeout(() => {      
            const win = window.open(data, '_blank');
            win.focus();          
          });
        });        
        
      } else
      {
        this.setState({ hasData: false, errorMessage: 'No Data to Export', IsDataLoading: false });    
        setTimeout(() => {
          this.setState({ errorMessage: '' }); 
        }, "6000")
      }
    }
  };

  getFilteredValues = () => {
    var instockSearchValues =
      this.props.inStockDataObj.instockGlobalSearchValues;
    var instockInventorySearchValues =
      this.props.inStockDataObj.instockInventorySearchValues;
    var instockDateRangeSearchValues =
      this.props.inStockDataObj.instockDateRangeSearchValues;

    //27 Jan 2023 Ensure Same payload for API and Export API to avoid dremio queries getting called  
    //const newArr = { limit: 0 };
    const allFilterObj = {
      ...cleanArrObject(instockSearchValues),
      ...cleanArrObject(instockInventorySearchValues),
      //...cleanArrObject(instockDateRangeSearchValues),
      //...newArr,
    };
    return allFilterObj;
  };

  render() {
    const { data } = this.state;
    return (
      <div>
        <input
          type="button"
          debugger
          value={
            this.state.IsDataLoading && !this.state.hasData ? "Loading csv..." : "Export All Data"
          }
          className="btn btn-gradient-info label-right font-weight-bold px-lg-4 px-3"
          onClick={this.downloadReport}
        />
        
        <CSVLink
          headers={headers}
          filename={"ExportedInventoryData.csv"}
          asyncOnClick={true}
          data={data}
          //ref={this.csvLinkEl}
        //className="btn btn-gradient-info label-right font-weight-bold px-lg-4 px-3"
        //onClick={this.downloadReport}
        />
        {/* {this.state.IsDataLoading ? 'Loading csv...' : 'Export All Data'}
        </CSVLink> */}        
        
        {/* //Feb 13 2023 To handle huge volume issue
        // If the downloaded data not available display "No Data to Export" error */}
        {(this.state.errorMessage?.length > 0 && !(this.state.hasData)) && <div className="error"> {this.state.errorMessage} </div>}
        
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    inStockDataObj: state.instockReducer,
  };
}
const mapDispatchToProps = { getInventoryDataForExport };
export default connect(mapStateToProps, mapDispatchToProps)(ExportInventoryCSV);
