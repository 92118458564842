import { config } from '../config/config';
import Axios from 'axios';
//import { getToken, logoutInternal } from './cookie';

const URL = config.api.apiURL;
console.log("URL IS", URL);

// doc - https://github.com/axios/axios
const axiosInstance = Axios.create({
    baseURL: URL,
    withCredentials: true,
    headers: {
        'Cache-control': 'no-cache, no-store',
        'Pragma': 'no-cache',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        //'Set-Cookie':'session=78c595bf-41f6-4633-88d3-3b328d5cc186'
    },
    // timeout: 10000,
    transformRequest: [
        function (data, headers) {
            headers['Expires'] = '0';
            //if (getToken()) headers['Authorization'] = `Bearer ` + getToken();
            return JSON.stringify(data);
        },
    ],
    transformResponse: [
        function (data, headers) {
            headers['Access-Control-Allow-Origin'] = '*';
            if (headers['content-type'].indexOf('application/json') > -1) {
                const json = JSON.parse(data);
                return json;
            }
            return data;
        },
    ],
});

axiosInstance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
        console.log("Authorization Error!");
        //return logoutInternal();
    }
    if (error?.response?.status === 500) return;
    throw new Error();
})

export const GET = async (url) => {
    const res = await axiosInstance.get(url);
    return res.data;
}

export const POST = async (url, data) => {
    const res = await axiosInstance.post(url, data);
    return res.data;
}

export const PUT = async (url, data) => {
    const res = await axiosInstance.put(url, data);
    return res.data;
}

export const DELETE = async (url,data) => {
    const res = await axiosInstance.delete(url,data);
    return res.data;
}

