import React, { Component } from 'react';
import { CSVLink } from "react-csv";

import { connect } from "react-redux";
import { getForecastDataForExport } from '../../../redux/actions/forecastActions';

import { cleanArrObject } from '../../../utils/common-functions';

const headers = [
  {
    key: "region",
    label: "Region",
  },
  {
    key: "customer",
    label: "Customer",
  },
  {
    key: "segment",
    label: "Segment",
  },
  {
    key: 'bu',
    label: 'BU'
  },
  {
    key: 'brand_name',
    label: 'Brand Name'
  },  
  {
    key: 'rb_sku_code',
    label: 'RB SKU CODE',
  },
  {
    key: 'prod_sku_desc',
    label: 'Product Sku Desc',
  },
  {
    key: 'forecast_week',
    label: 'Forecast Week'
  },
  {
    key: 'current_week',
    label: 'Current Week'
  },
  {
    key: 'forecast_current_week',
    label: 'Forecast current week eaches'
  },
  {
    key: 'forecast_current_week_cases',
    label: 'Forecast current week cases'
  },
  {
    key: 'projected_order_current_week',
    label: 'Projected Order Current Week eaches'
  },
  {
    key: 'projected_order_current_week_cases',
    label: 'Projected Order Current Week cases'
  },
  {
    key: 'total_inventory',
    label: 'Total Inventory Eaches'
  },
  {
    key: 'total_inventory_cases',
    label: 'Total Inventory cases'
  },
  {
    key: 'prior_week',
    label: 'Prior Week'
  },
  {
    key: 'forecast_prior_week',
    label: 'Forecast Prior Week'
  },
  {
    key: 'projected_order_prior_week',
    label: 'Projected Order Prior Week'
  },
  {
    key: 'forecast_woc',
    label: 'Forecast WOC'
  },
  {
    key: 'forecast_variance_value',
    label: 'Forecast Variance Value'
  },
  {
    key: 'forecast_variance_percent',
    label: 'Forecast Variance Percent'
  },
  {
    key: 'forecast_variance_flag',
    label: 'Forecast Variance Flag'
  },
  {
    key: 'projected_order_variance_value',
    label: 'Projected Order Variance Value'
  },
  {
    key: 'projected_order_variance_percent',
    label: 'Projected Order Variance Percent'
  },
  {
    key: 'projected_order_variance_flag',
    label: 'Projected Order Variance Flag'
  },
  {
    key: 'pos_avg_4w',
    label: '4 Weeks Avg POS'
  }
]
 
class ExportForecastCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      IsDataLoading:false
    }
    this.csvLinkEl = React.createRef();
  }
 
  fetchDataFromServer = async () => {
    var filterItems = this.getFilteredValues();
    const apiRes = await getForecastDataForExport(filterItems);
      return apiRes;
  }
 
  downloadReport = async () => {
    if(!this.state.IsDataLoading){
      this.setState({ IsDataLoading: true });
      const data = await this.fetchDataFromServer();  
      this.setState({ data: data, IsDataLoading:false }, () => {
          setTimeout(() => {
            this.csvLinkEl.current.link.click();
          });
        });
    }
  }
 
  getFilteredValues = ( ) => {
    var forecastSearchValues = this.props.forecastDataObj.forecastSearchValues;
    const { region, customer, dc, bu, brand, from_date, to_date } = forecastSearchValues;
    var searchValue = {};
    const params = { region, customer, dc, bu, brand, from_date, to_date };
    Object.keys(params).forEach((param, index) => {
      if(params[param]) {
        searchValue[param] = params[param];
      }
    });
  
    const newArr = { 'limit': 0 };
    const allFilterObj = { ...cleanArrObject(searchValue), ...newArr }
    return allFilterObj;
  }


  render() {
    const {data} = this.state;
    return (
      <div>
        <input type="button" value={this.state.IsDataLoading ? 'Loading csv...' : 'Export SKU Level data'} className="btn btn-gradient-info label-right font-weight-bold px-lg-4 px-3" onClick={this.downloadReport} />
        <CSVLink
          headers={headers}
          filename={"Exported_SKU_Forecast_Data.csv"}
          asyncOnClick={true}
          data={data}
          ref={this.csvLinkEl}
        />
      </div>
    );
  }
}
 
function mapStateToProps(state) {
    return {
      forecastDataObj: state.forecastReducer
    }
}
const mapDispatchToProps = { getForecastDataForExport };
export default connect(mapStateToProps, mapDispatchToProps)(ExportForecastCSV);
