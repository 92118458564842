import React, { Component } from "react";
// Import redux
import { connect } from "react-redux";
import {
  setAnalystKpiData,
  setAnalystPOSChartData,
  setAnalystOOSChartData,
  setAnalystOTIFChartData,
  setAnalystLandingAggregateData,
} from "../../redux/actions/analystLandingActions";
import {
  setCustomerKpiData,
  setBuKpiData,
  setBrandKpiData,
} from "../../redux/actions/executivePageActions";

import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
// import child components
import CockpitGlobalFilters from "./Filters/CockpitGlobalFilters";
import DateRangeFilter from "./Filters/DateRangeFilter";
//import ExecutiveGlobalFilter from "./Filters/ExecutiveGlobalFilter";

import KPI from "./Components/KPI";
import KPIOTIF from "./Components/KPIOTIF";
import POS from "./Components/POS";
import OOS from "./Components/OOS";
import OTIF from "./Components/OTIF";
import AnalystAggregate from "./Components/AnalystAggregate";

// import child components
import ExecutiveCustomerKpi from "./Components/ExecutiveCustomerKpi";
import ExecutiveBuKpi from "./Components/ExecutiveBuKpi";
import ExecutiveBrandKpi from "./Components/ExecutiveBrandKpi";
import ExecutiveSegment from "./Components/ExecutiveSegment";
import ShowPopup from "../../components/NewWindow";
import PivotTableComponent from "./Components/PivotTable";


import {
  ExportCustomerCSV,
  ExportBrandCSV,
  ExportBuCSV,
  ExportSegmentCSV,
} from "./ExportData";
import {
  todayDate,
  getLastnthWeeksDate,
  dateFormatter,
} from "../../utils/common-functions";

class Cockpit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: {},
      isSearchDataLoaded: false,
      isCustomerLinkClicked: false,
      isBrandLinkClicked: false,
      isBuLinkClicked: false,
      isSegmentLinkClicked: false
    };
  }

  componentDidMount() {
    //On page load clear redux state
    // this.props.setAnalystKpiData([]);
    // this.props.setAnalystPOSChartData([]);
    // this.props.setAnalystOOSChartData([]);
    // this.props.setAnalystOTIFChartData([]);
    // this.props.setAnalystLandingAggregateData([]);
    // this.props.setCustomerKpiData([]);
    // this.props.setBuKpiData([]);
    // this.props.setBrandKpiData([]);
  }
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <CockpitGlobalFilters />
        </div>
        <div className="col-lg-12 grid-margin">
          {/* <div className="">
            <div className=""> */}
              <div className="d-flex justify-content-between align-items-center">
                {/* <h4 className="card-title">KPIs </h4> */}
                {/* <p className="card-description mb-2">
                  current week
                  <code>({dateFormatter(todayDate(), "MM/DD/YYYY")})</code> vs
                  last week
                  <code>
                    ({dateFormatter(getLastnthWeeksDate(1), "MM/DD/YYYY")})
                  </code>
                </p> */}
              </div>
              <ErrorBoundary>
                <KPI
                  data={this.props.analystLandingObj.analystKpiData}
                  isLoading={this.props.analystLandingObj.isLoadingRequestIds.includes(
                    "isKpiDataLoaded"
                  )}
                />
              </ErrorBoundary>
              <ErrorBoundary>
                <KPIOTIF
                  otifData={this.props.analystLandingObj.analystKpiData}
                  isLoading={this.props.analystLandingObj.isLoadingRequestIds.includes(
                    "isKpiDataLoaded"
                  )}
                />
              </ErrorBoundary>
            {/* </div>
          </div> */}
        </div>
        <div className="col-md-12 grid-margin">
          <div className="row">
            <div className="col-md-6">
              <DateRangeFilter />
              <ShowPopup name="Trends">
                <div className="stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <ErrorBoundary>
                        <POS
                          data={
                            this.props.analystLandingObj.analystPOSChartData
                          }
                          isLoading={this.props.analystLandingObj.isLoadingRequestIds.includes(
                            "isPOSChartDataLoaded"
                          )}
                        />
                      </ErrorBoundary>
                    </div>
                  </div>
                </div>
                <div className="stretch-card">
                  <div className="card">
                    <div className="card-body">
                      {/* <h4 className="card-title">OOS Trends</h4> */}
                      <ErrorBoundary>
                        <OOS
                          data={
                            this.props.analystLandingObj.analystOOSChartData
                          }
                          isLoading={this.props.analystLandingObj.isLoadingRequestIds.includes(
                            "isOOSChartDataLoaded"
                          )}
                        />
                      </ErrorBoundary>
                    </div>
                  </div>
                </div>
                <div className="stretch-card">
                  <div className="card">
                    <div className="card-body">
                      {/* <h4 className="card-title">On Time, in Full, OTIF</h4> */}
                      <ErrorBoundary>
                        <OTIF
                          data={
                            this.props.analystLandingObj.analystOTIFChartData
                          }
                          isLoading={this.props.analystLandingObj.isLoadingRequestIds.includes(
                            "isOOSChartDataLoaded"
                          )}
                        />
                      </ErrorBoundary>
                    </div>
                  </div>
                </div>
              </ShowPopup>
              <div className="grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">KPI Table</h4>
                    <div className="table-responsive scrolling-lock-table-wrapper">
                      <ErrorBoundary>
                        <AnalystAggregate
                          data={
                            this.props.analystLandingObj
                              .analystLandingAggregateData
                          }
                          customer={this.props.analystLandingObj}
                          isLoading={this.props.analystLandingObj.isLoadingRequestIds.includes(
                            "isAnalystAggregateDataLoaded"
                          )}
                        />
                      </ErrorBoundary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 grid-margin">
              <div className="stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex justify-content-between">
                        <h4 className="card-title">Customer</h4>
                        <span className="card-description ml-1">
                          {" "}
                          (Latest Week - {dateFormatter(todayDate(), "MM/DD/YYYY")})
                        </span>
                      </div>
                      <ExportCustomerCSV />
                    </div>
                    <ShowPopup name="customer" onlinkclick={(isLinkClicked) => this.setState({ isCustomerLinkClicked: isLinkClicked })}>
                      <ErrorBoundary>
                        <ExecutiveCustomerKpi
                          data={this.props.executivePageObj.customerKpiData}
                          isLoading={this.props.executivePageObj.isLoadingRequestIds.includes(
                            "isCustomerKpiDataLoaded"
                          )}
                          isLinkClicked={this.state.isCustomerLinkClicked}
                        />
                      </ErrorBoundary>
                    </ShowPopup>
                  </div>
                </div>
              </div>
              <div className="stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex justify-content-between">
                        <h4 className="card-title">BU</h4>
                        <span className="card-description ml-1">
                        (Latest Week - {dateFormatter(todayDate(), "MM/DD/YYYY")})
                        </span>
                      </div>
                      <ExportBuCSV />
                    </div>
                    <ShowPopup name="bu" onlinkclick={(isLinkClicked) => this.setState({ isBuLinkClicked: isLinkClicked })}>
                      <ErrorBoundary>
                        <ExecutiveBuKpi
                          data={this.props.executivePageObj.buKpiData}
                          isLoading={this.props.executivePageObj.isLoadingRequestIds.includes(
                            "isBuKpiDataLoaded"
                          )}
                          isLinkClicked={this.state.isBuLinkClicked}
                        />
                      </ErrorBoundary>
                    </ShowPopup>
                  </div>
                </div>
              </div>
              <div className="grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex justify-content-between">
                        <h4 className="card-title">Brand</h4>
                        <span className="card-description ml-1">
                        (Latest Week - {dateFormatter(todayDate(), "MM/DD/YYYY")})
                        </span>
                      </div>
                      <ExportBrandCSV />
                    </div>
                    <ShowPopup name="brand" onlinkclick={(isLinkClicked) => this.setState({ isBrandLinkClicked: isLinkClicked })}>
                      <ErrorBoundary>
                        <ExecutiveBrandKpi
                          data={this.props.executivePageObj.brandKpiData}
                          kpiData={this.props.executivePageObj.customerKpiData}
                          isLoading={this.props.executivePageObj.isLoadingRequestIds.includes(
                            "isBrandKpiDataLoaded"
                          )}
                          isLinkClicked={this.state.isBrandLinkClicked}
                        />
                      </ErrorBoundary>
                    </ShowPopup>
                  </div>
                </div>
              </div>
              <div className="grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex justify-content-between">
                        <h4 className="card-title">Segment</h4>
                        <span className="card-description ml-1">
                        (Latest Week - {dateFormatter(todayDate(), "MM/DD/YYYY")})
                        </span>
                      </div>
                      <ExportSegmentCSV />
                    </div>
                    <ShowPopup name="segment" onlinkclick={(isLinkClicked) => this.setState({ isSegmentLinkClicked: isLinkClicked })}>
                      <ErrorBoundary>
                        <ExecutiveSegment
                          data={this.props.executivePageObj.segmentKpiData}
                          kpiData={this.props.executivePageObj.customerKpiData}
                          isLoading={this.props.executivePageObj.isLoadingRequestIds.includes(
                            "isSegmentKpiDataLoaded"
                          )}
                          isLinkClicked={this.state.isSegmentLinkClicked}
                        />
                      </ErrorBoundary>
                    </ShowPopup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 grid-margin">
          <div className="row">
            <div className="stretch-card">
              <div className="card">
                <div className="card-body">
                  <ErrorBoundary>
                    <PivotTableComponent
                      data={this.props.executivePageObj.executivePivotData}
                      isLoading={this.props.executivePageObj.isLoadingRequestIds.includes(
                        "isExecutivePivotDataLoaded"
                      )}
                    />
                  </ErrorBoundary>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    executivePageObj: state.executivePageReducer,
    analystLandingObj: state.analystLandingReducer,
  };
}
const mapDispatchToProps = {
  setAnalystKpiData,
  setAnalystPOSChartData,
  setAnalystOOSChartData,
  setAnalystOTIFChartData,
  setAnalystLandingAggregateData,
  setCustomerKpiData,
  setBuKpiData,
  setBrandKpiData,
};
export default connect(mapStateToProps, mapDispatchToProps)(Cockpit);
