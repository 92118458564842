import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import ErrorBoundary from "../../../components/ErrorBoundary/ErrorBoundary";
import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { PieChart } from "../../../components/Charts/index";
import InFullBarChart from './InFullBarChart';
import { DT_CellFormatterWithComma } from "../../../utils/common-functions";
import { cleanArrObject } from '../../../utils/common-functions';
import '../Otif.scss';

import {
    getInFullAccountabilityData,
    setOnTimeAccountabilityData,
  } from "../../../redux/actions/otifActions";

export default function InFullHistogram(props) {
    const dispatch = useDispatch();
    const { data, data3, data3_isLoading, isLoading } = props;
    const totalOfOnTimeAcc = [0, 0, 0];
    const totalOfOnTimeAccForChart = [0, 0];
    const [category, setCategory] = useState('brand');
    const otifSearchValues = useSelector(({ otifReducer }) => otifReducer.otifGlobalSearchValues);

    if (data3_isLoading) {
        return (
            <DataLoader
                fullPage={false}
                message="Fetching data"
            />
        );
    }
    if (data?.length === 0) {
        return <NoDataFound message={"There is no data for selected filters!"} />;
    }
    
    const filterDataCategoryWise = (selectedValue) => {
       const filterForDate = { "category": selectedValue }
       const newfilterForIFAccTable = { ...cleanArrObject(otifSearchValues), ...filterForDate };
       //Important 27 April 2023 commented the dispatch method as these are calling dremio queries 
       //UnComment when connected to connector dataource 
       //dispatch(getInFullAccountabilityData(newfilterForIFAccTable));
    }
      
    return (
        <div className="row">
            <div className="col-md-12">
                <ErrorBoundary>
                    <InFullBarChart data={data3}
                        isLoading={data3_isLoading} 
                        />
                </ErrorBoundary>
            </div>
        </div>

    );
}
//export default InFullHistogram;