import React, { Component } from "react";

// Import redux
import { connect } from "react-redux";

import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
//import child components
import ExportForecastCSV from "./ExportData/ExportForecastCSV";
import ExportBrandLevelForecastCSV from "./ExportData/ExportBrandLevelForecastCSV";
import ExportSegmentLevelForecastCSV from "./ExportData/ExportSegmentLevelForecastCSV";
import ForecastGlobalFilters from "./Filters/ForecastGlobalFilters";
import ForecastChart from "./Components/ForecastChart";
import ForecastDistribution from "./Components/ForecastDistribution";
import ForecastKpi from "./Components/ForecastKpi";
class Forecast extends Component {
  state = {};

  componentDidMount() {}

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <ForecastGlobalFilters />
          {/* <div className="card">
            <div className="card-body">
              <ForecastFilters />
            </div>
          </div> */}
        </div>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="card-title">KPIs </h4>
              </div>
              <ErrorBoundary>
                <ForecastKpi
                  data={this.props.forecastObj.forecastKpiData}
                  isLoading={this.props.forecastObj.isLoadingRequestIds.includes(
                    "isForecastKpiDataLoaded"
                  )}
                />
              </ErrorBoundary>
            </div>
          </div>
        </div>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Forecast</h4>
              <ErrorBoundary>
                <ForecastChart
                  data={this.props.forecastObj.forecastChartData}
                  isLoading={this.props.forecastObj.isLoadingRequestIds.includes(
                    "isForecastdataLoaded"
                  )}
                />
              </ErrorBoundary>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className='row'>
                <div className="col-md-6 text-center">
                  <Gauge 
                    value={58} width={300} height={190} label="BIAS Nat" 
                    topLabelStyle={{
                      display:"none",
                    }}
                  />
                  <h4 className="card-title">BIAS Nat</h4>
                </div>
                <div className="col-md-6 text-center">
                  <Gauge value={29} width={300} height={190} label="MAPE Nat" 
                    topLabelStyle={{
                      display:"none",
                    }}
                  />
                  <h4 className="card-title">MAPE Nat</h4>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
            <h4 className="card-title">Mape & Bias Distribution</h4>
                  {( this.props.forecastObj.isLoadingRequestIds.includes("isMbDistributionLoading") ) ? (
                    <DataLoader
                      fullPage={false}
                    //message="test"
                    />
                  ) : (
                    (
                      this.props.forecastObj.mbDistributionChartData && Object.keys(this.props.forecastObj.mbDistributionChartData).length > 0
                    ) ? (
                      <VeriticleBarChart
                        chartLabels={this.props.forecastObj.mbDistributionChartData.x_label}
                        chartDataSet={
                          [
                            {
                              label: 'MAP',
                              data: this.props.forecastObj.mbDistributionChartData.y_mape,
                              backgroundColor: 'rgb(255, 99, 132)',
                              stack: 'Stack 0',
                            },
                            {
                              label: 'BIAS',
                              data: this.props.forecastObj.mbDistributionChartData.y_bias,
                              backgroundColor: '#f59342',
                              stack: 'Stack 1',
                            }
                          ]
                        }
                      />
                    ) : (
                      <NoDataFound />
                    )

                  )}
            </div>
          </div>
        </div>       */}

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h4 className="card-title">Forecast Analysis - SKU Level</h4>
                <div className="d-flex justify-content-between">
                  <ExportBrandLevelForecastCSV />
                  <ExportForecastCSV />
                  <ExportSegmentLevelForecastCSV />
                </div>
              </div>
              <div className="table-responsive">
                <ErrorBoundary>
                  <ForecastDistribution
                    data={this.props.forecastObj.forecastDistributionData}
                    isLoading={this.props.forecastObj.isLoadingRequestIds.includes(
                      "isForecastDistributionLoading"
                    )}
                  />
                </ErrorBoundary>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    forecastObj: state.forecastReducer,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Forecast);
