import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
//import "bootstrap/dist/css/bootstrap.min.css";

import ErrorBoundary from "../../../components/ErrorBoundary/ErrorBoundary";
import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { PieChart } from "../../../components/Charts/index";
import OnTimeBarChart from './OnTimeBarChart';
import { DT_CellFormatterWithComma } from "../../../utils/common-functions";
import { cleanArrObject } from '../../../utils/common-functions';
import '../Otif.scss';

import {
    getOnTimeAccountabilityData,
    setOnTimeAccountabilityData,
  } from "../../../redux/actions/otifActions";

  export default function OnTimeAccountability(props) {
  const { data, data3, data3_isLoading, isLoading } = props;
  const totalOfOnTimeAcc  = [0, 0, 0, 0, 0];
  const totalOfOnTimeAccSegment = [0, 0, 0, 0, 0];
  const totalOfOnTimeAccSku = [0, 0, 0, 0, 0];
  const totalOfOnTimeAccForChart  = [0, 0, 0];
 
  const otifSearchValues = useSelector(({ otifReducer }) => otifReducer.otifGlobalSearchValues);
  const dispatch = useDispatch();

  const [category, setCategory] = useState('brand');

  if (isLoading) {
    return (
      <DataLoader
        fullPage={false}
        message="Fetching data"
      />
    );
  }
  if (data?.length === 0) {
    return <NoDataFound message={"There is no data for selected filters!"} />;
  }
  
  const filterDataCategoryWise = (selectedValue) => {
        const filterForDate = { "category": selectedValue }
        const newfilterForOTAccTable = { ...cleanArrObject(otifSearchValues), ...filterForDate };
        //Important 27 April 2023 commented the dispatch method as these are calling dremio queries 
        //UnComment when connected to connector dataource 
        dispatch(getOnTimeAccountabilityData(newfilterForOTAccTable));
  }
 
  return (
    <div className="row">
        <div className="col-md-3 ">
        <PieChart
            chartLabels={["Within Window", "Early", "Late"]}
            chartDataSet={
                [
                    {
                        //label: 'On Time Orders Actual',
                        data: totalOfOnTimeAccForChart,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)'
                        ],
                        borderWidth: 1,
                    }
                ]
            }
            showXAxis={false}
            showYAxis={false}
            graphTitle="On Time Accountability"
        />
        </div>
        
        <div className="col-md-9 remove-padding">
        <Tabs 
            id="controlled-tab-ontime"
            activeKey={category}      
            onSelect={(e) => { setCategory(e); filterDataCategoryWise(e); }}
            className="mb-3"
        >
        <Tab  id="onTimebrand"  eventKey="brand" title="Brand" >
            <div className="table-responsive my-custom-scrollbar">
                <table className="table table-bordered table-hover tbl-custom-width font-weight-normal">
                    <thead>
                        <tr>
                        <th className="text-center">Brands</th>
                        <th className="text-center">Shipped</th>
                        <th className="text-center">Shipped Within Window</th>
                        <th className="text-center">Shipped outside window</th>
                        <th className="text-center">Shipped Early</th>
                        <th className="text-center">shipped Late</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((obj, index) => {
                            totalOfOnTimeAcc[0] = parseInt(obj.shipped) + parseInt(totalOfOnTimeAcc[0]);
                            totalOfOnTimeAcc[1] = totalOfOnTimeAccForChart[0] = parseInt(obj.shipped_within_win) + parseInt(totalOfOnTimeAcc[1]);
                            totalOfOnTimeAcc[2] = parseInt(obj.shipped_outside_win) + parseInt(totalOfOnTimeAcc[2]);
                            totalOfOnTimeAcc[3] = totalOfOnTimeAccForChart[1] = parseInt(obj.shipped_early) + parseInt(totalOfOnTimeAcc[3]);
                            totalOfOnTimeAcc[4] = totalOfOnTimeAccForChart[2] = parseInt(obj.shipped_late) + parseInt(totalOfOnTimeAcc[4]);
                            
                        return (
                            <tr>
                                <th className="normalFont text-center">{obj.brand_name}</th>
                                <td className="text-center">{obj.shipped}</td>
                                <td className="text-center">{obj.shipped_within_win} ({obj.shipped==0 ? 'NA' : DT_CellFormatterWithComma(obj.shipped_within_win*100/obj.shipped) + '%'})</td>
                                <td className="text-center">{obj.shipped_outside_win} ({obj.shipped==0 ? 'NA' : DT_CellFormatterWithComma(obj.shipped_outside_win*100/obj.shipped) + '%'})</td>
                                <td className="text-center">{obj.shipped_early} ({obj.shipped==0 ? 'NA' : DT_CellFormatterWithComma(obj.shipped_early*100/obj.shipped) + '%'})</td>
                                <td className="text-center">{obj.shipped_late} ({obj.shipped==0 ? 'NA' : DT_CellFormatterWithComma(obj.shipped_late*100/obj.shipped) + '%'})</td>
                            </tr>
                        )
                        })}
                        
                    </tbody>
                    <tfoot>
                        <tr>
                            <th className="text-center">Total</th>
                            <th className="text-center">{totalOfOnTimeAcc[0]}</th>
                            <th className="text-center">{totalOfOnTimeAcc[1]}</th>
                            <th className="text-center">{totalOfOnTimeAcc[2]}</th>
                            <th className="text-center">{totalOfOnTimeAcc[3]}</th>
                            <th className="text-center">{totalOfOnTimeAcc[4]}</th>
                        </tr>
                    </tfoot>
                </table>
                </div>

        </Tab>
        <Tab id="onTimeSegment" eventKey="segment" title="Segment">  
            <div className="table-responsive my-custom-scrollbar">
                <table className="table table-bordered table-hover tbl-custom-width">
                    <thead>
                        <tr>
                        {/* <th className="text-center">Brands</th> */}
                        <th className="text-center">Segments</th>
                        <th className="text-center">Shipped</th>
                        <th className="text-center">Shipped Within Window</th>
                        <th className="text-center">Shipped outside window</th>
                        <th className="text-center">Shipped Early</th>
                        <th className="text-center">shipped Late</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((obj, index) => {
                            totalOfOnTimeAccSegment[0] = parseInt(obj.shipped) + parseInt(totalOfOnTimeAccSegment[0]);
                            totalOfOnTimeAccSegment[1] = totalOfOnTimeAccForChart[0] = parseInt(obj.shipped_within_win) + parseInt(totalOfOnTimeAccSegment[1]);
                            totalOfOnTimeAccSegment[2] = parseInt(obj.shipped_outside_win) + parseInt(totalOfOnTimeAccSegment[2]);
                            totalOfOnTimeAccSegment[3] = totalOfOnTimeAccForChart[1] = parseInt(obj.shipped_early) + parseInt(totalOfOnTimeAccSegment[3]);
                            totalOfOnTimeAccSegment[4] = totalOfOnTimeAccForChart[2] = parseInt(obj.shipped_late) + parseInt(totalOfOnTimeAccSegment[4]);
                            
                        return (
                            <tr>
                                {/* <th className="text-center">{obj.brand_name}</th> */}
                                <th className="normalFont text-center text-wrap">{obj.segment}</th>
                                <td className="text-center">{obj.shipped}</td>
                                <td className="text-center">{obj.shipped_within_win} ({obj.shipped==0 ? 'NA' : DT_CellFormatterWithComma(obj.shipped_within_win*100/obj.shipped) + '%'})</td>
                                <td className="text-center">{obj.shipped_outside_win} ({obj.shipped==0 ? 'NA' : DT_CellFormatterWithComma(obj.shipped_outside_win*100/obj.shipped) + '%'})</td>
                                <td className="text-center">{obj.shipped_early} ({obj.shipped==0 ? 'NA' : DT_CellFormatterWithComma(obj.shipped_early*100/obj.shipped) + '%'})</td>
                                <td className="text-center">{obj.shipped_late} ({obj.shipped==0 ? 'NA' : DT_CellFormatterWithComma(obj.shipped_late*100/obj.shipped) + '%'})</td>
                            </tr>
                        )
                        })}
                        
                    </tbody>
                    <tfoot>
                        <tr>
                            {/* <th className="text-center"></th> */}
                            <th className="text-center">Total</th>
                            <th className="text-center">{totalOfOnTimeAccSegment[0]}</th>
                            <th className="text-center">{totalOfOnTimeAccSegment[1]}</th>
                            <th className="text-center">{totalOfOnTimeAccSegment[2]}</th>
                            <th className="text-center">{totalOfOnTimeAccSegment[3]}</th>
                            <th className="text-center">{totalOfOnTimeAccSegment[4]}</th>
                        </tr>
                    </tfoot>
                </table>
                </div>

        </Tab>
        <Tab id="onTimeSku" eventKey="sku" title="SKU">  
            <div className="table-responsive my-custom-scrollbar">
                <table className="table table-bordered table-hover tbl-custom-width">
                    <thead>
                        <tr>
                        {/* <th className="text-center">Brands</th> */}
                        <th className="text-center">SKUs</th>
                        <th className="text-center">Shipped</th>
                        <th className="text-center">Shipped Within Window</th>
                        <th className="text-center">Shipped outside window</th>
                        <th className="text-center">Shipped Early</th>
                        <th className="text-center">shipped Late</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((obj, index) => {
                            totalOfOnTimeAccSku[0] = parseInt(obj.shipped) + parseInt(totalOfOnTimeAccSku[0]);
                            totalOfOnTimeAccSku[1] = totalOfOnTimeAccForChart[0] = parseInt(obj.shipped_within_win) + parseInt(totalOfOnTimeAccSku[1]);
                            totalOfOnTimeAccSku[2] = parseInt(obj.shipped_outside_win) + parseInt(totalOfOnTimeAccSku[2]);
                            totalOfOnTimeAccSku[3] = totalOfOnTimeAccForChart[1] = parseInt(obj.shipped_early) + parseInt(totalOfOnTimeAccSku[3]);
                            totalOfOnTimeAccSku[4] = totalOfOnTimeAccForChart[2] = parseInt(obj.shipped_late) + parseInt(totalOfOnTimeAccSku[4]);
                            
                        return (
                            <tr>
                                {/* <th className="text-center">{obj.brand_name}</th> */}
                                <th className="normalFont text-center text-wrap">{obj.sku}</th>
                                <td className="text-center">{obj.shipped}</td>
                                <td className="text-center">{obj.shipped_within_win} ({obj.shipped==0 ? 'NA' : DT_CellFormatterWithComma(obj.shipped_within_win*100/obj.shipped) + '%'})</td>
                                <td className="text-center">{obj.shipped_outside_win} ({obj.shipped==0 ? 'NA' : DT_CellFormatterWithComma(obj.shipped_outside_win*100/obj.shipped) + '%'})</td>
                                <td className="text-center">{obj.shipped_early} ({obj.shipped==0 ? 'NA' : DT_CellFormatterWithComma(obj.shipped_early*100/obj.shipped) + '%'})</td>
                                <td className="text-center">{obj.shipped_late} ({obj.shipped==0 ? 'NA' : DT_CellFormatterWithComma(obj.shipped_late*100/obj.shipped) + '%'})</td>
                            </tr>
                        )
                        })}
                        
                    </tbody>
                    <tfoot>
                        <tr>
                            {/* <th className="text-center"></th> */}
                            <th className="text-center">Total</th>
                            <th className="text-center">{totalOfOnTimeAccSku[0]}</th>
                            <th className="text-center">{totalOfOnTimeAccSku[1]}</th>
                            <th className="text-center">{totalOfOnTimeAccSku[2]}</th>
                            <th className="text-center">{totalOfOnTimeAccSku[3]}</th>
                            <th className="text-center">{totalOfOnTimeAccSku[4]}</th>
                        </tr>
                    </tfoot>
                </table>
                </div>

        </Tab>
        </Tabs>
        </div>
        {/* <div className="col-md-4">
            <ErrorBoundary>
                <OnTimeBarChart data={data3}
                    isLoading = {data3_isLoading} />
            </ErrorBoundary>
        </div> */}
    </div>  
  );

}