import React from "react";
import { useDispatch } from "react-redux";
import GlobalFilter from "../../../components/Filter/GlobalFilter";
import {
  setGlobalSearchValues,
  getOntimeBarChartData,
  setOntimeBarChartData,
  setInfullBarChartData,
  getInfullBarChartData,
  getOnTimeAccountabilityData,
  setOnTimeAccountabilityData,
  getInFullAccountabilityData,
  setInFullAccountabilityData,
  setOTIFChartData,
  getOTIFChartData,
  getOnTimeStatsData,
  setOnTimeStatsData,
  getInFullStatsData,
  setInFullStatsData,
} from "../../../redux/actions/otifActions";
import {
  todayDate,
  getLastnthWeeksDate,
} from "../../../utils/common-functions";

export default function OtifGlobalFilter() {
  const dispatch = useDispatch();
  const processFilterData = (values) => {
    values = { region: values.region, customer: values.customer, dc: values.dc, bu: values.bu, brand: values.brand, from_date: values.from_date, to_date: values.to_date };
    dispatch(setGlobalSearchValues(values));
    const filterForGraph = { category: "dc", limit: 10, orderby: "top" };
    const newfilterForGraph = { ...values, ...filterForGraph };
    const dateRangefilterForOTIF = {
      ...values,
      from_date: getLastnthWeeksDate(13),
      to_date: todayDate()
    };

    //13 Mar 2023 - Add tabValues towards new requirement in OTIF
    const tabValues = {...values, category: 'brand' }
    //Important uncomment If all APIS to be called
    dispatch(getOnTimeAccountabilityData(tabValues));
    dispatch(getOnTimeStatsData(dateRangefilterForOTIF));        
    dispatch(getOntimeBarChartData(newfilterForGraph));
    dispatch(getInFullAccountabilityData(tabValues));  
    dispatch(getInFullStatsData(dateRangefilterForOTIF));      
    dispatch(getInfullBarChartData(newfilterForGraph));
    dispatch(getOTIFChartData(dateRangefilterForOTIF));
  };

  const resetFilterData = () => {
    dispatch(setOnTimeAccountabilityData([]));
    dispatch(setInFullAccountabilityData([]));
    dispatch(setOnTimeStatsData([]));
    dispatch(setInFullStatsData([]));
    dispatch(setOTIFChartData([]));
    dispatch(setOntimeBarChartData([]));
    dispatch(setInfullBarChartData([]));
  };
  return (
    <GlobalFilter
      filterPage={"otif"}
      onFormSubmit={(formData) => processFilterData(formData)}
      onFormReset={() => resetFilterData()}
      fields={["region", "customer", "dc", "bu", "brand", "sku"]}
    />
  );
}
