import React, { } from 'react';
import { AreaLineChart as LineChart } from "../../../components/Charts/index";
import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { dateFormatter } from "../../../utils/common-functions";
import Draggable from "react-draggable";

const ForecastChart = (props) => {
    const { data, isLoading } = props;
    if (isLoading) {
        return (
            <DataLoader
                fullPage={false}
                message="Fetching data"
            />
        );
    }
    if (data?.length === 0) {
        return <NoDataFound message={"There is no data for selected filters!"} />;
    }

    const changeLabelDateFormat = [];
    if (data.x_label.length > 0) {
        for (var index = 0; index < data.x_label.length; index++) {
            const element = dateFormatter(data.x_label[index], 'MM/DD/YYYY');
            changeLabelDateFormat.push(element);
        }
    }
    
    return (
        <Draggable>
        <LineChart
            chartLabels={changeLabelDateFormat}
            chartDataSet={
                [
                    {
                        type: 'line',
                        label: 'Forecast',
                        data: data.y_forecast,
                        borderColor: ['rgba(19, 0, 255)'],
                        backgroundColor: ['rgba(19, 0, 255, 0.5)'],
                        borderWidth: 1,
                        fill: false,
                        yAxisID: 'y'
                    },
                    {
                        type: 'line',
                        label: 'Projected Order Units',
                        data: data.y_order_units,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        borderWidth: 1,
                        fill: false,
                        yAxisID: 'y'
                    },
                    {
                        type: 'line',
                        label: 'Actual Units',
                        data: data.y_actual_units,
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        borderWidth: 1,
                        fill: false,
                        yAxisID: 'y'
                    },
                    {
                        type: 'bar',
                        label: 'Total Inventory',
                        data: data.y_tot_inventory,
                        backgroundColor: [
                            'rgba(31, 124, 17, 0.56)',
                        ],
                        borderColor: [
                            'rgba(31, 124, 17, 0.56)'
                        ],
                        borderWidth: 1,
                        fill: false,
                        yAxisID: 'y'
                    },
                ]
            }
            showXAxis={true}
            graphTitle="Forecast"
            chartHeight={82}
            showSecondaryY={false}
        />
        </Draggable>
    );

}
export default ForecastChart;