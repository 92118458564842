/*
CustomerMultiList is a multi select dropdown used to list multiple Customers along with checkbox from which user can select multiple customers

This component can handle below props:
- onUpdate
- localRef
- error
- loadDefaultValues

Currently this component is imported in executive page:
/src/js/screens/ExecutivePage/Filters/ExecutiveGlobalFilter.js
- Later this executive page is merged to cockpit. 
- In phase2 this will be imported in cockpit also
*/

import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import ReactSelect from "./ReactSelect";
import { Option, MultiValue, CustomMenuList } from "./CommonFn";
import { WindowedMenuList } from "react-windowed-select";
import { fn_code_array, urlEncoder } from "../../utils/common-functions";
import {
    setDefaultCustomerMultiList,
    setDefaultSearchValues,
  } from "../../redux/actions/masterActions";

export default function CustomerMultiList({ onUpdate, localRef, error, loadDefaultValues }) {
    const customerList = useSelector(({ masterReducer }) => masterReducer.customerList);
    
    //jan 19
    const customerDefaultMultiList = useSelector(
        ({ masterReducer }) => masterReducer.customerDefaultMultiList
      );
      const defaultSearchValues = useSelector(
        ({ masterReducer }) => masterReducer.defaultSearchValues
      );

    const isDataLoading = useSelector(({ masterReducer }) => masterReducer.isLoadingRequestIds);
    const [SelectedVal, setSelectedVal] = useState([]);
    const [customizeVal, setCustomizeVal] = useState(0);
    const dispatch = useDispatch();
    const test = ["TARGET"]
    return(
        <Form.Group className="row">
            <div className="col-sm-12">
            <ReactSelect
                    options={customerList.filter(elements => { return elements.code != null })}
                    isMulti
                    isClearable                    
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ 
                        Option,
                        //19-Jan-2023 for alerts page 
                        //MultiValue, 
                        MenuList: WindowedMenuList,
                        DropdownIndicator: () => null, 
                        IndicatorSeparator: () => null }}
                    //components={{ Option, MenuList: CustomMenuList, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    
                    //19-Jan-2023 for alerts page
                    onMenuClose={(e) => { return e }}                    
                    
                    //19-Jan-2023 for alerts page
                    onChange={(val) => {
                        setSelectedVal(val);
                        onUpdate && onUpdate(val.length > 0 ? val : "");
                        dispatch(setDefaultCustomerMultiList(val));            
                        const setDefaultSearchVal = { customerMulti: fn_code_array(val) };
                        dispatch(setDefaultSearchValues(setDefaultSearchVal));
                      }}

                    // onChange={(val) => {
                    //     onUpdate && onUpdate((val.length > 0) ? val : '');
                    //     if(loadDefaultValues && customizeVal === 0 && customerList.length > 0){
                    //         setCustomizeVal(-1);
                    //         setSelectedVal(customerList);
                    //     }else{
                    //         setSelectedVal(val);
                    //     }
                    // }}
                    allowSelectAll={true}
                    
                    //value={SelectedVal}
                    //01 Feb default select in alerts Page
                    value = {SelectedVal.length > 0 ? SelectedVal : { code: 'TARGET' , name: 'TARGET' } }                    
                    isLoading={isDataLoading.includes('isCustomerDataLoaded') ? true : false}
                    placeholder="Choose a Customer..."
                    localRef={localRef}
                />
            <div className='error'>{error}</div>
            </div>
        </Form.Group>
    );
}