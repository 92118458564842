import { commonTypes } from "../types/commonTypes";
import { otifTypes } from "../types/otifTypes";
import { removeItemOnce } from '../../utils/common-functions';

export default function (state = {}, action) {

    switch (action.type) {
        case commonTypes.SET_LOADING:
            var key = Object.keys(action.payload)[0];
            var value = action.payload[Object.keys(action.payload)[0]];
            return {
                ...state,
                isLoadingRequestIds: value ? [
                    ...state.isLoadingRequestIds,
                    key
                ] : removeItemOnce(state.isLoadingRequestIds, key)
            };
        case otifTypes.SET_ON_TIME_ACCOUNTABILITY:
            return {
                ...state,
                ontimeaccountability: action.payload
            }
        case otifTypes.SET_IN_FULL_ACCOUNTABILITY:
            return {
                ...state,
                infullaccountability: action.payload
            }
        case otifTypes.SET_OTIF_CHART_DATA:
            return {
                ...state,
                OTIFChartData: action.payload
            }
        case otifTypes.SET_OTIF_ONTIME_BAR_CHART:
            return {
                ...state,
                ontimeBarChartData: action.payload
            }
        case otifTypes.SET_OTIF_INFULL_BAR_CHART:
            return {
                ...state,
                infullBarChartData: action.payload
            }
        case otifTypes.SET_GLOBAL_SEARCH_VALUES:
            return {
                ...state,
                otifGlobalSearchValues: action.payload,
            }
        case otifTypes.SET_DATERANGE_SEARCH_VALUES:
            return {
                ...state,
                otifDateRangeSearchValues: action.payload,
            }
        case otifTypes.SET_ON_TIME_STATS:
            return {
                ...state,
                onTimeStats: action.payload,
            }
        case otifTypes.SET_IN_FULL_STATS:
            return {
                ...state,
                inFullStats: action.payload,
            }
        default:
            return state;
    }
}