/*
MultitpeChart is used to display multiple type of charts Area, Bar charts

This component can handle below props:
- chartHeading
- chartDataSet
- chartLabels

Currently this component got imported in below pages:
/src/js/screens/Instock/Components/SalesByDc.js
/src/js/screens/Instock/Components/SalesBySku.js
/src/js/screens/Instock/Components/SalesByStore.js
*/

import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

export default function MultitypeChart ( props ) { 
    const { chartDataSet, chartLabels } = props;
    const chartData = {
        labels: chartLabels,
        datasets: chartDataSet,
    };

    return <Chart type='bar' data={chartData} height="82" />;
}

