import React, { useState } from "react";
import NewWindow from "react-new-window";

export default function ShowPopup(props) {
  var str1, str2
  if (props.name == "Trends") {
    str1 = "Close " + props.name
    str2 = "Zoom " + props.name
  } else {
    str1 = "Close " + props.name + " Latest Week"
    str2 = "View " + props.name + " Latest Week"
  }


  const [opened, setOpened] = useState(false);

  const toggleOpened = (prevState) => {
    setOpened(!prevState);
    props.onlinkclick(!prevState);
  };
  const newWindowUnloaded = () => {
    setOpened(false);
    props.onlinkclick(false);
  };
  return (
    <div>
      <button
        onClick={() => toggleOpened(opened)}
        className="btn btn-link btn-fw d-flex align-items-center"
      >
        {/* {opened ? "Close the opened window" : "Open a new window"} */}
        {opened ? str1 : str2}
      </button>
      {opened ? (
        <NewWindow
          onUnload={() => newWindowUnloaded()}
          center={false}
          features={{
            width: 1000,
            height: 500,
            left: 0,
            top: 0,
          }}
        >
          {props.children}
        </NewWindow>
      ) : (
        //(props.name=="Trends")?props.children:<div></div>
        props.children
      )}
    </div>
  );
}
