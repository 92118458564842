import React, { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Formik } from "formik";

import { useDispatch, useSelector } from "react-redux";

// import master components for dropdown
import RegionList from "../DropdownList/RegionList";
import CustomerList from "../DropdownList/CustomerList";
import DcList from "../DropdownList/DcList";
import BuList from "../DropdownList/BuList";
import BuMultiList from "../DropdownList/BuMultiList";
import BrandList from "../DropdownList/BrandList";
import SkuList from "../DropdownList/SkuList";
// import SegmentList from "../DropdownList/SegmentList";
import CustomerMultiList from "../DropdownList/CustomerMultiList";
import RegionMultiList from "../DropdownList/RegionMultiList";


import {
  fn_code_array,
  todayDate,
  getLastnthWeeksDate,
  getLastnthWeeksDatePicker,
  todayDatePicker,
  cleanArrObject,
} from "../../utils/common-functions";

function GlobalFilter({ onFormSubmit, onFormReset, fields, filterPage }) {
  const dispatch = useDispatch();
  const regTypeheadRef = useRef();
  const custTypeheadRef = useRef();
  const regMultiTypeheadRef = useRef();
  const custMultiTypeheadRef = useRef();
  const buTypeheadRef = useRef();
  const brandTypeheadRef = useRef();
  const dcTypeheadRef = useRef();
  const skuTypeheadRef = useRef();
  const segmentTypeheadRef = useRef();
  const defaultSearchVal = useSelector(
    ({ masterReducer }) => masterReducer.defaultSearchValues
  );
  
  // const [fromDate, setfromDate] = useState(new Date(getLastnthWeeksDate(13)));
  // const [toDate, settoDate] = useState(new Date(todayDate()));

  //27 march 2023 Datepicker not suuporting moment js. Changes are added to test the behaviour of date in datepicker
  const [fromDate, setfromDate] = useState(getLastnthWeeksDatePicker(13));
  const [toDate, settoDate] = useState(todayDatePicker());

  const resetFilters = () => {
    setfromDate("");
    settoDate("");
    //Feb 2 2023 towards Alerts page
    if (fields.includes("bu")) {
      buTypeheadRef.current.select.select.clearValue();
    }
    if (fields.includes("brand")) {
      brandTypeheadRef.current.select.select.clearValue();
    }
    if (fields.includes("region")) {
      regTypeheadRef.current.clear();
    }
    if (fields.includes("customer")) {
      custTypeheadRef.current.clear();
    }
    if (fields.includes("dc")) {
      dcTypeheadRef.current.select.select.clearValue();
    }
    if (fields.includes("sku")) {
      skuTypeheadRef.current.select.select.clearValue();
    }
    if (fields.includes("customerMultiList")) {
      custMultiTypeheadRef.current.select.select.clearValue();
      //values.customerMulti = ["TARGET"]      
    }
    if (fields.includes("regionMultiList")) {
      regMultiTypeheadRef.current.select.select.clearValue();
      //values.regionMulti = ["US"];
    }
    if (fields.includes("segment")) {
      segmentTypeheadRef.current.select.select.clearValue();
    }
  };
  return (
    <Formik
      initialValues={defaultSearchVal}
      validate={(values) => {
        const errors = {};
        if (!values.region && fields.includes("region")) {
          errors.region_err = "This field is required!";
        }
        if (!values.customer && fields.includes("customer")) {
          errors.customer_err = "This field is required!";
        }
        if (!values.bu && fields.includes("bu")) {
          errors.bu_err = "This field is required!";
        }
        if (!values.brand && fields.includes("brand")) {
          errors.brand_err = "This field is required!";
        }
        if (!values.dc && fields.includes("dc")) {
          errors.dc_err = "This field is required!";
        }
        // if (!values.sku && fields.includes("sku")) {
        //   errors.sku_err = "This field is required!";
        // }

        //01 Feb 2022 default options in alerts page
        if (!values?.regionMulti && fields.includes("regionMultiList")) {
          //errors.region_multi_list_err = "This field is required!";
          values.regionMulti = ["US"];
        }
        if (!values?.customerMulti && fields.includes("customerMultiList")) {
          //errors.customer_multi_list_err = "This field is required!";
          values.customerMulti = ["TARGET"]
        }
        if (!values.segment && fields.includes("segment")) {
          errors.segment_err = "This field is required!";
        }
        return errors;
      }}
      onSubmit={(values) => {
        if (values.from_date === "" && values.to_date === "") {
           values.from_date = getLastnthWeeksDate(13);
           values.to_date = todayDate();
        }
        onFormSubmit(cleanArrObject(values));
      }}
      onReset={(values) => { }}
    >
      {({ errors, setFieldValue, handleSubmit, setErrors }) => (
        <form className="form-sample">
          <div className="row">
            {fields.includes("region") ? (
              <div className="col-md-2">
                <RegionList
                  onUpdate={(regionCode) => {
                    setFieldValue(
                      "region",
                      regionCode.length > 0 ? regionCode[0].code : ""
                    );
                  }}
                  localRef={regTypeheadRef}
                  error={errors.region_err}
                />
              </div>
            ) : null}
            {fields.includes("regionMultiList") ? (
              <div className="col-md-2">
                <RegionMultiList                  
                  onUpdate={(regionCode) => {
                    setFieldValue(
                      "regionMulti",
                      regionCode.length > 0 ? fn_code_array(regionCode)
                      : regionCode
                    );
                  }}
                  localRef={regMultiTypeheadRef}
                  error={errors.region_multi_list_err}
                />
              </div>
            ) : null}
            {fields.includes("customer") ? (
              <div className="col-md-2">
                <CustomerList
                  onUpdate={(customerCode) => {
                    setFieldValue(
                      "customer",
                      customerCode.length > 0
                        ? customerCode[0].code
                        : customerCode
                    );
                  }}
                  localRef={custTypeheadRef}
                  error={errors.customer_err}
                />
              </div>
            ) : null}
            {fields.includes("customerMultiList") ? (
              <div className="col-md-2">
                <CustomerMultiList
                  onUpdate={(customerCode) => {
                    setFieldValue(
                      "customerMulti",
                      customerCode.length > 0
                        ? fn_code_array(customerCode)
                        : customerCode
                    );
                  }}
                  localRef={custMultiTypeheadRef}
                  error={errors.customer_multi_list_err}
                />
              </div>
            ) : null}
            {fields.includes("dc") ? (
              <div className="col-md-3">
                <DcList
                  onUpdate={(dcCode) => {
                    setFieldValue(
                      "dc",
                      dcCode.length > 0 ? fn_code_array(dcCode) : dcCode
                    );
                  }}
                  localRef={dcTypeheadRef}
                  error={errors.dc_err}
                />
              </div>
            ) : null}
            {fields.includes("bu") ? (
              <div className="col-md-2">
                <BuMultiList
                  onUpdate={(buCode) => {
                    brandTypeheadRef.current.select.select.clearValue();
                    setFieldValue(
                      "bu",
                      buCode.length > 0 ? fn_code_array(buCode) : buCode
                    );
                  }}
                  localRef={buTypeheadRef}
                  error={errors.bu_err}
                />
              </div>
            ) : null}
            {fields.includes("brand") ? (
              <div className="col-md-3">
                <BrandList
                  onUpdate={(brandCode) => {
                    setFieldValue(
                      "brand",
                      brandCode.length > 0
                        ? fn_code_array(brandCode)
                        : brandCode
                    );
                  }}
                  localRef={brandTypeheadRef}
                  error={errors.brand_err}
                />
              </div>
            ) : null}
            {fields.includes("segment") ? (
              <div className="col-md-3">
                <BrandList
                  onUpdate={(segmentCode) => {
                    setFieldValue(
                      "segment",
                      segmentCode.length > 0
                        ? fn_code_array(segmentCode)
                        : segmentCode
                    );
                  }}
                  localRef={segmentTypeheadRef}
                  error={errors.segment_err}
                />
              </div>
            ) : null}
            {fields.includes("sku") ? (
              <div className="col-md-3">
                <SkuList
                  onUpdate={(skuCode) => {
                    setFieldValue(
                      "sku",
                      skuCode.length > 0 ? fn_code_array(skuCode) : skuCode
                    );
                  }}
                  localRef={skuTypeheadRef}
                //error={errors.sku_err}
                />
              </div>
            ) : null}
            {fields.includes("dateRangeFilter") ? (
              <div className="col-md-2">
                <Form.Group className="row">
                  <div className="col-sm-12">
                    <label className="sr-only" htmlFor="inlineFormInputName2">
                      From Date
                    </label>
                    <DatePicker
                      className="form-control w-100"
                      placeholderText="From Date"
                      selected={fromDate}
                      onChange={(val) => {
                        setfromDate(val);
                        setFieldValue(
                          "from_date",
                          val
                            .toLocaleDateString("en-GB") 
                            .replaceAll("/", "-")
                            .split("-")
                            .reverse()
                            .join("-")
                        );
                      }}
                    />
                    {/* <div className='error'>{errors.from_date_error}</div> */}
                  </div>
                </Form.Group>
              </div>
            ) : null}
            {fields.includes("dateRangeFilter") ? (
              <div className="col-md-2">
                <Form.Group className="row">
                  <div className="col-sm-12">
                    <label className="sr-only" htmlFor="inlineFormInputName2">
                      To Date
                    </label>
                    <DatePicker
                      className="form-control w-100"
                      format={"MM/DD/YYYY"}
                      placeholderText="To Date"
                      selected={toDate}
                      onChange={(val) => {
                        settoDate(val);
                        setFieldValue(
                          "to_date",
                          val
                            .toLocaleDateString("en-GB")
                            .replaceAll("/", "-")
                            .split("-")
                            .reverse()
                            .join("-")
                        );
                      }}
                    />
                  </div>
                </Form.Group>
              </div>
            ) : null}
            <div className="col justify-content-between align-items-center">
              <button
                type="button"
                className="btn btn-gradient-light btn-sm"
                onClick={() => {
                  setErrors({});
                  resetFilters();
                  onFormReset();
                }}
              >
                {" "}
                Reset{" "}
              </button>
              <button
                type="button"
                className="btn btn-gradient-danger btn-sm"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {" "}
                Filter{" "}
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default GlobalFilter;
