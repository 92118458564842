import { cleanArrObject } from "../../../utils/common-functions";

export const getFilterValuesForExport = (props) => {
    var executiveGlobalSearchValues = props.executiveGlobalSearchValues;
    var executiveDateRangeSearchValues = props.executiveDateRangeSearchValues;
    //const newArr = { limit: 0 };
    const allFilterObj = {
        ...cleanArrObject(executiveGlobalSearchValues),
        //...cleanArrObject(executiveDateRangeSearchValues),
        //27 Jan 2023 Ensure Same payload for API and Export API to avoid dremio queries getting called
        //...newArr,
    };
    return allFilterObj;
};