import React, { Component, Suspense } from "react";
import { Tabs, Tab } from "react-bootstrap";

//Import custom components
import DataLoader from "../../components/Loader/DataLoader";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";

import InStockGlobalFilters from "./Filters/InStockGlobalFilters";
import InventoryFilters from "./Filters/InventoryFilters";
import DateRangeFilter from "./Filters/DateRangeFilter";
import BatchFilter from "./Filters/BatchFilter";

//import export data
import {
  ExportSkuCSV,
  ExportInventoryCSV,
  ExportSegmentCSV,
} from "./ExportData";

// Import redux
import { connect } from "react-redux";
import {
  getInventoryData,
  getSkuLevelData,
  getSalesBySkuData,
  getSalesByDcData,
  getSalesByStoreData,
} from "../../redux/actions/instockActions";

//import child components
import SalesByDc from "./Components/SalesByDc";
import SalesByStore from "./Components/SalesByStore";
import SalesBySku from "./Components/SalesBySku";
import Inventory from "./Components/Inventory";
import SkuLevelAggregate from "./Components/SkuLevelAggregate";
import SegmentLevelAggregate from "./Components/SegmentLevelAggregate";
import SkuLevelTrend from "./Components/SkuLevelTrend";
import StockStatistics from "./Components/StockStatistics";

import "./InStock.scss";

class InStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInventoryDataState: [],
      filteredSkuDataState: [],
      filteredSegmentDataState: [],
      isInventoryFilterApplied: false,
    };

    this.handleInvetoryDD = this.handleInvetoryDD.bind(this);
  }

  componentDidMount() {}

  handleInvetoryDD = (selectedItem) => {
    if (selectedItem === "All") {
      this.setState({
        isInventoryFilterApplied: false,
        filteredInventoryDataState: [],
        filteredSkuDataState: [],
        filteredSegmentDataState: [],
      });
    } else {
      const filteredInventoryData = [];
      const filteredSkuData = [];
      const filteredSegmentData = [];
      
      //In UI Instockpage Table SKU, DC, Store Aggregated Also referred as inventory table
      //(refer inventoryData variable in code) -compared to risk
      //Related to file inventory.js and ExportInventoryCSV.js   
      this.props.inStockDataObj.inventoryData.map((obj, index) => {
        if (obj.risk === selectedItem) {
          //let skuObj = this.props.inStockDataObj.skuLevelData.find(o => o.sku === obj.sku);
          filteredInventoryData.push(obj);
          //filteredSkuData.push(skuObj);
        }
      });

      //In UI Instockpage SKU Level Aggregate Data Table - Also referred as SKU Level table
      //(filteredSkuData) compared to future_risk
      //Related to file SkuLevelAggregate.js and ExportSkuCSV.js
      this.props.inStockDataObj.skuLevelData.map((obj, index) => {
        if (obj.future_risk === selectedItem) {
          filteredSkuData.push(obj);
        }
      });

      //In UI Instockpage Segment Level Aggregate Data Table 
      //(filteredSegmentData variable) compared to future_risk
      //Related to SegmentLevelAggregate.js and ExportSegmentCSV.js
      this.props.inStockDataObj.segmentLevelData.map((obj, index) => {
        if (obj.future_risk === selectedItem) {
          filteredSegmentData.push(obj);
        }
      });

      this.setState({
        isInventoryFilterApplied: true,
        filteredInventoryDataState: filteredInventoryData,
        filteredSkuDataState: filteredSkuData,
        filteredSegmentDataState: filteredSegmentData,
      });
    }
  };

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <InStockGlobalFilters />
          {/* <div className="card">
            <div className="card-body">
              <InStockGlobalFilters />
            </div>
          </div> */}
        </div>
        {/* <div className="col-md-12 stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="card-title">KPI's</h4>
                <InventoryFilters handleInvetoryDD={(val) => this.handleInvetoryDD(val)} />
              </div>
              
            </div>
          </div>
        </div> */}
        <div className="col-lg-12 stretch-card">
          <div className="card">
            <div className="card-body">
              <InventoryFilters
                handleInvetoryDD={(val) => this.handleInvetoryDD(val)}
              />
              <ErrorBoundary>
                <StockStatistics
                  data={this.props.inStockDataObj.stockStatistics}
                  isLoading={this.props.inStockDataObj.isLoadingRequestIds.includes(
                    "isStockStatisticsLoaded"
                  )}
                />
              </ErrorBoundary>
            </div>
          </div>
        </div>
        <div className="col-lg-12 stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h4 className="card-title">SKU Level Aggregate Data </h4>
                <ExportSkuCSV />
              </div>
              <ErrorBoundary>
                <Suspense fallback={<DataLoader />}>
                  <SkuLevelAggregate
                    data={
                      this.state.isInventoryFilterApplied
                        ? this.state.filteredSkuDataState
                        : this.props.inStockDataObj.skuLevelData
                    }
                    isLoading={this.props.inStockDataObj.isLoadingRequestIds.includes(
                      "isSkuLevelDataLoaded"
                    )}
                  />
                </Suspense>
              </ErrorBoundary>
            </div>
          </div>
        </div>
        <div className="col-lg-12 stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h4 className="card-title">SKU, DC, Store aggregated </h4>
                <div className="d-flex justify-content-between">                       
                  <div className='row col-rows-10'>                      
                        <div className="col d-flex mb-4 justify-content-between align-items-center">
                            {/* <BatchFilter/> */}
                            {/* <button type="button" className="btn btn-outline-secondary btn-sm"
                                //onClick={() => { handleReset(); myCustomhandleResetFn(); }}
                            >Prev</button>
                            <button type="button" className="btn btn-gradient-success btn-sm"
                                // onClick={() => { handleSubmit();}}
                            >Next</button> */}
                        </div>
                  </div>
                  <ExportInventoryCSV />
                </div>
              </div>
              <ErrorBoundary>
                <Suspense fallback={<DataLoader />}>
                  <Inventory
                    data={
                      this.state.isInventoryFilterApplied
                        ? this.state.filteredInventoryDataState
                        : this.props.inStockDataObj.inventoryData
                    }
                    isLoading={this.props.inStockDataObj.isLoadingRequestIds.includes(
                      "isInventoryDataLoaded"
                    )}
                  />
                </Suspense>
              </ErrorBoundary>
            </div>
          </div>
        </div>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h4 className="card-title">Segment Level Aggregate Data </h4>
                <ExportSegmentCSV />
              </div>
              <ErrorBoundary>
                <Suspense fallback={<DataLoader />}>
                  <SegmentLevelAggregate
                    data={
                      this.state.isInventoryFilterApplied
                        ? this.state.filteredSegmentDataState
                        : this.props.inStockDataObj.segmentLevelData
                    }
                    isLoading={this.props.inStockDataObj.isLoadingRequestIds.includes(
                      "isSegmentLevelDataLoaded"
                    )}
                  />
                </Suspense>
              </ErrorBoundary>
            </div>
          </div>
        </div>

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="card-title">SKU Level Trends</h4>
                  <div className="padding-top-custom">
                    <ErrorBoundary>
                      <SkuLevelTrend
                        data={this.props.inStockDataObj.skuLevelTrends}
                        isLoading={this.props.inStockDataObj.isLoadingRequestIds.includes(
                          "isSkuLevelTrendLoaded"
                        )}
                      />
                    </ErrorBoundary>
                  </div>
                </div>
                <div className="col-md-6">
                  <DateRangeFilter />
                  <Tabs
                    defaultActiveKey="salesBySku"
                    transition={false}
                    id="left-tabs-example"
                    className="mb-0"
                  >
                    <Tab eventKey="salesBySku" title="Sales By Sku">
                      <ErrorBoundary>
                        <Suspense fallback={<DataLoader />}>
                          <SalesBySku
                            data={this.props.inStockDataObj.salesBySkuData}
                            isLoading={this.props.inStockDataObj.isLoadingRequestIds.includes(
                              "isSalesBySkuDataLoaded"
                            )}
                          />
                        </Suspense>
                      </ErrorBoundary>
                    </Tab>
                    <Tab eventKey="salesByDc" title="Sales By Dc">
                      <ErrorBoundary>
                        <Suspense fallback={<DataLoader />}>
                          <SalesByDc
                            data={this.props.inStockDataObj.salesByDcData}
                            isLoading={this.props.inStockDataObj.isLoadingRequestIds.includes(
                              "isSalesByDcDataLoaded"
                            )}
                          />
                        </Suspense>
                      </ErrorBoundary>
                    </Tab>
                    <Tab eventKey="salesByStore" title="Sales By Stores">
                      <ErrorBoundary>
                        <Suspense fallback={<DataLoader />}>
                          <SalesByStore
                            data={this.props.inStockDataObj.salesByStoreData}
                            isLoading={this.props.inStockDataObj.isLoadingRequestIds.includes(
                              "isSalesByStoreDataLoaded"
                            )}
                          />
                        </Suspense>
                      </ErrorBoundary>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    inStockDataObj: state.instockReducer,
  };
}
const mapDispatchToProps = {
  getInventoryData,
  getSkuLevelData,
  getSalesBySkuData,
  getSalesByDcData,
  getSalesByStoreData,
};
export default connect(mapStateToProps, mapDispatchToProps)(InStock);
