import React from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { DT_CellFormatterWithComma, DT_CellFormatterWithDollar, } from "../../../utils/common-functions";

const tbl_headers = [
  {
    dataField: "region",
    text: "Region",
  },
  {
    dataField: "customer",
    text: "Customer",
  },
  {
    dataField: "segment",
    text: "Segment",
  },
  {
    dataField: "brand_name",
    text: "Brand Name",
  },
  {
    dataField: "bu",
    text: "BU",
  },
  {
    dataField: "total_stores",
    text: "Total Stores",
    formatter: DT_CellFormatterWithComma,
  },
  {
    dataField: "oos_stores",
    text: "OOS Stores",
  },
  {
    dataField: "percentage_oos",
    text: "% OOS",
  },
  {
    dataField: "pos_per_store_4w_avg",
    text: "POS per store(4W Avg)",
  },
  {
    dataField: "fill_rate",
    text: "Fill Rate %",
  },
  {
    dataField: "on_time_rate",
    text: "On Time Rate %",
  },
  {
    dataField: "in_transit_inventory",
    text: "In Transit Inventory",
    formatter: DT_CellFormatterWithComma,
  },
  {
    dataField: "dc_inventory",
    text: "DC Inventory",
    formatter: DT_CellFormatterWithComma,
  },
  {
    dataField: "store_inventory",
    text: "Store Inventory",
    formatter: DT_CellFormatterWithComma,
  },
  {
    dataField: "total_inventory",
    text: "Total Inventory",
    formatter: DT_CellFormatterWithComma,
  },
  {
    dataField: "in_transit_inventory_perc",
    text: "In Transit Inventory %",
  },
  {
    dataField: "dc_inventory_perc",
    text: "DC Inventory %",
  },
  {
    dataField: "store_inventory_perc",
    text: "Store Inventory %",
  },
  {
    dataField: "dc_week_of_supply",
    text: "DC WOC (4w avg)",
  },
  {
    dataField: "store_week_of_supply",
    text: "Store WOC (4w avg)",
  },
  {
    dataField: "sku_woc_historical",
    text: "Total WOC (4w avg)",
  },
  {
    dataField: "pos_lw",
    text: "POS(LW)",
    formatter: DT_CellFormatterWithComma,
  },
  {
    dataField: "avg_pos_historical_4w",
    text: "Average POS Historical(4W)",
    formatter: DT_CellFormatterWithComma,
  },
  {
    dataField: "sales_variance_4w",
    text: "Sales Variance (Avg 4W vs. LW %)",
    formatter: DT_CellFormatterWithComma,
  },  
  {
    dataField: "avg_pos_forecast_4w",
    text: "Average POS Forecast ( 4 weeks)",
    formatter: DT_CellFormatterWithComma,
  },
  {
    dataField: "sku_woc_forecast",
    text: "SKU WOC Forecast",
  },
  //avg_pos_forecast_8w , avg_pos_historical_13w removed due to optimization of queries
  //projected_order_qty_8w changed as sum_order_forecast_4w  
  {
    dataField: "sum_order_forecast_4w",
    text: "Sum Order Forecast 4 weeks",
    formatter: DT_CellFormatterWithComma,
  },
  {
    dataField: "future_inventory",
    text: "Future Inventory",
    formatter: DT_CellFormatterWithComma,
  },
  {
    dataField: "future_woc",
    text: "Future WOC",
  },
  {
    dataField: "future_risk",
    text: "Future Risk",
  },
  {
    dataField: "lost_sales",
    text: "Lost Sales",
  },
  {
    dataField: "pos_value",
    text: "POS Value $",
    formatter: DT_CellFormatterWithDollar,
  },
  // Coversion factor column not included at the end as there is no RbSkuCode in Json
];
const pagination_options = {
  // pageStartIndex: 0,
  sizePerPage: 5,
  hideSizePerPage: true,
  hidePageListOnlyOnePage: true,
};
const SegmentLevelAggregate = (props) => {
  const { data, isLoading } = props;
  if (isLoading) {
    return <DataLoader fullPage={false} message="Fetching data" />;
  }
  if (data?.length === 0) {
    return <NoDataFound message={"There is no data for selected filters!"} />;
  }
  return (
    <ToolkitProvider keyField="sku" data={data} columns={tbl_headers}>
      {(props) => (
        <div>
          <BootstrapTable
            striped
            hover
            condensed
            classes="table-responsive"
            {...props.baseProps}
            pagination={paginationFactory(pagination_options)}
            noDataIndication="There is nos data for selected filters!"
          />
        </div>
      )}
    </ToolkitProvider>
  );
};
export default SegmentLevelAggregate;
