import { commonTypes } from "../types/commonTypes";
import { executivePageTypes } from "../types/executivePageTypes";
import { GET, POST } from "../../utils/axios";
import { getSocketStatus, recursiveExecutiveKpisData } from './socketActions';
import store from "../storeFactory";

// Import data & utils
import custKpiData from "../../../assets/data/executive/executiveCustomerKpi.json";
import buKpiData from "../../../assets/data/executive/executiveBuKpi.json";
import brandKpiData from "../../../assets/data/executive/executiveBrandKpi.json";

import pivotTableData from "../../../assets/data/executive/pivotTable.json";

export const setLoad = (isLoadingRequestIds) => {
  return { type: commonTypes.SET_LOADING, payload: isLoadingRequestIds };
};

// Executive Customer KPI
export const getCustomerKpiData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isCustomerKpiDataLoaded: true }));
    const custKpiData = await getCustomerKpiDataReq(postData);
    dispatch(setCustomerKpiData(custKpiData));
    dispatch(setLoad({ isCustomerKpiDataLoaded: false }));
  };
};
const getCustomerKpiDataReq = async (postData) => {
  try {
    const apiRes = await POST(`/executiveCustomer`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getCustomerKpiDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("ExecutiveCustomer ERROR: ", err);
    return [];
  }
};
export const setCustomerKpiData = (customerKpiData) => {
  return {
    type: executivePageTypes.SET_CUSTOMER_KPI,
    payload: customerKpiData,
  };
};

export const getCustomerKpiDataForExport = async (postData) => {
  const custKpiData = await getCustomerKpiDataReq(postData);
  return custKpiData;
};

// Executive Brand KPI
export const getBrandKpiData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isBrandKpiDataLoaded: true }));
    const brandKpiData = await getBrandKpiDataReq(postData);
    dispatch(setBrandKpiData(brandKpiData));
    dispatch(setLoad({ isBrandKpiDataLoaded: false }));
  };
};
const getBrandKpiDataReq = async (postData) => {
  try {
    const apiRes = await POST(`/executiveBrand`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getBrandKpiDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("ExecutiveBrand ERROR: ", err);
    return [];
  }
};
export const setBrandKpiData = (brandKpiData) => {
  return { type: executivePageTypes.SET_BRAND_KPI, payload: brandKpiData };
};

export const getBrandKpiDataForExport = async (postData) => {
  const brandKpiData = await getBrandKpiDataReq(postData);
  return brandKpiData;
};

// Executive BU KPI
export const getBuKpiData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isBuKpiDataLoaded: true }));
    const buKpiData = await getBuKpiDataReq(postData);
    dispatch(setBuKpiData(buKpiData));
    dispatch(setLoad({ isBuKpiDataLoaded: false }));
  };
};
const getBuKpiDataReq = async (postData) => {
  try {
    const apiRes = await POST(`/executiveBu`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      console.log("completed");
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getBuKpiDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("ExecutiveBu ERROR: ", err);
    return [];
  }
};
export const setBuKpiData = (buKpiData) => {
  return { type: executivePageTypes.SET_BU_KPI, payload: buKpiData };
};

export const getBuKpiDataForExport = async (postData) => {
  const buKpiData = await getBuKpiDataReq(postData);
  return buKpiData;
};

// Executive SEGMENT KPI
export const getSegmentKpiData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isSegmentKpiDataLoaded: true }));
    const apiResData = await getSegmentKpiDataReq(postData);
    dispatch(setSegmentKpiData(apiResData));
    dispatch(setLoad({ isSegmentKpiDataLoaded: false }));
  };
};
const getSegmentKpiDataReq = async (postData) => {
  try {
    const apiRes = await POST(`/executiveSegment`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getSegmentKpiDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("ExecutiveSegment ERROR: ", err);
    return [];
  }
};
export const setSegmentKpiData = (segmentKpiData) => {
  return { type: executivePageTypes.SET_SEGMENT_KPI, payload: segmentKpiData };
};

export const getSegmentKpiDataForExport = async (postData) => {
  const data = await getSegmentKpiDataReq(postData);
  return data;
};

export const setDateRangeSearchValues = (executiveDateRangeSearchValues) => {
  return {
    type: executivePageTypes.SET_DATERANGE_SEARCH_VALUES,
    payload: executiveDateRangeSearchValues,
  };
};

export const setGlobalSearchValues = (searchValues) => {
  return {
    type: executivePageTypes.SET_GLOBAL_SEARCH_VALUES,
    payload: searchValues,
  };
};

// GET Executive Pivot data combining responses from all other executive API response
// Executive Pivot KPI
export const getExecutivePivotData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isExecutivePivotDataLoaded: true }));
    const apiData = await getExecutivePivotDataReq(postData);
    dispatch(setExecutivePivotData(apiData));
    dispatch(setLoad({ isExecutivePivotDataLoaded: false }));
  };
};


const getExecutivePivotDataReq = async (postData) => {
  try {

    const apiRes = await recursiveExecutiveKpisData();

    let convertArrForPivotTable = [];
    let definedFields = {
      "ontime_perc_from_ontime_data": "OT %",
      "fill_rate_original": "IF %",
      "otif": "OTIF %",
      "total_pos_amount": "POS $",
      "total_pos_qty": "POS Eaches",
      "total_pos_qty_cases": "POS Cases",
      "total_pos_qty_pallets": "POS Pallets",
      "oos_perc": "OOS %",
      "active_stores": "Active Stores",
      "woc": "WOC"
    };

    apiRes.map((val, index) => {
      for (var i = 0; i < 10; i++) {
        convertArrForPivotTable.push({
          Customer: (val.customer_name !== undefined) ? val.customer_name : "",
          Bu: (val.bu_name !== undefined) ? val.bu_name : "",
          Brand: (val.brand_name !== undefined) ? val.brand_name : "",
          Segment: (val.segment !== undefined) ? val.segment : "",
          KPIs: Object.values(definedFields)[i],
          colVal: val[Object.keys(definedFields)[i]],
        });
      }
    })
    //console.log("convertArrForPivotTable is ", convertArrForPivotTable)
    return convertArrForPivotTable;
  } catch (err) {
    console.log("ExecutivePivot ERROR2: ", err);
    return [];
  }
};

// Pivot table API api/executivePivot exhausts memory in dremio
// Use this code in future if Pivot table API is available in future, if things are sorted out
// const getExecutivePivotDataReq = async (postData) => {
//   try {
//     const apiRes = await POST(`/executivePivot`, postData);
//     if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {

//       let convertArrForPivotTable = [];
//       let definedFields = {
//         "ontime_perc_from_ontime_data": "OT %",
//         "fill_rate_original": "IF %",
//         "otif": "OTIF %",
//         "total_pos_amount": "POS $",
//         "total_pos_qty": "POS Eaches",
//         "total_pos_qty_cases": "POS Cases",
//         "total_pos_qty_pallets": "POS Pallets",
//         "oos_perc": "OOS %",
//         "active_stores": "Active Stores",
//         "woc": "WOC"
//       };

//       apiRes.data?.result.map((val, index) => {
//         // if (val.customer_name !== "" && val.bu_name !== "" && val.brand_name !== "" && val.segment === "") {
//         //   console.log("god######" + index);
//         //   return;
//         // }
//         // console.log("god######" + index);

//         for (var i = 0; i < 10; i++) {
//           convertArrForPivotTable.push({
//             Customer: val.customer_name,
//             Bu: val.bu_name,
//             Brand: val.brand_name,
//             Segment: val.segment,
//             KPIs: Object.values(definedFields)[i],
//             colVal: val[Object.keys(definedFields)[i]],
//           });
//         }
//       })

//       return convertArrForPivotTable;
//     } else {
//       let socketRetData = await getSocketStatus(apiRes.key);
//       if (socketRetData.jobState === "COMPLETED") {
//         return await getExecutivePivotDataReq(postData);
//       } else if (socketRetData.jobState === "FAILED") {
//         return socketRetData.errorMessage;
//       }
//     }
//     return [];
//   } catch (err) {
//     console.log("ExecutivePivot ERROR: ", err);
//     return [];
//   }
// };

const filterPivotData = (data) => {

}
export const setExecutivePivotData = (data) => {
  return {
    type: executivePageTypes.SET_EXECUTIVE_PIVOT_DATA,
    payload: data,
  };
};