import React, { } from 'react';

import { KPIComponent } from "../../../components/KPIComponent/index";
import { KPIBox } from "../../../components/KPIComponent/index";

import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import {numberWithCommas, DT_CellFormatterWithPerc} from '../../../utils/common-functions';

const KPI = (props) => {
  let { data, isLoading } = props;
  if (isLoading) {
    return (
      <DataLoader
        fullPage={false}
        message="Fetching data"
      />
    );
  }
  if (data?.length === 0) {
    data = [
      {
        "kpiTitle": "In Stock",
        "actualValue": "0",
        "variance": "0"
      },
      {
        "kpiTitle": "Out of Stock",
        "actualValue": "0",
        "variance": "0"
      },
      {
        "kpiTitle": "Total",
        "actualValue": "0",
        "variance": "0"
      },
      {
        "kpiTitle": "WOC",
        "actualValue": "0",
        "variance": "0"
      },
      {
        "kpiTitle": "Orders",
        "actualValue": "0",
        "variance": "0"
      },
      
      {
        "kpiTitle": "POS (Cons.)",
        "actualValue": "0",
        "variance": "0"
      },
      // {
      //   "kpiTitle": "POS Forecast vs Actual",
      //   "actualValue": "0",
      //   "variance": "0"
      // },
      // {
      //   "kpiTitle": "Order Forecast vs Actual",
      //   "actualValue": "0",
      //   "variance": "0"
      // },
      {
        "kpiTitle": "On Time",
        "actualValue": "0",
        "variance": "0"
      },
      {
        "kpiTitle": "In Full",
        "actualValue": "0",
        "variance": "0"
      },
      {
        "kpiTitle": "OTIF",
        "actualValue": "0",
        "variance": "0"
      }
    ];
  }
 
 
  return (    
    <div className="card-body-custom">
    <div className="row">
        {data.map((obj, index) => 
          (index <= 3 && (index !== 6 || index !== 7) )?
           //(index >= 0 )?
            // (<div key={index} className={(index===2)?"col ver_divider":"col"}>
            (<div key={index} className="col">
              <KPIBox kpiData={{
                    kpiTitle: (index===3)?'WOC':obj.kpiTitle,
                    actualValue: (index === 0 || index === 1 || index === 8 || index === 9 || index === 10)? DT_CellFormatterWithPerc(obj.actualValue) : numberWithCommas(obj.actualValue),
                    //24 March 2023 Append percentage symbol is handled inside KPIBox component
                    //variance: obj.variance+'%',
                    variance: obj.variance,                    
                }}
              />
          </div>)
         : null
    )} 
    </div>
    </div>
  );

}
export default KPI;