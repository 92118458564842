/*
BuMultiList is a multi select dropdown used to list Bus with checkboxes from which user can select multiple Bus

This component can handle below props:
- onUpdate
- localRef
- error
- loadDefaultValues

Currently this component is not used in any page. This requirement is carry forwarded for Phase2
*/

import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
    setDefaultBuList,
    setDefaultSearchValues,
    getBrandList,
    setBrandList,
} from "../../redux/actions/masterActions";
import { fn_code_array, urlEncoder } from "../../utils/common-functions";
import ReactSelect from "./ReactSelect";
import { Option, MultiValue } from "./CommonFn";
import { WindowedMenuList } from "react-windowed-select";


export default function BuMultiList({ onUpdate, localRef, error, loadDefaultValues }) {
    const buList = useSelector(({ masterReducer }) => masterReducer.buList);
    const buDefaultList = useSelector(
        ({ masterReducer }) => masterReducer.buDefaultList
    );
    const defaultSearchValues = useSelector(
        ({ masterReducer }) => masterReducer.defaultSearchValues
      );
    const isDataLoading = useSelector(
        ({ masterReducer }) => masterReducer.isLoadingRequestIds
    );
    const [SelectedVal, setSelectedVal] = useState([]);
    const dispatch = useDispatch();

    return (
        <Form.Group className="row">
            <div className="col-sm-12">
                <ReactSelect
                    options={buList.filter(elements => { return elements.code != null })}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                        Option,
                        //MultiValue,
                        MenuList: WindowedMenuList,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }}
                    //12-Jan-2023 customer Updating Brand API to filter based on region along with BU. Refer RBCPFR2-122 
                    onMenuClose={(isMenuClose) => {
                        if (isMenuClose && buDefaultList.length > 0 && defaultSearchValues.region.length > 0) {
                            dispatch(getBrandList(`?bu=${urlEncoder(
                                fn_code_array(buDefaultList)
                            )}&region=${defaultSearchValues.region}`));
                        } else {
                            dispatch(setBrandList([]));
                        }
                    }}
                    onChange={(val) => {
                        //setSelectedVal(val);
                        onUpdate && onUpdate(val.length > 0 ? val : "");
                        dispatch(setDefaultBuList(val));
                        //  const setDefaultSearchVal = { bu: urlEncoder(fn_code_array(val)) };
                        // For dremio the payload goes like 
                        const setDefaultSearchVal = { bu: fn_code_array(val) };
                        dispatch(setDefaultSearchValues(setDefaultSearchVal));
                    }}
                    allowSelectAll={true}
                    value={buDefaultList}
                    isLoading={isDataLoading.includes('isBuDataLoaded') ? true : false}
                    placeholder="Choose a BU..."
                    localRef={localRef}
                />
                <div className='error'>{error}</div>
            </div>
        </Form.Group>
    );
}