/*
RegionList is a single select dropdown used to list Region from which user can select a Region

This component can handle below props:
- onUpdate
- localRef
- error

Currently this component got imported in below pages:
/src/js/screens/Cockpit/Filters/GlobalFilters.js
/src/js/screens/Instock/Filters/InStockGlobalFilters.js
/src/js/screens/Forecast/Filters/ForecastFilters.js
/src/js/screens/Otif/Filters/GlobalFilter.js
*/

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import ReactSelect from "./ReactSelect";
import { useSelector, useDispatch } from "react-redux";
import {
  setDefaultRegionList,
  setDefaultRegionMultiList,
  setDefaultSearchValues,
  getCustomerList,
  setCustomerList,
} from "../../redux/actions/masterActions";
import { WindowedMenuList } from "react-windowed-select";
import { fn_code_array, urlEncoder } from "../../utils/common-functions";
import { Option, MultiValue } from "./CommonFn";

export default function RegionMultiList({ onUpdate, localRef, error }) {
  const regionList = useSelector(
    ({ masterReducer }) => masterReducer.regionList
  );
  //handles only single region
  const regionDefaultList = useSelector(
    ({ masterReducer }) => masterReducer.regionDefaultList
  );

  const regionDefaultMultiList = useSelector(
    ({ masterReducer }) => masterReducer.regionDefaultMultiList
  );

  const defaultSearchValues = useSelector(
    ({ masterReducer }) => masterReducer.defaultSearchValues
  );

  const isDataLoading = useSelector(
    ({ masterReducer }) => masterReducer.isLoadingRequestIds
  );
  const [SelectedVal, setSelectedVal] = useState([]);
  const dispatch = useDispatch();
  const test = "US"
  return (
    <Form.Group className="row">
      <div className="col-sm-12">
      <ReactSelect          
          options={regionList.filter(elements => { return elements.code != null })}
          isMulti
          isClearable
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
              Option,
              //MultiValue,
              MenuList: WindowedMenuList,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
          }}
          //19-Jan-2023 Multi List Region Alerts Page
          onMenuClose={(isMenuClose) => {
              if (isMenuClose && regionDefaultMultiList.length > 0) {
                  dispatch(getCustomerList(`?region=${urlEncoder(
                      fn_code_array(regionDefaultMultiList)
                  )}`));
              } else {
                  dispatch(setCustomerList([]));
              }
          }}
          onChange={(val) => {
              //setSelectedVal(val);
              onUpdate && onUpdate(val.length > 0 ? val : "");
              dispatch(setDefaultRegionMultiList(val));
              //  const setDefaultSearchVal = { region: urlEncoder(fn_code_array(val)) };
              // For dremio the payload goes like 
              const setDefaultSearchVal = { regionMulti: fn_code_array(val) };
              dispatch(setDefaultSearchValues(setDefaultSearchVal));
          }}
          allowSelectAll={true}
          
          value={regionDefaultMultiList}
          value = {regionDefaultMultiList.length > 0 ? regionDefaultMultiList : { code: 'US' , name: 'US' } }
          
          isLoading={isDataLoading.includes('isRegionDataLoaded') ? true : false}
          placeholder="Choose a Region..."
          localRef={localRef}
          
          />
        <div className="error">{error}</div>
      </div>
    </Form.Group>
  );
}
