export const instockTypes = {
  SET_INVENTORY: "SET_INVENTORY",
  SET_SKU_LEVEL_DATA: "SET_SKU_LEVEL_DATA",
  SET_SEGMENT_LEVEL_DATA: "SET_SEGMENT_LEVEL_DATA",
  SET_DC_LEVEL_DATA: "SET_DC_LEVEL_DATA",
  SET_STORE_LEVEL_DATA: "SET_STORE_LEVEL_DATA",
  SET_SALES_BY_SKU: "SET_SALES_BY_SKU",
  SET_SALES_BY_DC: "SET_SALES_BY_DC",
  SET_SALES_BY_STORE: "SET_SALES_BY_STORE",
  SET_GLOBAL_SEARCH_VALUES: "SET_GLOBAL_SEARCH_VALUES",
  SET_INVENTORY_SEARCH_VALUES: "SET_INVENTORY_SEARCH_VALUES",
  SET_DATERANGE_SEARCH_VALUES: "SET_DATERANGE_SEARCH_VALUES",
  SET_MIN_MAX_SEARCH_VALUES: "SET_MIN_MAX_SEARCH_VALUES",
  SET_SKU_LEVEL_TRENDS_DATA: "SET_SKU_LEVEL_TRENDS_DATA",
  SET_STOCK_STATISTICS: "SET_STOCK_STATISTICS",
};
