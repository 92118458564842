import React, { } from 'react';

import { VeriticleBarChart } from "../../../components/Charts/index";
import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";

const InFullBarChart = (props) => {
    const { data, isLoading } = props;
    if (isLoading) {
        return (
            <DataLoader
                fullPage={false}
                message="Fetching data"
            />
        );
    }
    if (data?.length === 0) {
        return <NoDataFound message={"There is no data for selected filters!"} />;
    }
    return (
        <VeriticleBarChart
            chartLabels={data.x_label}
            chartDataSet={
                [
                    {
                        label: 'IN FULL %',
                        data: data.y_infull_perc,
                        backgroundColor: 'rgb(255, 99, 132)',
                        stack: 'Stack 0',
                    },
                ]
            }
        />
    );

}
export default InFullBarChart;