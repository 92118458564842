import React from "react";
import { useDispatch } from "react-redux";
import GlobalFilter from "../../../components/Filter/GlobalFilter";
import {
  getForecastData,
  getForecastDitributionData,
  getForecastKpiData,
  setForecastData,
  setForecastDitributionData,
  setForecastKpiData,
  setSearchValues,
} from "../../../redux/actions/forecastActions";
import Draggable from "react-draggable";

export default function ForecastGlobalFilters() {
  const dispatch = useDispatch();
  const processFilterData = (values) => {  
    values = { region: values.region, customer: values.customer, dc: values.dc, bu: values.bu, brand: values.brand, from_date: values.from_date, to_date: values.to_date };
    //Important uncomment If all APIS to be called
    dispatch(getForecastData(values));
    dispatch(getForecastKpiData(values));
    dispatch(getForecastDitributionData(values));
    dispatch(setSearchValues(values));
  };
  const resetFilterData = () => {
    dispatch(setForecastKpiData([]));
    dispatch(setForecastData([]));
    dispatch(setForecastDitributionData([]));
    dispatch(setSearchValues([]));
  };
  return (
    <Draggable>
    <GlobalFilter
      filterPage={"forecast"}
      onFormSubmit={(formData) => processFilterData(formData)}
      onFormReset={() => resetFilterData()}
      fields={["region", "customer", "dc", "bu", "brand", "dateRangeFilter"]}
    />
    </Draggable>
  );
}
