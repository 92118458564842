import React, { Component } from "react";
import { Formik } from "formik";

import { connect } from "react-redux";
import { getMenuItemData, getUserRoleData} from "../../redux/actions/userRoleActions";
import DataLoader from "../../components/Loader/DataLoader";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";

class AddRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedList: [],
            checkedChildrens: [],
            addParent: []
        };
    }

    componentDidMount() {
        this.props.getMenuItemData().then();
        this.props.getUserRoleData();
    }

    handleSelectAll = (isChecked, checkedItem) => {
        const parentName = checkedItem.substring(0, checkedItem.lastIndexOf('-'));
        if (isChecked) {           
            //Push all children belonging to parent
            const addAllChildren=[];
            for (var i = 0; i < this.props.menuItemObj.menuItems[checkedItem]?.length; i++) {
                const addTempChild = checkedItem+"-"+this.props.menuItemObj.menuItems[checkedItem][i]?.name
                addAllChildren.push(addTempChild)             
            }
            //Filter Unique
            var tempArray=[...this.state.checkedChildrens,...addAllChildren]
            var removeDuplicatesChildren = tempArray.filter(function(item, pos){
                return tempArray.indexOf(item)== pos; 
              });           

            //Add to existing children
            this.setState({                
                checkedList: [...this.state.checkedList, parentName],
                //checkedChildrens: [...this.state.checkedChildrens,...removeDuplicatesChildren],
                checkedChildrens: [...removeDuplicatesChildren],
            });
           
        } else {            
                this.state.checkedChildrens = this.state.checkedChildrens.filter(item => item.substring(0, item.lastIndexOf('-'))+"-" !== checkedItem+'-')
                let parentFilterArray = this.state.checkedList.filter(item => item !== parentName);
                this.setState({ checkedList: parentFilterArray });
        }
    }
    handleSingleCheck = (isChecked, checkedItem) => {   
        console.log(" checked item ",checkedItem)  
        console.log("parent else this.state.checkedlist ",this.state.checkedList)  
        
        this.state.checkedList = this.state.checkedList.filter(item => item !== checkedItem.substring(0, checkedItem.lastIndexOf('-')))
                
        var finalFilterArray=[];
        var tempchildrens=this.state.checkedChildrens
        //checkedItem="list"
        if (isChecked) {
            this.setState({
                checkedChildrens: [...this.state.checkedChildrens, checkedItem]
            });
        } else {    
            console.log(" children else checkedItem ",checkedItem)        
            let filterArray = tempchildrens.filter(item => item !== checkedItem);
            this.setState({ checkedChildrens: filterArray });                     
        }            
    }
    __ChildMenuItems(key) {        
        console.log(" Single select ",this.state.checkedChildrens)
        let childrens = [];
        for (var i = 0; i < this.props.menuItemObj.menuItems[key]?.length; i++) {
            var childName = key+"-"+this.props.menuItemObj.menuItems[key][i]?.name;
            var parentName=key;
            console.log(" parentName name - key ",key)

            var parentChecked = this.state.checkedList.includes(key)
            var childChecked = this.state.checkedChildrens.includes(childName)

            //const arr = ["word", "a word", "another word"];
            const arr = this.state.checkedChildrens
            console.log(" __ChildMenuItems Array is ",arr)
            //const query = key+"-";
            const query = "Users"+"-";
            const findAll = (arr, query) => {
               let count = 0;
               count = arr.filter(el => {
                  return el.indexOf(query) != -1;
               }).length;
               return count;
            };
             const matches=findAll(arr, query);
             console.log("Finding all matches ",findAll(arr, query));

             
            if(this.props.menuItemObj.menuItems[key]?.length==matches){
                var newParentChecked=true
                console.log("All elements selected  ");
                // this.setState({
                //     addParent: [...this.state.addParent, query]
                // });
            }else{
                console.log("All elements are not selected  ");
            }


                 
            childrens.push(
                <div className="form-check" key={i}>
                    <label className="form-check-label">
                        <input name={key+"-"+this.props.menuItemObj.menuItems[key][i]?.name}
                            type="checkbox"
                            className="form-check-input"
                            // checked={
                            //     (this.state.checkedList.includes(key) && this.state.checkedChildrens.includes(this.props.menuItemObj.menuItems[key][i]?.name)) ? true : false
                            // }  
                            
                            // checked={
                            //     (this.state.checkedList.includes(key) && this.state.checkedChildrens.includes(key+'-'+this.props.menuItemObj.menuItems[key][i]?.name)) ? true : false
                            // }    

                            checked={
                                (((parentChecked || childChecked))||(this.state.checkedList.includes(key) && this.state.checkedChildrens.includes(key+'-'+this.props.menuItemObj.menuItems[key][i]?.name))) ? true : false
                            }


                            onChange={(e) => this.handleSingleCheck(e.target.checked, e.target.name)}
                            
                        />
                        <i className="input-helper"></i> {this.props.menuItemObj.menuItems[key][i]?.name}
                    </label>
                </div>
            );


           // console.log(this.props.menuItemObj.menuItems[key][i].name);
            // childrens.push(
            //     <div className="form-check" key={i}>
            //         <label className="form-check-label">
            //             <input
            //                 type="checkbox"
            //                 className="form-check-input"
            //                 checked={(this.state.checkedList.includes(key) && this.state.checkedChildrens.includes(this.props.menuItemObj.menuItems[key][i]['name'])) ? true : false}
            //                 onChange={(e) => this.handleSingleCheck(e.target.checked, this.props.menuItemObj.menuItems[key][i]['name'])}
            //             />
            //             <i className="input-helper"></i> {this.props.menuItemObj.menuItems[key][i]['name']}
            //         </label>
            //     </div>
            // );
        }
        return childrens;
    }

    render() {
        // if (this.props.menuItemObj.isLoading) {
        //     return (
        //         <DataLoader
        //             fullPage={false}
        //             message="Fetching data"
        //         />
        //     );
        // }
        if (this.props.menuItemObj.menuItems?.length === 0) {
            return <NoDataFound message={"There is no data for selected filters!"} />;
        }
        return (
            <Formik
                initialValues={{}}
                validate={(values) => { }}
                onSubmit={(values) => { }}
                onReset={() => { }}
            >
                {({
                    values,
                    errors,
                    setFieldValue,
                    handleSubmit,
                    handleReset,
                    setErrors,
                    isSubmitting
                }) => (
                    <form className="form-sample" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div class="form-group">
                                            <label htmlFor="exampleFormControlSelect2">Group Names</label>
                                            <select className="form-control" id="exampleFormControlSelect2">
                                                    <option>select role</option>
                                                    {this.props.menuItemObj.userRoleList.map((val, key) =>
                                                        <option>{val.role_name}</option>
                                                    )}
                                                </select>
                                        </div>
                                        <div className="row">
                                            {Object.keys(this.props.menuItemObj.menuItems).map((val, key) => 
                                            
                                                <div className="col-sm-6 col-md-4 col-lg-3" key={key}>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input name={val}
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                onChange={(e,val) => this.handleSelectAll(e.target.checked, e.target.name)}
                                                                //onChange={(e,val) => this.handleSelectAll(e.target.checked, val)}
                                                            //checked={false}
                                                            />
                                                            <i className="input-helper"></i>
                                                            {val}
                                                        </label>
                                                        <div className="ml-4">
                                                            {this.__ChildMenuItems(val)}
                                                        </div>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {/* <div className="panel-footer footer-button">
                                            <button type="submit" className="btn btn-gradient-primary mr-2">Submit</button>
                                            <button className="btn btn-light">Cancel</button>
                                        </div> */}


                                        <p></p>
                                        <div className="col-md-12 text-center">
                                                <button
                                                    type="reset"
                                                    className="btn btn-outline-secondary btn-lg font-weight-medium auth-form-btn"
                                                    onClick={() => {
                                                        setErrors({});
                                                        handleReset();
                                                    }}
                                                >
                                                    {" "}
                                                    Reset
                                                </button>
                                                <button type="button"
                                                    onClick={() => handleSubmit()}
                                                    disabled={isSubmitting}
                                                    className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                                                //disabled={authState.isLoading}
                                                >
                                                    {/* {authState.isLoading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )} */}
                                                    <span>Add</span>

                                                </button>

                                                {/* <button type="submit" disabled={isSubmitting}>
                                              Submit
                                            </button> */}
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        )
    }
}
function mapStateToProps(state) {
    return {
        menuItemObj: state.userRoleReducer,
    };
}
const mapDispatchToProps = { getMenuItemData, getUserRoleData };
export default connect(mapStateToProps, mapDispatchToProps)(AddRole);
