import React from "react";
import { useDispatch } from "react-redux";
import GlobalFilter from "../../../components/Filter/GlobalFilter";
import {
  setGlobalSearchValues,
  setSegmentLevelData,
  setStockStatisticsData,
  setInventoryData,
  setSkuLevelData,
  setSalesByDcData,
  setSalesBySkuData,
  setSalesByStoreData,
  getInventoryData,
  getSkuLevelData,
  getSegmentLevelData,
  getSalesBySkuData,
  getSalesByDcData,
  getSalesByStoreData,
  getSkuLevelTrendData,
  setSkuLevelTrendData,
  getStockStatisticsData,
  setDateRangeSearchValues,
  setInventorySearchValues
} from "../../../redux/actions/instockActions";
import {
  todayDate,
  getLastnthWeeksDate,
} from "../../../utils/common-functions";

export default function InStockGlobalFilters() {
  const dispatch = useDispatch();
  const processFilterData = (values) => {
    values = { region: values.region, customer: values.customer, dc: values.dc, bu: values.bu, brand: values.brand, from_date: values.from_date, to_date: values.to_date };
    dispatch(setGlobalSearchValues(values));
    const dateRangeFilter = {
      from_date: getLastnthWeeksDate(13),
      to_date: todayDate(),
    };
    dispatch(setDateRangeSearchValues(dateRangeFilter));
    dispatch(setInventorySearchValues({ M: 3, N: 1.5 }));
    const defaultMinMaxThreshholdFilter = { ...values, M: 3, N: 1.5 };    
    const queryParams = {
      batch: 1,
      batchSize: 100,
      download: false
    }
    
    console.log("defaultMinMaxThreshholdFilter",defaultMinMaxThreshholdFilter)
    //Important uncomment If all APIS to be called
     dispatch(getInventoryData(defaultMinMaxThreshholdFilter, queryParams));
    dispatch(getSkuLevelData(defaultMinMaxThreshholdFilter));
    dispatch(getSegmentLevelData(defaultMinMaxThreshholdFilter));
    dispatch(getStockStatisticsData(defaultMinMaxThreshholdFilter));

    const { dc, ...removeDcFromFilter } = values;
    const salesBySkuFilter = {
      ...removeDcFromFilter,
      ...dateRangeFilter,
      limit: 5,
    };
    dispatch(getSalesBySkuData(salesBySkuFilter));
    
    // Region, Customer, BU filters included in salesByDC, Store, skutrends
    const salesByAPIFilters = {
      ...values,
      ...dateRangeFilter,
      limit: 5,
    }

    dispatch(getSalesByDcData(salesByAPIFilters));
    dispatch(getSalesByStoreData(salesByAPIFilters));
    dispatch(getSkuLevelTrendData(salesByAPIFilters));
  };

  const resetFilterData = () => {
    dispatch(setInventoryData([]));
    dispatch(setSkuLevelData([]));
    dispatch(setSalesByDcData([]));
    dispatch(setSalesBySkuData([]));
    dispatch(setSalesByStoreData([]));
    dispatch(setDateRangeSearchValues({ from_date: "", to_date: "" }));
    dispatch(setSkuLevelTrendData([]));
    dispatch(setSegmentLevelData([]));
    dispatch(setStockStatisticsData([]));
    
  };
  return (
    <GlobalFilter
      filterPage={"instock"}
      onFormSubmit={(formData) => processFilterData(formData)}
      onFormReset={() => resetFilterData()}
      fields={["region", "customer", "dc", "bu", "brand", "sku"]}
    />
  );
}
