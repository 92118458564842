import React, { Component, Suspense } from "react";
import { Tabs, Tab } from "react-bootstrap";

//Import custom components
import DataLoader from "../../components/Loader/DataLoader";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";

import AlertGlobalFilters from "./Filters/AlertGlobalFilters";

//import export data
import { 
         ExportMissingProdMapCSV, 
         ExportMissingStoreMapCSV, 
         ExportInactiveStoreSalesCSV, 
         ExportInactiveStoreinventoryCSV 
       } from "./ExportData";

// Import redux
import { connect } from "react-redux";
import {
    getMissingProdMapData,
    getMissingStoreMapData,
    getInactiveStoreSalesData, 
    getInactiveStoreInventoryData   
} from "../../redux/actions/alertActions";

//import child components
import MissingProductMapping from "./Components/MissingProductMapping";
import MissingStoreMapping from "./Components/MissingStoreMapping";
import InactiveStoreSales from "./Components/InactiveStoreSales";
import InactiveStoreInventory from "./Components/InactiveStoreInventory";
import "./Alerts.scss";

class Alerts extends Component {
  constructor(props) {
    super(props);
    this.state = {      
    };
  }

  componentDidMount() {}
  
  componentWillMount() {
    const defaultFilters = {
      region : ["US"],
      customer : ["TARGET"]
    }
    this.props.getMissingProdMapData(defaultFilters);
    this.props.getMissingStoreMapData(defaultFilters);
    this.props.getInactiveStoreSalesData(defaultFilters);
    this.props.getInactiveStoreInventoryData(defaultFilters);
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <AlertGlobalFilters />
        </div>        
        
        <div className="col-lg-12 stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h4 className="card-title">Missing Product Mapping </h4>
                <ExportMissingProdMapCSV />
              </div>
              <ErrorBoundary>
                <Suspense fallback={<DataLoader />}>
                  <MissingProductMapping
                    data={ this.props.alertsDataObj.missingProdMapData }
                    isLoading={this.props.alertsDataObj.isLoadingRequestIds.includes(
                      "isMissingProdMapDataLoaded"
                    )}
                  />
                </Suspense>
              </ErrorBoundary>
            </div> 
          </div>
        </div>
        
        <div className="col-lg-12 stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h4 className="card-title">Missing Store Mapping </h4>
                <ExportMissingStoreMapCSV />
              </div>
              <ErrorBoundary>
                <Suspense fallback={<DataLoader />}>
                  <MissingStoreMapping
                    data={ this.props.alertsDataObj.missingStoreMapData }
                    isLoading={this.props.alertsDataObj.isLoadingRequestIds.includes(
                      "isMissingStoreMapDataLoaded"
                    )}
                  />
                </Suspense>
              </ErrorBoundary>
            </div> 
          </div>
        </div>        
        
        <div className="col-lg-12 stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h4 className="card-title">Inactive Store Sales </h4>
                <ExportInactiveStoreSalesCSV />
              </div>
              <ErrorBoundary>
                <Suspense fallback={<DataLoader />}>
                  <InactiveStoreSales
                    data={ this.props.alertsDataObj.inactiveStoreSalesData }
                    isLoading={this.props.alertsDataObj.isLoadingRequestIds.includes(
                      "isInactiveStoreSalesDataLoaded"
                    )}
                  />
                </Suspense>
              </ErrorBoundary>
            </div> 
          </div>
        </div>        
       
        <div className="col-lg-12 stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h4 className="card-title">Inactive Store Inventory </h4>
                <ExportInactiveStoreinventoryCSV />
              </div>
              <ErrorBoundary>
                <Suspense fallback={<DataLoader />}>
                  <InactiveStoreInventory
                    data={ this.props.alertsDataObj.inactiveStoreInventoryData }
                    isLoading={this.props.alertsDataObj.isLoadingRequestIds.includes(
                      "isInactiveStoreInventoryDataLoaded"
                    )}
                  />
                </Suspense>
              </ErrorBoundary>
            </div> 
          </div>
        </div>  
                      
       </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alertsDataObj: state.alertReducer,
  };
}
const mapDispatchToProps = {
  getMissingProdMapData,
  getMissingStoreMapData,
  getInactiveStoreSalesData,
  getInactiveStoreInventoryData
};
export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
