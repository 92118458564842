/*
SkuList is a Multi select dropdown with checkbox. Used to list SKUs from which user can select multiple SKUs

This component can handle below props:
- onUpdate
- localRef
- error

Currently this component got imported in below pages:
/src/js/screens/Cockpit/Filters/GlobalFilters.js
/src/js/screens/Instock/Filters/InStockGlobalFilters.js
/src/js/screens/Otif/Filters/GlobalFilter.js
*/

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  setDefaultSkuList,
  setDefaultSearchValues,
} from "../../redux/actions/masterActions";
import { fn_code_array } from "../../utils/common-functions";
import ReactSelect from "./ReactSelect";
import { Option, MultiValue, CustomMenuList } from "./CommonFn";
import { WindowedMenuList } from "react-windowed-select";

export default function SkuList({ onUpdate, localRef, error }) {
  const skuList = useSelector(({ masterReducer }) => masterReducer.skuList);
  const skuDefaultList = useSelector(
    ({ masterReducer }) => masterReducer.skuDefaultList
  );
  const isDataLoading = useSelector(
    ({ masterReducer }) => masterReducer.isLoadingRequestIds
  );
  const [SelectedVal, setSelectedVal] = useState(null);
  const dispatch = useDispatch();
  return (
    <Form.Group className="row">
      <div className="col-sm-12">
        <ReactSelect
          options={skuList.filter((elements) => {
            return elements.code != null;
          })}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
            //MultiValue,
            MenuList: WindowedMenuList,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          //components={{ Option, MenuList: CustomMenuList, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          onMenuClose={(e) => { return e }}
          onChange={(val) => {
            setSelectedVal(val);
            onUpdate && onUpdate(val.length > 0 ? val : "");
            dispatch(setDefaultSkuList(val));

            const setDefaultSearchVal = { sku: fn_code_array(val) };
            dispatch(setDefaultSearchValues(setDefaultSearchVal));
          }}
          allowSelectAll={true}
          value={skuDefaultList}
          isLoading={isDataLoading.includes("isSkuDataLoaded") ? true : false}
          placeholder="Choose a SKU..."
          localRef={localRef}
        />
        <div className="error">{error}</div>
      </div>
    </Form.Group>
  );
}
