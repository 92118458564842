/*
LineChart is used to display graphical line trends

This component can handle below props:
- chartHeading
- chartDataSet
- chartLabels

Currently this component is not imported in any of the pages. 
This Component was used for Client's earlier requirement.
*/

import React, { } from 'react'
// Import chart Js
import {
    Chart as Chartjs,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';

  Chartjs.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

export default function LineChart ( props ) {
    const { chartHeading, chartDataSet, chartLabels } = props;
    const options =  {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
                display: false,
                text: chartHeading,
            },
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
            }
            // yAxes: [{
            //     ticks: {
            //         beginAtZero: true,
            //         min: 0
            //     }    
            // }]
        },

    };
    

    const chartData = {
        labels: chartLabels,
        datasets: chartDataSet,
    };

    return (
        <Line data={chartData} options={options} height="90" />
    )
}