import { commonTypes } from "../types/commonTypes";
import { alertTypes } from "../types/alertTypes";
import { POST } from "../../utils/axios";
import { getSocketStatus } from './socketActions';

export const setLoad = (isLoadingRequestIds) => {
  return {
    type: commonTypes.SET_LOADING,
    payload: isLoadingRequestIds,
  };
};


//Missing Product Mapping data
export const getMissingProdMapData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isMissingProdMapDataLoaded: true }));
    const inventoryData = await getMissingProdMapDataReq(postData);
    dispatch(setMissingProdMapData(inventoryData));
    dispatch(setLoad({ isMissingProdMapDataLoaded: false }));
  };
};
const getMissingProdMapDataReq = async (postData) => {
  try {
    const apiRes = await POST(`/missingProductMapping`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getMissingProdMapDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("Missing Product Mapping API ERROR: ", err);
    return [];
  }
};
export const setMissingProdMapData = (missingProdMapData) => {
  return {
    type: alertTypes.SET_MISSING_PRODUCT_MAPPING,
    payload: missingProdMapData,
  };
};

export const getMissingProdMapExportData = async (postData) => {
  const missingProdMapData = await getMissingProdMapDataReq(postData);
  return missingProdMapData;
};


//Missing Store Mapping data
export const getMissingStoreMapData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isMissingStoreMapDataLoaded: true }));
    const inventoryData = await getMissingStoreMapDataReq(postData);
    dispatch(setMissingStoreMapData(inventoryData));
    dispatch(setLoad({ isMissingStoreMapDataLoaded: false }));
  };
};
const getMissingStoreMapDataReq = async (postData) => {
  try {
    const apiRes = await POST(`/missingDcStoreMapping`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getMissingStoreMapDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("Missing Store Mapping API ERROR: ", err);
    return [];
  }
};
export const setMissingStoreMapData = (missingStoreMapData) => {
  return {
    type: alertTypes.SET_MISSING_STORE_MAPPING,
    payload: missingStoreMapData,
  };
};

export const getMissingStoreMapExportData = async (postData) => {
  const missingStoreMappingData = await getMissingStoreMapDataReq(postData);
  return missingStoreMappingData;
};



//Inactive Store Sales data
export const getInactiveStoreSalesData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isInactiveStoreSalesDataLoaded: true }));
    const inactiveStoreSalesData = await getInactiveStoreSalesDataReq(postData);
    dispatch(setInactiveStoreSalesData(inactiveStoreSalesData));
    dispatch(setLoad({ isInactiveStoreSalesDataLoaded: false }));
  };
};
const getInactiveStoreSalesDataReq = async (postData) => {
  try {
    const apiRes = await POST(`/inactiveStoreSales`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getInactiveStoreSalesDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("Inactive Store Sales API ERROR: ", err);
    return [];
  }
};
export const setInactiveStoreSalesData = (inactiveStoreSalesData) => {
  return {
    type: alertTypes.SET_INACTIVE_STORE_SALES,
    payload: inactiveStoreSalesData,
  };
};

export const getInactiveStoreSalesExportData = async (postData) => {
  const inactiveStoreSalesData = await getInactiveStoreSalesDataReq(postData);
  return inactiveStoreSalesData;
};


//Inactive Store Inventory data
export const getInactiveStoreInventoryData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isInactiveStoreInventoryDataLoaded: true }));
    const inactiveStoreInventoryData = await getInactiveStoreInventoryDataReq(postData);
    dispatch(setInactiveStoreInventoryData(inactiveStoreInventoryData));
    dispatch(setLoad({ isInactiveStoreInventoryDataLoaded: false }));
  };
};
const getInactiveStoreInventoryDataReq = async (postData) => {
  try {
    const apiRes = await POST(`/inactiveStoreInventory`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getInactiveStoreInventoryDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("Inactive Store Inventory API ERROR: ", err);
    return [];
  }
};
export const setInactiveStoreInventoryData = (inactiveStoreInventoryData) => {
  return {
    type: alertTypes.SET_INACTIVE_STORE_INVENTORY,
    payload: inactiveStoreInventoryData,
  };
};

export const getInactiveStoreInventoryExportData = async (postData) => {
  const inactiveStoreInventoryData = await getInactiveStoreInventoryDataReq(postData);
  return inactiveStoreInventoryData;
};


export const setGlobalSearchValues = (searchValues) => {
  return {
    type: alertTypes.SET_GLOBAL_SEARCH_VALUES,
    payload: searchValues,
  };
};