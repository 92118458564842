import React, { } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";

const tbl_headers = [
    {
        dataField: 'id',
        text: 'Id',
        sort: true
    },
    {
        dataField: 'role_name',
        text: 'Role Name',
        sort: true
    },
    {
        dataField: 'createdAt',
        text: 'Created',
    },
    {
        dataField: 'df2',
        isDummyField: true,
        text: 'Action',
        formatter: (cellContent, row) => {
            return (
                <div className="action-btn">
                    <a className="btn">
                        <i className="mdi mdi-pencil-box-outline"></i>
                    </a>
                    <a className="btn">
                        <i className="mdi mdi-delete"></i>
                    </a>
                </div>
            );
        }
    }
];
const pagination_options = {
    // pageStartIndex: 0,
    sizePerPage: 5,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true
};
const Listing = (props) => {
    const { data, isLoading } = props;
    if (isLoading) {
        return (
            <DataLoader
                fullPage={false}
                message="Fetching data"
            />
        );
    }
    if (data?.length === 0) {
        return <NoDataFound message={"There is no data for selected filters!"} />;
    }
    return (
        <BootstrapTable
            bootstrap4
            keyField='id'
            data={data}
            columns={tbl_headers}
            classes=''
            noDataIndication="There is no data to display!"
            pagination={paginationFactory()}
        />
        // <ToolkitProvider
        //     keyField="id"
        //     data={data}
        //     columns={tbl_headers}
        // >
        //     {
        //         props => (
        //             <div>
        //                 <BootstrapTable
        //                     striped
        //                     hover
        //                     condensed
        //                     classes='table-responsive scrolling-lock-table-wrapper'
        //                     {...props.baseProps}
        //                     pagination={paginationFactory(pagination_options)}
        //                     noDataIndication="There is no data to display!" />
        //             </div>
        //         )
        //     }
        // </ToolkitProvider>
    );
}
export default Listing;