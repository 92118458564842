import React, { Component } from "react";

import { connect } from "react-redux";
import { getUserList } from "../../redux/actions/userManagementActions";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import Listing from "./components/Listing";

import { addUser } from "../../redux/actions/userManagementActions";
import AddUser from "./AddUser"

class ListUser extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.getUserList();
    }

    render() {
        return (
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div class="d-flex justify-content-between pb-4">
                                <h4 className="card-title">List Users</h4>
                                <button type="submit" href='/home/add-user' onClick={AddUser} className="ml-3 btn btn-gradient-primary"> <a href='/home/add-user'>Add User</a> </button>
                            </div>
                            <ErrorBoundary>
                                <Listing data={this.props.userManagementObj.userList} isLoading={this.props.userManagementObj.isLoading}  />
                            </ErrorBoundary>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        userManagementObj: state.userManagementReducer
    };
}
const mapDispatchToProps = { getUserList };
export default connect(mapStateToProps, mapDispatchToProps)(ListUser);
