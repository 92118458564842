import { userRoleTypes } from "../types/userRoleTypes";
import { GET, POST } from '../../utils/axios';

// dummy data
import permissionsData from "../../../assets/data/permission/permissions.json";
import menuItemData from "../../../assets/data/permission/addPermissions.json";

const setLoad = (isLoading) => {
    return {
        type: userRoleTypes.SET_LOADING,
        payload: isLoading
    }
}

//Get user role data from API
export const getUserRoleData = (postData) => {
    return async (dispatch) => {
        dispatch(setLoad(true));
        const apiRes = await getUserRoleDataReq(postData);
        dispatch(setUserRoleData(apiRes));
        dispatch(setLoad(false));
    }
}
const getUserRoleDataReq = async (postData) => {
    try {
        const apiRes = permissionsData; //await POST(`/user-role`, postData);
        if (apiRes.status.statusCode === "200") {
            return apiRes.data;
        }
        return [];
    } catch (err) {
        return [];
    }
}
export const setUserRoleData = (data) => {
    return {
        type: userRoleTypes.SET_ROLE_DATA,
        payload: data
    }
}

//Get menu items from API
export const getMenuItemData = (postData) => {
    return async (dispatch) => {
        dispatch(setLoad(true));
        const apiRes = await getMenuItemDataReq(postData);
        dispatch(setMenuItemData(apiRes));
        dispatch(setLoad(false));
    }
}
const getMenuItemDataReq = async (postData) => {
    try {
        const apiRes = menuItemData; //await POST(`/user-role`, postData);
        if (apiRes.status.statusCode === "200") {
            return apiRes.data;
        }
        return [];
    } catch (err) {
        return [];
    }
}
export const setMenuItemData = (data) => {
    return {
        type: userRoleTypes.SET_MENU_ITEMS,
        payload: data
    }
}