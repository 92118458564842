// Import required libraies
import React from "react";
import NoDataFound from "../NoDataFound/NoDataFound";
import ErrorFound from "../ErrorFound/ErrorFound"

function DataLoader(props) {
  const { fullPage, message, customHeight, errorMessage } = props;

  console.log(errorMessage);
  //If there is a socket error display the error message
  // if (errorMessage === undefined || errorMessage === null) {
  //   return <ErrorFound message={errorMessage} />;
  // }

  return (
    <div>
        <div className="loader-demo-box">
            
            <div className="jumping-dots-loader">
            {/* {message && <div className="loader-text">{message}</div>} */}
                <span></span><span></span><span></span>
                {message && <div className="loader-text">{message}</div>}
            </div>
        </div>
      </div>
  );
}

export default DataLoader;
