/*
BrandList is a single select dropdown used to list brands from which user can select a brand

This component can handle below props:
- onUpdate
- localRef
- error
- loadDefaultValues

Currently this component got imported in below pages:
/src/js/screens/Cockpit/Filters/GlobalFilters.js
/src/js/screens/Instock/Filters/InStockGlobalFilters.js
/src/js/screens/Forecast/Filters/ForecastFilters.js
/src/js/screens/Otif/Filters/GlobalFilter.js
*/

import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  setDefaultBrandList,
  setDefaultSearchValues,
  getSkuList,
  setSkuList,
} from "../../redux/actions/masterActions";
import { fn_code_array, urlEncoder } from "../../utils/common-functions";
import ReactSelect from "./ReactSelect";
import { Option, MultiValue, CustomMenuList } from "./CommonFn";

export default function BrandList({
  onUpdate,
  localRef,
  error,
  loadDefaultValues,
}) {
  const brandList = useSelector(({ masterReducer }) => masterReducer.brandList);
  const brandDefaultList = useSelector(
    ({ masterReducer }) => masterReducer.brandDefaultList
  );
  const defaultSearchValues = useSelector(
    ({ masterReducer }) => masterReducer.defaultSearchValues
  );
  const isDataLoading = useSelector(
    ({ masterReducer }) => masterReducer.isLoadingRequestIds
  );

  // const [SelectedVal, setSelectedVal] = useState([]);
  // const [customizeVal, setCustomizeVal] = useState(0);
  const dispatch = useDispatch();
  return (
    <Form.Group className="row">
      <div className="col-sm-12">
        <ReactSelect
          options={brandList.filter((elements) => {
            return elements.code != null;
          })}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
            MultiValue,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onMenuClose={(isMenuClose) => {

            if (isMenuClose && brandDefaultList.length > 0) {
              dispatch(getSkuList(
                `?region=${defaultSearchValues.region}&customer=${defaultSearchValues.customer}&bu=${urlEncoder(defaultSearchValues.bu)}&brand=${urlEncoder(
                  fn_code_array(brandDefaultList)
                )}`
              ))
            } else {
              dispatch(setSkuList([]));
            }
          }}
          onChange={(val) => {
            onUpdate && onUpdate(val.length > 0 ? val : "");
            dispatch(setDefaultBrandList(val));
            const setDefaultSearchVal = { brand: fn_code_array(val) };
            dispatch(setDefaultSearchValues(setDefaultSearchVal));
          }}
          allowSelectAll={true}
          value={brandDefaultList}
          isLoading={isDataLoading.includes("isBrandDataLoaded") ? true : false}
          placeholder="Choose a Brand..."
          localRef={localRef}
        />
        <div className="error">{error}</div>
      </div>
    </Form.Group>
  );
}
