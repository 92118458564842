import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
//import "bootstrap/dist/css/bootstrap.min.css";

import ErrorBoundary from "../../../components/ErrorBoundary/ErrorBoundary";
import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { PieChart } from "../../../components/Charts/index";
import OnTimeBarChart from './OnTimeBarChart';
import { DT_CellFormatterWithComma } from "../../../utils/common-functions";
import { cleanArrObject } from '../../../utils/common-functions';
import '../Otif.scss';

import {
    getOnTimeAccountabilityData,
    setOnTimeAccountabilityData,
  } from "../../../redux/actions/otifActions";

  export default function OnTimeHistogram(props) {
  const { data, data3, data3_isLoading, isLoading } = props;
  const totalOfOnTimeAcc  = [0, 0, 0, 0, 0];
  const totalOfOnTimeAccForChart  = [0, 0, 0];
 
  const otifSearchValues = useSelector(({ otifReducer }) => otifReducer.otifGlobalSearchValues);
  const dispatch = useDispatch();

  const [category, setCategory] = useState('brand');

  //if (isLoading) {
  if (data3_isLoading) {
    return (
      <DataLoader
        fullPage={false}
        message="Fetching data"
      />
    );
  }
  if (data?.length === 0) {
    return <NoDataFound message={"There is no data for selected filters!"} />;
  }
  
  const filterDataCategoryWise = (selectedValue) => {
        const filterForDate = { "category": selectedValue }
        const newfilterForOTAccTable = { ...cleanArrObject(otifSearchValues), ...filterForDate };
        //Important 27 April 2023 commented the dispatch method as these are calling dremio queries 
        //UnComment when connected to connector dataource 
        //dispatch(getOnTimeAccountabilityData(newfilterForOTAccTable));
  }
 
  return (
    <div className="row">
        <div className="col-md-12">
            <ErrorBoundary>
                <OnTimeBarChart data={data3}
                    isLoading = {data3_isLoading} />
            </ErrorBoundary>
        </div>
    </div>      
  );
}