/*
CustomerList is a single select dropdown used to list Customers from which user can select a customer

This component can handle below props:
- onUpdate
- localRef
- error

Currently this component got imported in below pages:
/src/js/screens/Cockpit/Filters/GlobalFilters.js
/src/js/screens/Instock/Filters/InStockGlobalFilters.js
/src/js/screens/Forecast/Filters/ForecastFilters.js
/src/js/screens/Otif/Filters/GlobalFilter.js
*/

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector, useDispatch } from "react-redux";
import {
  setDefaultCustomerList,
  setDefaultSearchValues,
  getDcList,
  setDcList,
} from "../../redux/actions/masterActions";
import { fn_code_array } from "../../utils/common-functions";

export default function CustomerList({ onUpdate, localRef, error }) {
  const customerList = useSelector(
    ({ masterReducer }) => masterReducer.customerList
  );
  const customerDefaultList = useSelector(
    ({ masterReducer }) => masterReducer.customerDefaultList
  );
  const isDataLoading = useSelector(
    ({ masterReducer }) => masterReducer.isLoadingRequestIds
  );
  const [SelectedVal, setSelectedVal] = useState([]);
  const dispatch = useDispatch();

  return (
    <Form.Group className="row">
      <div className="col-sm-12">
        <Typeahead
          defaultSelected={customerDefaultList}
          id="customer-typeahead-multiple"
          labelKey="name"
          onChange={(val) => {
            // setting up dc list based on customer
            if (val.length > 0) {
              dispatch(getDcList(`?customer=${val[0].code}`));
            } else {
              dispatch(setDcList([]));
            }

            //setSelectedVal(val);
            onUpdate && onUpdate(val.length > 0 ? val : "");
            dispatch(setDefaultCustomerList(val));
            const setDefaultSearchVal = { customer: val[0]?.code };
            dispatch(setDefaultSearchValues(setDefaultSearchVal));
          }}
          options={customerList.filter((elements) => {
            return elements.code != null;
          })}
          placeholder="Choose a Customer..."
          single
          //selected={SelectedVal}
          isLoading={
            isDataLoading.includes("isCustomerDataLoaded") ? true : false
          }
          ref={localRef}
        />
        <div className="error">{error}</div>
      </div>
    </Form.Group>
  );
}
