import { cleanArrObject } from "../../../utils/common-functions";

export const getFilterValuesForExport = (props) => {
    var alertGlobalSearchValues = props.alertGlobalSearchValues;
    //const newArr = { limit: 0 };
    const allFilterObj = {
        ...cleanArrObject(alertGlobalSearchValues),
        //...newArr,
    };
    return allFilterObj;
};