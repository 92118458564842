import React, { } from 'react';
import { AreaLineChart as LineChart } from "../../../components/Charts/index";
import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { dateFormatter } from "../../../utils/common-functions";

const OOS = (props) => {
    const { data, isLoading } = props;
    if (isLoading) {
        return (
            <DataLoader
                fullPage={false}
                message="Fetching data"
            />
        );
    }
    if (data?.length === 0) {
        return <NoDataFound message={"OOS data set is empty!"} />;
    }

    const changeLabelDateFormat = [];
    if (data.x_label.length > 0) {
        for (var index = 0; index < data.x_label.length; index++) {
            const element = dateFormatter(data.x_label[index], 'MM/DD/YY');
            changeLabelDateFormat.push(element);
        }
    }

    return (
        <LineChart
            chartLabels={changeLabelDateFormat}
            chartDataSet={
                [
                    {
                        label: 'OOS Stores',
                        data: data.y_oos_stores,
                        backgroundColor: ['rgba(53, 162, 235, 0.5)'],
                        borderColor: ['rgb(53, 162, 235)'],
                        borderWidth: 1,
                        fill: false,
                        yAxisID: 'y'
                    },
                    {
                        label: 'Instock Stores',
                        data: data.y_instock_stores,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        borderWidth: 1,
                        fill: false,
                        yAxisID: 'y'
                    }
                ]
            }
            showXAxis={false}
            graphTitle="OOS Stores"
            chartHeight={60}
            showSecondaryY={false}
        />
    );

}
export default OOS;