import React from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { DT_CellFormatterWithComma } from "../../../utils/common-functions";

const tbl_headers = [
  {
    dataField: "region",
    text: "Region",
  },
  {
    dataField: "customer",
    text: "Customer",
  },
  {
    dataField: "segment",
    text: "Segment",
  },
  {
    dataField: "sku",
    text: "SKU",
  },
  {
    dataField: "retailer_sku",
    text: "Retailer SKU",
  },
  {
    dataField: "product_description",
    text: "SKU Name",
  },
  {
    dataField: "store",
    text: "Store Number",
  },
  {
    dataField: "dc",
    text: "DC Number",
  },
  {
    dataField: "brand_name",
    text: "Brand Name",
  },
  //Remove pos_average
  //Rename average_forecast to avg_pos_forecast_4w
  //dc_order_forecast to "sum_order_forecast_4w" in Frontend
  {
    dataField: "avg_pos_forecast_4w",
    text: "Average Forecast POS (4W)",
    formatter: DT_CellFormatterWithComma,
    style: {},
  },
  {
    dataField: "sum_order_forecast_4w",
    text: "Sum Order Forecast 4W",
    formatter: DT_CellFormatterWithComma,
  },
  {
    dataField: "store_level_inventory",
    text: "Store Inv. LW",
  },
  {
    dataField: "store_week_of_supply",
    text: "Store WOC (POS 4W AVG)",
    formatter: DT_CellFormatterWithComma,
  },
  {
    dataField: "dc_inventory",
    text: "DC Inventory",
    formatter: DT_CellFormatterWithComma,
  },
  {
    dataField: "dc_week_of_supply",
    text: "DC WOC (POS 4W AVG)",
    formatter: DT_CellFormatterWithComma,
  },
  // Add avg_pos_historical_4w_store, avg_pos_historical_4w_dc, dc_future_woc
  {
    dataField: "avg_pos_historical_4w_store",
    text: "Store (POS Historical 4W AVG )",
    formatter: DT_CellFormatterWithComma,
  },
  {
    dataField: "avg_pos_historical_4w_dc",
    text: "DC (POS Historical 4W AVG )",
    formatter: DT_CellFormatterWithComma,
  },
  {
    dataField: "dc_future_woc",
    text: "DC Future WOC",
    formatter: DT_CellFormatterWithComma,
  },
  {
    dataField: "dc_current_suggestion",
    text: "DC Current Suggestion",
  },
  {
    dataField: "dc_future_suggestion",
    text: "DC Future Suggestion",
  },
  {
    dataField: "risk",
    text: "Risk",
  },
  {
    dataField: "pos_conversion_factor",
    text: "Case Pack",
  },
  {
    dataField: "pos_conversion_factor_pallets",
    text: "Pallet Qty",
  },
];
const pagination_options = {
  // pageStartIndex: 0,
  sizePerPage: 5,
  hideSizePerPage: true,
  hidePageListOnlyOnePage: true,
};

const Inventory = (props) => {
  const { data, isLoading } = props;
  if (isLoading) {
    return <DataLoader fullPage={false} message="Fetching data" />;
  }
  if (data?.length === 0) {
    return <NoDataFound message={"There is no data for selected filters!"} />;
  }
  return (
    <ToolkitProvider keyField={`sku store`} data={data} columns={tbl_headers}>
      {(props) => (
        <div>
          <BootstrapTable
            striped
            hover
            condensed
            classes="table-responsive"
            {...props.baseProps}
            pagination={paginationFactory(pagination_options)}
            noDataIndication="There is nos data for selected filters!"
          />
        </div>
      )}
    </ToolkitProvider>
  );
};
export default Inventory;
