import React, { Component } from "react";
import { CSVLink } from "react-csv";

import { connect } from "react-redux";
import { getMissingProdMapExportData } from "../../../redux/actions/alertActions";

import { getFilterValuesForExport } from './GetFilterForExport';

const headers = [
  {
    key: "region",
    label: "Region",
  },
  {
    key: "retailer_id",
    label: "Retailer ID",
  },
  {
    key: "retailer_product_id",
    label: "Retailer Product ID",
  },
  {
    key: "reckitt_product_code",
    label: "Reckitt Product Code",
  },
  {
    key: "consumer_product_code",
    label: "Consumer Product Code",
  },
  {
    key: "description",
    label: "Description",
  },
  {
    key: "brand_name",
    label: "Brand Name",
  },
  {
    key: "bu",
    label: "BU",
  },
  {
    key: "segment_description",
    label: "Segment Description",
  },
  {
    key: "missing_flag",
    label: "Missing Flag",
  },
];

class ExportMissingProdMapCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      IsDataLoading: false,
    };
    this.csvLinkEl = React.createRef();
  }

  fetchDataFromServer = async () => {
    var filterItems = getFilterValuesForExport(this.props.alertsDataObj);
    const apiRes = await getMissingProdMapExportData(filterItems);
    return apiRes;
  };

  downloadReport = async () => {
    if (!this.state.IsDataLoading) {
      this.setState({ IsDataLoading: true });
      const data = await this.fetchDataFromServer();
      this.setState({ data: data, IsDataLoading: false }, () => {
        setTimeout(() => {
          this.csvLinkEl.current.link.click();
        });
      });
    }
  };


  render() {
    const { data } = this.state;
    return (
      <div>
        <input
          type="button"
          value={
            this.state.IsDataLoading ? "Loading csv..." : "Export All Data"
          }
          className="btn btn-gradient-info label-right font-weight-bold px-lg-4 px-3"
          onClick={this.downloadReport}
        />
        <CSVLink
          headers={headers}
          filename={"ExportedMissingProductMapping.csv"}
          asyncOnClick={true}
          data={data}
          ref={this.csvLinkEl}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alertsDataObj: state.alertReducer,
  };
}
const mapDispatchToProps = { getMissingProdMapExportData };
export default connect(mapStateToProps, mapDispatchToProps)(ExportMissingProdMapCSV);