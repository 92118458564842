import React, { } from 'react';
import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import {  DT_CellFormatterWithComma } from "../../../utils/common-functions";

import '../Forecast.scss';
const ForecastDistribution = (props) => {
  const { data, isLoading } = props;
  //const tblFirstColumn = ['Order Forecast', 'POS Forecast', 'Inventory', 'Weeks of Supply forecast', 'Fill Rate (last 4 week average)', 'Adjusted Inventory', 'Adjusted Weeks of supply'];
  if (isLoading) {
    return (
      <DataLoader
        fullPage={false}
        message="Fetching data"
        //errorMessage={data}
      />
    );
  }
  if (Object.keys(data).length === 0) {
    return <NoDataFound message={"There is no data for selected filters!"} />;
  }

  const tblData = (dataType) => {
    var retData = [];
    Object.values(data).map((tblData, index) => {
      if( dataType === 'forecast' ){
        retData.push((<td>{DT_CellFormatterWithComma(tblData[1].forecast)}</td>));
      } else if ( dataType === 'orders' ){
        retData.push((<td>{DT_CellFormatterWithComma(tblData[1].orders)}</td>));
      } else if ( dataType === 'forecast_cases' ){
        retData.push((<td>{DT_CellFormatterWithComma(tblData[1].forecast_cases)}</td>));
      } else if ( dataType === 'orders_cases' ){
        retData.push((<td>{DT_CellFormatterWithComma(tblData[1].orders_cases)}</td>));
      }
    })
    return retData;
  }

  return (
    <table className="table table-bordered table-hover">
      
      <thead>
        <tr>
          <th></th>
          {Object.values(data).map((heading, index) => {
            return(
              <th>{heading[0]}</th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Forecast Eaches</td>
          {tblData('forecast')}
        </tr>
        <tr>
          <td>Forecast Cases</td>
          {tblData('forecast_cases')}
        </tr>
        <tr>
          <td>Orders Eaches</td>
          {tblData('orders')}
        </tr>      
        <tr>
          <td>Orders Cases</td>
          {tblData('orders_cases')}
        </tr>
      </tbody>
    </table>
  );

}
export default ForecastDistribution;