export const executivePageTypes = {
  SET_GLOBAL_SEARCH_VALUES: "SET_GLOBAL_SEARCH_VALUES",
  SET_CUSTOMER_KPI: "SET_CUSTOMER_KPI",
  SET_BU_KPI: "SET_BU_KPI",
  SET_BRAND_KPI: "SET_BRAND_KPI",
  SET_SEGMENT_KPI: "SET_SEGMENT_KPI",
  SET_EXECUTIVE_TREND_CHART: "SET_EXECUTIVE_TREND_CHART",
  SET_DATERANGE_SEARCH_VALUES: "SET_DATERANGE_SEARCH_VALUES",
  SET_EXECUTIVE_PIVOT_DATA: "SET_EXECUTIVE_PIVOT_DATA",
};
