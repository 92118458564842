import React from "react";

import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

function PageHeader( props ) {
    const { match } = props;
    return (
        <div>
            <div className="page-header">
                <h3 className="page-title"> {props.pageTitle} </h3>
                <Breadcrumbs match={ match } />
                {/* <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Tables</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Basic tables</li>
                    </ol>
                </nav> */}
            </div>
        </div>
    );
}

export default PageHeader;