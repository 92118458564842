import React from "react";
import { Alert } from "react-bootstrap";

// Error boundaries currently have to be classes.

export default class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null };
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }
  componentDidCatch = (hasError, error) => {
    this.setState({ hasError, error });
  };
  render() {
    if (this.state.hasError) {
      return (
        <Alert variant="error">
          {/* <AlertIcon /> */}
          <Alert.Heading mr={2}>An error occured!</Alert.Heading>
          <Alert.Description>
            Please contact us for assistance.
          </Alert.Description>
        </Alert>
      );
    }
    return this.props.children;
  }
}
