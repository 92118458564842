import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import ErrorBoundary from "../../../components/ErrorBoundary/ErrorBoundary";
import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { PieChart } from "../../../components/Charts/index";
import InFullBarChart from './InFullBarChart';
import { DT_CellFormatterWithComma } from "../../../utils/common-functions";
import { cleanArrObject } from '../../../utils/common-functions';
import '../Otif.scss';

import {
    getInFullAccountabilityData,
    setOnTimeAccountabilityData,
  } from "../../../redux/actions/otifActions";

export default function InFullAccountability(props) {
    
    const { data, data3, data3_isLoading, isLoading } = props;
    const totalOfOnTimeAcc = [0, 0, 0];
    const totalOfOnTimeAccSegment = [0, 0, 0];
    const totalOfOnTimeAccSku = [0, 0, 0];
    const totalOfOnTimeAccForChart = [0, 0];
    
    const otifSearchValues = useSelector(({ otifReducer }) => otifReducer.otifGlobalSearchValues);
    const dispatch = useDispatch();

    const [category, setCategory] = useState('brand');
    
    if (isLoading) {
        return (
            <DataLoader
                fullPage={false}
                message="Fetching data"
            />
        );
    }
    if (data?.length === 0) {
        return <NoDataFound message={"There is no data for selected filters!"} />;
    }
    
    const filterDataCategoryWise = (selectedValue) => {
       const filterForDate = { "category": selectedValue }
       const newfilterForIFAccTable = { ...cleanArrObject(otifSearchValues), ...filterForDate };
       //Important 27 April 2023 commented the dispatch method as these are calling dremio queries 
       //UnComment when connected to connector dataource 
       dispatch(getInFullAccountabilityData(newfilterForIFAccTable));
    }
      
    return (
        <div className="row">
            <div className="col-md-3">
                <PieChart
                    chartLabels={["Ordered", "Unfulfilled"]}
                    chartDataSet={
                        [
                            {
                                //label: 'On Time Orders Actual',
                                data: totalOfOnTimeAccForChart,
                                backgroundColor: [
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 99, 132, 0.2)'
                                ],
                                borderColor: [
                                    'rgba(54, 162, 235, 1)',
                                    'rgba(255, 99, 132, 1)'
                                ],
                                borderWidth: 1,
                            }
                        ]
                    }
                    showXAxis={false}
                    showYAxis={false}
                    graphTitle="On Time Accountability"
                />
            </div>
            <div className="col-md-9 remove-padding">
            <Tabs 
                id="controlled-tab-infull"
                activeKey={category}   
                onSelect={(e) => { setCategory(e); filterDataCategoryWise(e); }}
                className="mb-3"
            >
            <Tab id="inFullBrand" eventKey="brand" title="Brand" >
                <div className="table-responsive my-custom-scrollbar">
                        <table className="table table-bordered table-hover tbl-custom-width">
                        <thead>
                            <tr>
                                <th className="text-center">Brands</th>
                                <th className="text-center">Ordered</th>
                                <th className="text-center">Received</th>
                                <th className="text-center">Unfulfilled</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((obj, index) => {
                                totalOfOnTimeAcc[0] = parseInt(obj.received) + parseInt(totalOfOnTimeAcc[0]);
                                totalOfOnTimeAcc[1] = totalOfOnTimeAccForChart[0] = parseInt(obj.ordered) + parseInt(totalOfOnTimeAcc[1]);
                                var unfilledOrdered = parseInt(obj.ordered) - parseInt(obj.received)
                                var unfilledOrderedPerc = parseInt(obj.ordered)===0 ? 'NA' : DT_CellFormatterWithComma((parseInt(obj.ordered) - parseInt(obj.received))*100/parseInt(obj.ordered)) + '%';
                                totalOfOnTimeAcc[2] = totalOfOnTimeAccForChart[1] = parseInt(unfilledOrdered) + parseInt(totalOfOnTimeAcc[2]);
                                return (
                                    <tr>
                                        <th className="normalFont text-center">{obj.brand_name}</th>
                                        <td className="text-center">{obj.ordered}</td>
                                        <td className="text-center">{obj.received} ({obj.ordered===0 ? 'NA' : DT_CellFormatterWithComma(obj.received*100/obj.ordered) + '%'})</td>
                                        <td className="text-center">{unfilledOrdered} ({unfilledOrderedPerc})</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                        <tfoot>
                            <tr>
                                <th className="text-center">Total</th>
                                <th className="text-center">{totalOfOnTimeAcc[1]}</th>
                                <th className="text-center">{totalOfOnTimeAcc[0]}</th>
                                <th className="text-center">{totalOfOnTimeAcc[2]}</th>
                            </tr>
                        </tfoot>
                        </table>
                    </div>
            </Tab>
            <Tab id="inFullSegment" eventKey="segment" title="Segment" >
                <div className="table-responsive my-custom-scrollbar">
                        <table className="table table-bordered table-hover tbl-custom-width">
                        <thead>
                            <tr>
                                {/* <th className="text-center">Brands</th> */}
                                <th className="text-center">Segments</th>
                                <th className="text-center">Ordered</th>
                                <th className="text-center">Received</th>
                                <th className="text-center">Unfulfilled</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((obj, index) => {
                                totalOfOnTimeAccSegment[0] = parseInt(obj.received) + parseInt(totalOfOnTimeAccSegment[0]);
                                totalOfOnTimeAccSegment[1] = totalOfOnTimeAccForChart[0] = parseInt(obj.ordered) + parseInt(totalOfOnTimeAccSegment[1]);
                                var unfilledOrdered = parseInt(obj.ordered) - parseInt(obj.received)
                                var unfilledOrderedPerc = parseInt(obj.ordered)===0 ? 'NA' : DT_CellFormatterWithComma((parseInt(obj.ordered) - parseInt(obj.received))*100/parseInt(obj.ordered)) + '%';
                                totalOfOnTimeAccSegment[2] = totalOfOnTimeAccForChart[1] = parseInt(unfilledOrdered) + parseInt(totalOfOnTimeAccSegment[2]);
                                return (
                                    <tr>
                                        {/* <th className="text-center">{obj.brand_name}</th> */}
                                        <th className="normalFont text-center">{obj.segment}</th>
                                        <td className="text-center">{obj.ordered}</td>
                                        <td className="text-center">{obj.received} ({obj.ordered===0 ? 'NA' : DT_CellFormatterWithComma(obj.received*100/obj.ordered) + '%'})</td>
                                        <td className="text-center">{unfilledOrdered} ({unfilledOrderedPerc})</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                        <tfoot>
                            <tr>
                                {/* <th className="text-center"></th> */}
                                <th className="text-center">Total</th>
                                <th className="text-center">{totalOfOnTimeAccSegment[1]}</th>
                                <th className="text-center">{totalOfOnTimeAccSegment[0]}</th>
                                <th className="text-center">{totalOfOnTimeAccSegment[2]}</th>
                            </tr>
                        </tfoot>
                        </table>
                    </div>
            </Tab>
            <Tab id="inFullSku" eventKey="sku" title="SKU" >
                <div className="table-responsive my-custom-scrollbar">
                        <table className="table table-bordered table-hover tbl-custom-width">
                        <thead>
                            <tr>
                                {/* <th className="text-center">Brands</th>
                                <th className="text-center">Segments</th> */}
                                <th className="text-center">SKUs</th>
                                <th className="text-center">Ordered</th>
                                <th className="text-center">Received</th>
                                <th className="text-center">Unfulfilled</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((obj, index) => {
                                totalOfOnTimeAccSku[0] = parseInt(obj.received) + parseInt(totalOfOnTimeAccSku[0]);
                                totalOfOnTimeAccSku[1] = totalOfOnTimeAccForChart[0] = parseInt(obj.ordered) + parseInt(totalOfOnTimeAccSku[1]);
                                var unfilledOrdered = parseInt(obj.ordered) - parseInt(obj.received)
                                var unfilledOrderedPerc = parseInt(obj.ordered)===0 ? 'NA' : DT_CellFormatterWithComma((parseInt(obj.ordered) - parseInt(obj.received))*100/parseInt(obj.ordered)) + '%';
                                totalOfOnTimeAccSku[2] = totalOfOnTimeAccForChart[1] = parseInt(unfilledOrdered) + parseInt(totalOfOnTimeAccSku[2]);
                                return (
                                    <tr>
                                        {/* <th className="text-center">{obj.brand_name}</th>
                                        <th className="text-center">{obj.segment}</th> */}
                                        <th className="normalFont text-center text-wrap">{obj.sku}</th>
                                        <td className="text-center">{obj.ordered}</td>
                                        <td className="text-center">{obj.received} ({obj.ordered===0 ? 'NA' : DT_CellFormatterWithComma(obj.received*100/obj.ordered) + '%'})</td>
                                        <td className="text-center">{unfilledOrdered} ({unfilledOrderedPerc})</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                        <tfoot>
                            <tr>
                                {/* <th className="text-center"></th>
                                <th className="text-center"></th> */}
                                <th className="text-center">Total</th>
                                <th className="text-center">{totalOfOnTimeAccSku[1]}</th>
                                <th className="text-center">{totalOfOnTimeAccSku[0]}</th>
                                <th className="text-center">{totalOfOnTimeAccSku[2]}</th>
                            </tr>
                        </tfoot>
                        </table>
                    </div>
            </Tab>    
            </Tabs>
            </div>
            {/* <div className="col-md-4">
                <ErrorBoundary>
                    <InFullBarChart data={data3}
                        isLoading={data3_isLoading} 
                        />
                </ErrorBoundary>
            </div> */}
        </div>
    );

}
//export default InFullAccountability;