import React, { } from 'react';
import { AreaLineChart as LineChart  } from "../../../components/Charts/index";
import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { dateFormatter } from "../../../utils/common-functions";

const POS = (props) => {
    const { data, isLoading } = props;
    if (isLoading) {
        return (
            <DataLoader
                fullPage={false}
                message="Fetching data"
            />
        );
    }
    if (data?.length === 0) {
        return <NoDataFound message={"POS data set is empty!"} />;
    }

    const changeLabelDateFormat = [];
    if (data.x_label.length > 0) {
        for (var index = 0; index < data.x_label.length; index++) {
            const element = dateFormatter(data.x_label[index], 'DD MMM YY');
            changeLabelDateFormat.push(element);
        }
    }

    return (
        <LineChart
            chartLabels={changeLabelDateFormat}
            chartDataSet={
                [

                    {
                        type: 'line',
                        label: 'POS',
                        data: data.y_pos,
                        borderColor: ['rgba(19, 0, 255)'],
                        backgroundColor: ['rgba(19, 0, 255, 0.5)'],
                        yAxisID: 'y',
                        borderWidth: 1,
                        fill: false
                       
                    },
                    {
                        type: 'line',
                        label: 'Orders',
                        data: data.y_orders,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        yAxisID: 'y',
                        borderWidth: 1,
                        fill: false
                    },
                    {
                        type: 'bar',
                        label: 'Total Inventory',
                        data: data.y_tot_inventory,
                        backgroundColor: [
                            'rgba(31, 124, 17, 0.56)',
                        ],
                        borderColor: [
                            'rgba(31, 124, 17, 0.56)'
                        ],
                        yAxisID: 'y',
                        borderWidth: 1,
                        fill: false
                    },
                ]
            }
            showXAxis={false}
            graphTitle="POS & Orders"
            chartHeight={60}
            showSecondaryY={false}
        />
    );

}
export default POS;