import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { cleanArrObject } from "../../../utils/common-functions";

import {
  setInventorySearchValues,
  getInventoryData,
  getSkuLevelData,
  getStockStatisticsData,
} from "../../../redux/actions/instockActions";

export default function InventoryFilters(props) {
  //const { } = props;

  const instockGlobalSearchValues = useSelector(
    ({ instockReducer }) => instockReducer.instockGlobalSearchValues
  );
  //const instockInventorySearchValues = useSelector(({ instockReducer }) => instockReducer.instockInventorySearchValues);
  const dispatch = useDispatch();
  const customHandleReset = () => {
    dispatch(setInventorySearchValues([]));
    const object3 = { ...cleanArrObject(instockGlobalSearchValues) };
    
    //Important 27 April 2023 commented the dispatch method as these are calling dremio queries 
    //UnComment when connected to connector dataource 
    // dispatch(getInventoryData(object3));
    // dispatch(getSkuLevelData(object3));
  };

  const [inventoryTypeState, setinventoryTypeState] = useState(false);
  return (
    <Formik
      initialValues={{
        inventoryType: "All",
        N: 1.5,
        M: 3.0,
      }}
      validate={(values) => {
        const errors = {};
        if (!values.inventoryType && !values.N && !values.M) {
          errors.field_error = "All fields are empty!";
        } else if (
          values.N !== "" &&
          values.M !== "" &&
          parseInt(values.N) > parseInt(values.M)
        ) {
          errors.field_error = "Max WOC should be greater the Min WOC";
        }
        return errors;
      }}
      onSubmit={(values) => {
        const { inventoryType, N, M } = values;
        //var searchValue = {};
        const params = { inventoryType, N, M };
        dispatch(setInventorySearchValues({ ...values, inventoryType: "All" }));

        const object3 = {
          ...cleanArrObject(params),
          ...cleanArrObject(instockGlobalSearchValues),
        };

        //Important 27 April 2023 commented the dispatch method as these are calling dremio queries 
        //UnComment when connected to connector dataource 
        // dispatch(getInventoryData(object3));
        // dispatch(getSkuLevelData(object3));
        // dispatch(getStockStatisticsData(object3));
        props.handleInvetoryDD("All");
        setinventoryTypeState(true);
      }}
      onReset={(values) => {}}
    >
      {({
        values,
        errors,
        setFieldValue,
        handleSubmit,
        handleReset,
        setErrors,
      }) => (
        <form className="form-sample" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-4">
              <h4 className="card-title">KPIs</h4>
            </div>
            <div className="col-md-2">
              {/* <Form.Group className="row">
                <div className="col-sm-12"> */}
              {inventoryTypeState ? (
                <select
                  className="form-control"
                  onChange={(e) => {
                    setFieldValue("inventoryType", e.target.value);
                    props.handleInvetoryDD(e.target.value);
                  }}
                >
                  <option value="All" selected>
                    All
                  </option>
                  <option value="At Stock">At Stock</option>
                  <option value="Out of Stock">Out of Stock</option>
                  <option value="Under Stock">Under Stock</option>
                  <option value="Over Stock">Over Stock</option>
                </select>
              ) : (
                <select
                  className="form-control"
                  onChange={(e) => {
                    setFieldValue("inventoryType", e.target.value);
                    props.handleInvetoryDD(e.target.value);
                  }}
                >
                  <option value="All">All</option>
                  <option value="At Stock">At Stock</option>
                  <option value="Out of Stock">Out of Stock</option>
                  <option value="Under Stock">Under Stock</option>
                  <option value="Over Stock">Over Stock</option>
                </select>
              )}
              {/* </div>
              </Form.Group> */}
            </div>

            <div className="col-md-2">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text text-dark text-center text-dark-custom">
                    Min WOC
                  </div>
                </div>
                <label className="sr-only" htmlFor="inlineFormInputName2">
                  Min WOC
                </label>
                <Form.Control
                  type="number"
                  onChange={(e) => setFieldValue("N", e.target.value)}
                  className="form-control"
                  id="inlineFormInputName2"
                  placeholder="1.5"
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text text-dark text-center text-dark-custom">
                    Max WOC
                  </div>
                </div>
                <label className="sr-only" htmlFor="inlineFormInputName3">
                  Max WOC
                </label>
                <Form.Control
                  type="number"
                  onChange={(e) => setFieldValue("M", e.target.value)}
                  className="form-control"
                  id="inlineFormInputName3"
                  placeholder="3.0"
                />
              </div>
            </div>

            <div className="col-md-2 text-right">
              <button
                type="reset"
                className="btn btn-outline-secondary btn-sm"
                onClick={() => {
                  setErrors({});
                  customHandleReset();
                }}
              >
                {" "}
                Reset{" "}
              </button>
              <button
                type="button"
                className="btn btn-gradient-danger btn-sm"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {" "}
                Filter{" "}
              </button>
            </div>
          </div>
          <div className="error">{errors.field_error}</div>
        </form>
      )}
    </Formik>
  );
}
