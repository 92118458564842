/*
Key Performance Indicator (KPI) component is created to display statistics data with same design

This component can handle below props:
- kpiTitle
- actualValue
- variance
- comparison

Currently this component got imported in below pages:
/src/js/screens/Cockpit/Components/KPI.js
/src/js/screens/Instock/Components/StockStatistics.js
/src/js/screens/Forecast/Components/ForecastKpi.js
*/

import React, { Component } from 'react'
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

// Import styles
import "./KPIComponent.scss";

export default function KPIBox(props) {
    const { kpiData } = props;
    return (
        <div className="header-kpi pb-8 pt-1 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Card className="card-stats-kpi mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col-kpi">
                        <CardTitle tag="h6" className="text-uppercase text-muted text-nowrap mb-2 limit-width">
                            {kpiData.kpiTitle}
                        </CardTitle>
                        <span className="h4 font-weight-bold mb-0">
                        {kpiData.actualValue}
                        </span>
                      </div>
                      </Row>
                      <Row>

                    {kpiData.variance > 0 ? 
                      (<p className="mt-2 mb-0 text-muted text-sm">
                      <span className="text-success mr-2">
                        <i className="mdi mdi-arrow-up-thick text-success icon-sm text-nowrap" /> {kpiData.variance}% 
                      </span>{""}
                      <span className="text-nowrap text-success comparison-text">Compared to LW</span>
                    </p>)
                    :
                      (<p className="mt-2 mb-0 text-muted text-sm">
                      <span className="text-danger mr-2">
                        <i className="mdi mdi-arrow-down text-danger icon-sm text-nowrap" /> {kpiData.variance}% 
                      </span>{""}
                      <span className="text-nowrap text-danger comparison-text">Compared to LW</span>
                    </p>)
                    }

                    {kpiData.variance > 0 ? 
                      (<p className="mt-0 mb-0 text-muted text-sm ">
                      <span className="text-success mr-2">
                        <i className="mdi mdi-arrow-up-thick text-success icon-sm text-nowrap" /> 
                        {/* {kpiData.variance} */}
                         --- % 
                      </span>{" "}
                      <span className="text-nowrap text-success comparison-text">Compared to LY</span>
                    </p>)
                    :
                      (<p className="mt-0 mb-0 text-muted text-sm">
                      <span className="text-danger mr-2">
                        <i className="mdi mdi-arrow-down text-danger icon-sm text-nowrap" /> 
                        {/* {kpiData.variance} */}
                        --- % 
                      </span>{" "}
                      <span className="text-nowrap text-danger comparison-text">Compared to LY</span>
                    </p>)
                    }
                    </Row>
                  </CardBody>
            </Card>
            </div>
        </Container>
      </div>
            
                
            
        // <div className="card aligner-wrapper">
        //     <div className="card-body-custom">
        //         {/* <div className="absolute left center h-50 v-strock-2 bg-danger"></div> */}
        //         <p className="mb-2 text-dark text-center text-dark-custom">{kpiData.kpiTitle}</p>
        //         <div className="align-items-center">
        //             <h5 className="font-weight-medium mb-2 text-dark text-center">{kpiData.actualValue}</h5>
        //         </div>
        //         <h4 className="font-weight-medium text-success ml-2 text-center" >{kpiData.variance}</h4>
        //         {/* {kpiData.comparison ? 
        //         (<div className="d-flex align-items-center">
        //             <div className="bg-danger dot-indicator"></div>
        //             <p className="text-muted mb-0 ml-2 text-center">{kpiData.comparison}</p>
        //         </div>):(null)
        //         } */}
        //     </div>
        // </div>
    )
}

