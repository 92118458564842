import React, { Component } from 'react'
import { components } from "react-select";
import VirtualList from 'react-tiny-virtual-list';
import PropTypes from "prop-types";
import './ReactSelect.scss';

export const Option = props => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };
    return (
        <div>
            <components.Option {...newProps} className="custom-option">
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};
 
export const MultiValue = props => ( 
    <components.MultiValue {...props}>
        <span>{props.data.name}</span>
    </components.MultiValue>
);

const DefaultItemHeight = 40;
export class CustomMenuList extends Component { 
    
    static propTypes = {
        options   : PropTypes.array.isRequired,
        children  : PropTypes.node.isRequired,
        maxHeight : PropTypes.number.isRequired,
        getValue  : PropTypes.func.isRequired
      }
     
  renderItem = (props) => {
    const { children } = this.props;
    if (Array.isArray(children)) {
      return (
        <li style={props.style} key={props.index}>
          {children[props.index]}
        </li>
      );
    }
    return (
      <li
        key={props.index}
        className="react-virtualized-menu-placeholder"
      >
        {children.props.children}
      </li>
    );
  }

  render() { 
    const { options, children, maxHeight, getValue } = this.props;

    const [value]         = getValue();
    const initialOffset   = options.indexOf(value) * DefaultItemHeight;
    const childrenOptions = React.Children.toArray(children);
    const wrapperHeight   = maxHeight < childrenOptions.length * DefaultItemHeight ?
                              maxHeight : childrenOptions.length * DefaultItemHeight;

    return (
      <span className="react-virtualized-list-wrapper">
        <VirtualList
          width="100%"
          height={wrapperHeight + 6}
          scrollOffset={initialOffset}
          itemCount={childrenOptions.length}
          itemSize={DefaultItemHeight}
          renderItem={this.renderItem}
        />
      </span>
    );
  }
}