/*
AreaLineChart is used to display graphical line trends

This component can handle below props:
- chartLabels
- chartDataSet
- showXAxis, 
- graphTitle, 
- chartHeight, 
- showSecondaryY

Currently this component got imported in below pages:
/src/js/screens/Cockpit/Components/POS.js
/src/js/screens/Cockpit/Components/OOS.js
/src/js/screens/Cockpit/Components/OTIF.js
/src/js/screens/InStock/Components/SkuLevelTrend.js
/src/js/screens/Forecast/Components/ForecastChart.js
*/


import React, { } from 'react'
// Import chart Js
import {
    Chart as Chartjs,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  import { numFormatterForChart } from '../../utils/common-functions';
  
  Chartjs.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );



export default function AreaLineChart ( props ) {
    const { chartDataSet, chartLabels, showXAxis, graphTitle, chartHeight, showSecondaryY } = props;
    const options =  {
        responsive: true,
        barThickness:(chartLabels.length > 10 ) ? 15: 15,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
                display: true,
                text: graphTitle,
                position:'left'
            },
            labels:{
                display:false
            },
            filler: {
                propagate: true
            },
            legend:{
                display:true,
                labels: {
                    boxHeight: 7,
                    boxWidth: 22,
                    font: {
                        size: 10
                      },
                      
                }              
            },
            tooltip:{
                filter: function (tooltipItem) {
                    return tooltipItem.raw !== null;
                }
           }
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    callback: (label) => numFormatterForChart(label),
                    backdropPadding:10,
                    //color:"red"
                  },
                  grid: {
                    display: false,
                    color:"red"
                  }
                //backgroundColor:"red"
            },
            y1: {
                type: 'linear',
                display: (showSecondaryY === false)?false:true,
                position: 'right',
                grid: {
                    display: false,
                    color:"red"
                  }
            },
            x:{
                display:showXAxis,
                grid: {
                    display: false,
                    color:"red"
                  }
            }
        },

    };
    

    const chartData = {
        labels: chartLabels,
        datasets: chartDataSet,
    };

    return (
        <Line data={chartData} options={options} height={(chartHeight)?chartHeight:'163'} />
    )
}