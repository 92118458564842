import { combineReducers } from "redux";
import user from "./user";
import authReducer from "./authReducer";
import userRoleReducer from "./userRoleReducer";
import masterReducer from "./masterReducer";
import forecastReducer from "./forecastReducer";
import analystLandingReducer from "./AnalystLandingReducer";
import instockReducer from "./instockReducer";
import executivePageReducer from "./executivePageReducer";
import otifReducer from "./otifReducer";
import alertReducer from "./alertReducer"
import userManagementReducer from "./userManagementReducer"

export default combineReducers({
  authReducer,
  user,
  userRoleReducer,
  masterReducer,
  forecastReducer,
  instockReducer,
  analystLandingReducer,
  executivePageReducer,
  otifReducer,
  userManagementReducer,
  alertReducer,
});
