//Import required libraies
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//Import Custom Component
import Header from "../../components/Shared/Header";
import Footer from "../../components/Shared/Footer";
import Sidenav from "../../components/Shared/Sidebar";

function LayoutTopSideBottom(props) {
  const { user, children, match } = props;
  return (
    <div className="container-scroller">
      <Header match={match} />
      <div className="container-fluid page-body-wrapper">
        <Sidenav match={match} />
        <div className="main-panel">
          <div className="content-wrapper">
            {children}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

LayoutTopSideBottom.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(LayoutTopSideBottom);
