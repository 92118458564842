import React, { Component } from "react";
import { Formik } from "formik";

import { connect } from "react-redux";
import { getUserRoleData } from "../../redux/actions/userRoleActions";
import { getUserList, updateUserData } from "../../redux/actions/userManagementActions";

import DataLoader from "../../components/Loader/DataLoader";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import UserManagement from ".";


class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedList: [],
      checkedChildrens: []
    };
  }

  
  componentDidMount() {
    this.props.getUserRoleData();
    this.props.getUserList();    
    this.props.updateUserData();    
  }

  render() {
    // if (this.props.userManagementObj.isLoading) {
    //   return (
    //     <DataLoader
    //       fullPage={false}
    //       message="Fetching data"
    //     />
    //   );
    // }

    if (this.props.userManagementObj.userList?.length === 0) {
      return <NoDataFound message={"There is no data for selected filters!"} />;
    }
    else{
      console.log(this.props.userManagementObj?.userList.email)
    }
    return (
      <Formik
        initialValues={{ username: "", email: "sumathy", password: '', created_date: "" }}

        validate={(values) => {
          const errors = {};
          if (!values.username) {
            errors.username = "Username required";
          }
          if (!values.email) {
            errors.email = 'Email required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
          if (!values.password) {
            errors.password = "Password required!";
          }
          if (!values.usertype) {
            errors.usertype = "Select user type!";
          }
          return errors;
        }
        }


        onSubmit={(values) => { }}
        onReset={() => { }}
      >
        {({
          values,
          errors,
          setFieldValue, //Use this
          handleSubmit,
          handleReset,
          setErrors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,

        }) => (
          <form className="form-sample" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="col-md-12">
                      <div className="form-group">
                        <input type="text" className="form-control form-control-lg"
                          id="exampleInputUsername1" name="username" placeholder="Name"
                          // value={this.props.userManagementObj.userList[0].user_name} onChange={e => {
                          //   alert(e.target.value);
                          //   //setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                          // }}

                        />
                      </div>
                      <div className="form-group">
                        <input type="email" className="form-control form-control-lg" 
                          //onChange={onInputChange}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Email" 
                          onUpdate={(skuCode) => {
                            setFieldValue("email", skuCode);
                          }}
                          />
                        {errors.email && touched.email}
                      </div>
                      <div className="form-group">
                        <input type="password" className="form-control form-control-lg"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={this.props.userManagementObj.userList[0]?.password}
                          placeholder="Password" />
                        {errors.password && touched.password}
                      </div>

                      <div className="form-group">
                        <input type="password" className="form-control form-control-lg" id="exampleInputPassword1" name="confirmpassword" placeholder="confirm password" />
                      </div>
                      <div class="form-group">
                        <select className="form-control" id="exampleFormControlSelect2">
                          <option>{this.props.userManagementObj.userList[0]?.role}</option>
                          {this.props.menuItemObj.userRoleList.map((val, key) =>
                          val.role_name!=this.props.userManagementObj.userList[0]?.role?
                            <option>{val.role_name}</option>:''
                          )}
                        </select>
                      </div>
                      <div className="col-md-12 text-center">
                        <button
                          type="reset"
                          className="btn btn-outline-secondary btn-lg font-weight-medium auth-form-btn"
                          onClick={() => {
                            setErrors({});
                            handleReset();
                          }}
                        >
                          {" "}
                          Reset
                        </button>
                        <button type="button"
                          onClick={() => handleSubmit()}
                          disabled={isSubmitting}
                          className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                        //disabled={authState.isLoading}
                        >
                          {/* {authState.isLoading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )} */}
                          <span>Update</span>

                        </button>

                        {/* <button type="submit" disabled={isSubmitting}>
                                              Submit
                                            </button> */}
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    )
  }
}
function mapStateToProps(state) {
  return {
    menuItemObj: state.userRoleReducer,
    userManagementObj: state.userManagementReducer
  };
}
const mapDispatchToProps = { getUserRoleData, getUserList, updateUserData };
export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
