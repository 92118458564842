/*
RegionList is a single select dropdown used to list Region from which user can select a Region

This component can handle below props:
- onUpdate
- localRef
- error

Currently this component got imported in below pages:
/src/js/screens/Cockpit/Filters/GlobalFilters.js
/src/js/screens/Instock/Filters/InStockGlobalFilters.js
/src/js/screens/Forecast/Filters/ForecastFilters.js
/src/js/screens/Otif/Filters/GlobalFilter.js
*/

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector, useDispatch } from "react-redux";
import {
  setDefaultRegionList,
  setDefaultSearchValues,
  getCustomerList,
  setCustomerList,
} from "../../redux/actions/masterActions";

import { fn_code_array } from "../../utils/common-functions";

export default function RegionList({ onUpdate, localRef, error }) {
  const regionList = useSelector(
    ({ masterReducer }) => masterReducer.regionList
  );
  const regionDefaultList = useSelector(
    ({ masterReducer }) => masterReducer.regionDefaultList
  );
  const isDataLoading = useSelector(
    ({ masterReducer }) => masterReducer.isLoadingRequestIds
  );
  const [SelectedVal, setSelectedVal] = useState([]);
  const dispatch = useDispatch();

  return (
    <Form.Group className="row">
      <div className="col-sm-12">
        <Typeahead
          defaultSelected={regionDefaultList}
          id="customer-typeahead-multiple"
          labelKey="name"
          onChange={(val) => {
            // setting up customer list based on region
            if (val.length > 0) {
              dispatch(getCustomerList(`?region=${val[0].code}`));
            } else {
              dispatch(setCustomerList([]));
            }
            // setting up selected value with local state
            /*setSelectedVal(val);*/

            // returning back selected values to child component
            onUpdate && onUpdate(val.length > 0 ? val : "");

            // setting up selected items as default regions list across the pages
            dispatch(setDefaultRegionList(val));

            // setting up selected values as default searched items
            const setDefaultSearchVal = { region: val[0]?.code };
            dispatch(setDefaultSearchValues(setDefaultSearchVal));
          }}
          options={regionList.filter((elements) => {
            return elements.code != null;
          })}
          placeholder="Choose a Region..."
          single
          //selected={SelectedVal}
          isLoading={
            isDataLoading.includes("isRegionDataLoaded") ? true : false
          }
          ref={localRef}
        />
        <div className="error">{error}</div>
      </div>
    </Form.Group>
  );
}
