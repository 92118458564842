import React from "react";

import { KPIComponent } from "../../../components/KPIComponent/index";
import DataLoader from "../../../components/Loader/DataLoader";
import { numberWithCommas } from "../../../utils/common-functions";

const ForecastKpi = (props) => {
  let { data, isLoading } = props;
  if (isLoading) {
    return <DataLoader fullPage={false} message="Fetching data" />;
  }
  if (data?.length === 0) {
    //return <NoDataFound message={"There is no data for selected filters!"} />;
    data = [
      {
        kpiTitle: "CW POS Eaches",
        actualValue: "0",
        variance: "0",
      },
      {
        kpiTitle: "CW POS Cases",
        actualValue: "0",
        variance: "0",
      },
      {
        kpiTitle: "CW Orders Eaches",
        actualValue: "0",
        variance: "0",
      },
      {
        kpiTitle: "CW Orders Cases",
        actualValue: "0",
        variance: "0",
      },
      {
        kpiTitle: "CW Inv Eaches",
        actualValue: "0",
        variance: "0",
      },
      {
        kpiTitle: "CW Inv Cases",
        actualValue: "0",
        variance: "0",
      },
      {
        kpiTitle: "CW WOC",
        actualValue: "0",
        variance: "0",
      },
      // {
      //   "kpiTitle": "F8W POS",
      //   "actualValue": "0",
      //   "variance": "0"
      // },
      // {
      //   "kpiTitle": "F8W Order",
      //   "actualValue": "0",
      //   "variance": "0"
      // },
      // {
      //   "kpiTitle": "F8W Inv",
      //   "actualValue": "0",
      //   "variance": "0"
      // },
      // {
      //   "kpiTitle": "F8W WOC",
      //   "actualValue": "0",
      //   "variance": "0"
      // }
    ];
  }

  return (
    <div className="container">
      <div className="row">
        {data.map((obj, index) =>
          index < 7 ? (
            <div className="col" key={index}>
              <KPIComponent
                key={index}
                kpiData={{
                  kpiTitle: obj.kpiTitle,
                  //actualValue: (index === 7 || index === 8 || index === 9) ? obj.actualValue + "%" : numberWithCommas(obj.actualValue),
                  actualValue:
                    index === 7 || index === 8 || index === 9
                      ? obj?.actualValue
                      : numberWithCommas(obj?.actualValue),
                  //variance: obj.variance + '%',
                  //comparison: 'KPI Vs Lastweek'
                }}
              />
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};
export default ForecastKpi;
