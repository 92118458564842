const userManagementReducer={
  userManagementValues: {
    username: "",
    email: "",
    password: "",
    role: "",
    created_date: ""
  },
  isLoading: false,
  userList: [],
  username:"", 
  email:"", 
  password:"",
  role: "",
  created_date:"",
  addUserData: [],  
  updateUser: [],
  deleteUser: []
}


const authReducer = {
  isLoading: false,
  isLoggedIn: false,
  message: '',
  userData: []
}
const user = {
  theme: "light",
  screen: {
    isSidenavBarExpanded: true,
  },
};

const userRoleReducer = {
  isLoading: false,
  userRoleList: [],
  menuItems: []
}

const masterReducer = {
  isLoading: false,
  isLoadingRequestIds: [],
  regionList: [],
  customerList: [],
  regionMultiList: [],
  customerMultiList: [],
  dcList: [],
  itemList: [],
  storeList: [],
  segmentList: [],
  skuList: [],
  brandList: [],
  buList: [],
  regionDefaultList: [],
  customerDefaultList: [],
  regionDefaultMultiList: [],
  customerDefaultMultiList: [],
  dcDefaultList: [],
  buDefaultList: [],
  brandDefaultList: [],
  skuDefaultList: [],
  segmentDefaultList: [],
  defaultSearchValues: {
    region: "",
    //regionMulti: [],
    customer: "",
    //customerMulti: [],
    dc: "",
    bu: "",
    brand: "",
    segment: "",
    sku: "",
    from_date: "",
    to_date: "",    
  },
};

const forecastReducer = {
  isLoadingRequestIds: [],
  forecastChartData: [],
  mbDistributionChartData: [],
  NatData: [],
  forecastDistributionData: [],
  forecastSearchValues: {
    region: "",
    customer: "",
    dc: "",
    bu: "",
    brand: "",
    segment: "",
    sku: "",
    from_date: "",
    to_date: "",
  },
  forecastKpiData: [],
};

const instockReducer = {
  isLoadingRequestIds: [],
  inventoryData: [],
  skuLevelData: [],
  segmentLevelData: [],
  dcLevelData: [],
  storeLevelData: [],
  salesBySkuData: [],
  salesByDcData: [],
  salesByStoreData: [],
  instockGlobalSearchValues: {
    region: "",
    customer: "",
    dc: "",
    bu: "",
    brand: "",
    segment: "",
    sku: "",
  },
  instockInventorySearchValues: {
    inventoryType: "",
    N: "",
    M: "",
  },
  instockDateRangeSearchValues: {
    from_date: "",
    to_date: "",
  },
  instockMinMaxSearchValues: {
    N: "",
    M: "",
  },
  stockStatistics: [],
  skuLevelTrends: [],
};

const analystLandingReducer = {
  isLoadingRequestIds: [],
  analystGlobalSearchValues: {
    region: "",
    customer: "",
    bu: [],
    brand: [],
    segment: [],
    sku: [],
  },
  analystDateRangeSearchValues: {
    from_date: "",
    to_date: "",
  },
  analystKpiData: [],
  analystPOSChartData: [],
  analystOOSChartData: [],
  analystOTIFChartData: [],
  analystLandingAggregateData: [],
};

const executivePageReducer = {
  isLoadingRequestIds: [],
  executiveGlobalSearchValues: {
    region: "",
    customer: "",
    bu: "",
    brand: "",
    segment: "",
  },
  customerKpiData: [],
  buKpiData: [],
  brandKpiData: [],
  segmentKpiData: [],
  executiveDateRangeSearchValues: {
    from_date: "",
    to_date: "",
  },
  executivePivotData: []
};

const otifReducer = {
  otifGlobalSearchValues: {
    region: "",
    customer: "",
    dc: "",
    bu: "",
    brand: "",
    segment: "",
    sku: "",
  },
  isLoadingRequestIds: [],
  ontimeaccountability: [],
  infullaccountability: [],
  OTIFChartData: [],
  ontimeBarChartData: [],
  infullBarChartData: [],
  otifDateRangeSearchValues: {
    from_date: "",
    to_date: "",
  },
  onTimeStats: [],
  inFullStats: [],
};

const socketReducer = {
  isConnected: false,
  isSubscribedToEvent: []
}


const alertReducer = {  
  isLoadingRequestIds: [],
  missingProdMapData: [],
  missingStoreMapData: [],
  inactiveStoreSalesData: [],
  inactiveStoreInventoryData: [],
  alertGlobalSearchValues: {
    region: "",
    customer: ""
  },
  // regionDefaultMultiList: [],
  // customerDefailtMultiList: [],
};


export const stateData = {
  userManagementReducer,
  authReducer,
  user,
  userRoleReducer,
  masterReducer,
  forecastReducer,
  instockReducer,
  analystLandingReducer,
  executivePageReducer,
  otifReducer,
  socketReducer,
  alertReducer,
};
