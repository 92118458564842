import { commonTypes } from "../types/commonTypes";
import { masterTypes } from "../types/masterTypes";
import { GET } from "../../utils/axios";
import { getSocketStatus } from './socketActions';

const setLoad = (isLoadingRequestIds) => {
  return {
    type: commonTypes.SET_LOADING,
    payload: isLoadingRequestIds,
  };
};

//Region lists
export const getRegionList = () => {
  return async (dispatch) => {
    dispatch(setLoad({ isRegionDataLoaded: true }));
    const regionList = await getRegionListReq();
    dispatch(setRegionList(regionList));
    dispatch(setLoad({ isRegionDataLoaded: false }));
  };
};
const getRegionListReq = async () => {
  try {
    const apiRes = await GET(`/region`);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getRegionListReq();
      }
    }
    return [];
  } catch (err) {
    return [];
  }
};
export const setRegionList = (regionList) => {
  return {
    type: masterTypes.SET_REGION,
    payload: regionList,
  };
};

//Segment lists
export const getSegmentList = (reqParams) => {
  return async (dispatch) => {
    dispatch(setLoad({ isSegmentDataLoaded: true }));
    const apiResponse = await getSegmentListReq(reqParams);
    dispatch(setSegmentList(apiResponse));
    dispatch(setLoad({ isSegmentDataLoaded: false }));
  };
};
const getSegmentListReq = async (reqParams) => {
  try {
    const apiRes = await GET(`/segment${reqParams}`);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getSegmentListReq(reqParams);
      }
    }
    return [];
  } catch (err) {
    return [];
  }
};
export const setSegmentList = (segmentList) => {
  return {
    type: masterTypes.SET_SEGMENT,
    payload: segmentList,
  };
};

//customer lists
export const getCustomerList = (reqParams) => {
  return async (dispatch) => {
    dispatch(setLoad({ isCustomerDataLoaded: true }));
    const customerList = await getCustomerListReq(reqParams);
    dispatch(setCustomerList(customerList));
    dispatch(setLoad({ isCustomerDataLoaded: false }));
  };
};
const getCustomerListReq = async (reqParams) => {
  try {
    const apiRes = await GET(`/customer${reqParams}`);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {

      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getCustomerListReq(reqParams);
      }
    }
    return [];
  } catch (err) {
    return [];
  }
};
export const setCustomerList = (customerList) => {
  return {
    type: masterTypes.SET_CUSTOMER,
    payload: customerList,
  };
};

//DC lists
export const getDcList = (reqParams) => {
  return async (dispatch) => {
    dispatch(setLoad({ isDcDataLoaded: true }));
    const dcList = await getDcListReq(reqParams);
    dispatch(setDcList(dcList));
    dispatch(setLoad({ isDcDataLoaded: false }));
  };
};
const getDcListReq = async (reqParams) => {
  try {
    const apiRes = await GET(`/dc${reqParams}`);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getDcListReq(reqParams);
      }
    }
    return [];
  } catch (err) {
    console.log("DC error", err);
    return [];
  }
};

export const setDcList = (dcList) => {
  return {
    type: masterTypes.SET_DC,
    payload: dcList,
  };
};



//Item lists
export const getItemList = () => {
  return async (dispatch) => {
    dispatch(setLoad({ isItemDataLoaded: true }));
    const itemList = await getItemListReq();
    dispatch(setItemList(itemList));
    dispatch(setLoad({ isItemDataLoaded: false }));
  };
};
const getItemListReq = async () => {
  try {
    // const itemData = await GET(`/item`);
    // if( itemData.status.statusCode === "200" ){
    //     return itemData.data;
    // }
    return [];
  } catch (err) {
    return [];
  }
};
export const setItemList = (itemList) => {
  return {
    type: masterTypes.SET_ITEM,
    payload: itemList,
  };
};

//Brand lists
export const getBrandList = (reqParams) => {
  return async (dispatch) => {
    dispatch(setLoad({ isBrandDataLoaded: true }));
    const brandList = await getBrandListReq(reqParams);
    dispatch(setBrandList(brandList));
    dispatch(setLoad({ isBrandDataLoaded: false }));
  };
};
const getBrandListReq = async (reqParams) => {
  try {
    const apiRes = await GET(`/brand${reqParams}`);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === 'COMPLETED') {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getBrandListReq(reqParams);
      }
    }
    return [];
  } catch (err) {
    return [];
  }
};
export const setBrandList = (brandList) => {
  return {
    type: masterTypes.SET_BRAND,
    payload: brandList,
  };
};

//Store lists
export const getStoreList = (reqParams) => {
  return async (dispatch) => {
    dispatch(setLoad({ isStoreDataLoaded: true }));
    const storeList = await getStoreListReq(reqParams);
    dispatch(setStoreList(storeList));
    dispatch(setLoad({ isStoreDataLoaded: false }));
  };
};
const getStoreListReq = async (reqParams) => {
  try {
    const storeData = await GET(`/store${reqParams}`);
    if (storeData.status.statusCode === "200") {
      return storeData.data?.result;
    }
    return [];
  } catch (err) {
    return [];
  }
};
export const setStoreList = (storeList) => {
  return {
    type: masterTypes.SET_STORE,
    payload: storeList,
  };
};

//BU lists
export const getBuList = (reqParams) => {
  return async (dispatch) => {
    dispatch(setLoad({ isBuDataLoaded: true }));
    const buList = await getBuListReq(reqParams);
    dispatch(setBuList(buList));
    dispatch(setLoad({ isBuDataLoaded: false }));
  };
};
const getBuListReq = async (reqParams) => {
  try {
    const apiRes = await GET(`/bu${reqParams}`);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getBuListReq(reqParams);
      }
    }
    return [];
  } catch (err) {
    return [];
  }
};
export const setBuList = (buList) => {
  return {
    type: masterTypes.SET_BU,
    payload: buList,
  };
};

//SKU lists
export const getSkuList = (reqParams) => {
  return async (dispatch) => {
    dispatch(setLoad({ isSkuDataLoaded: true }));
    const skuList = await getSkuListReq(reqParams);
    dispatch(setSkuList(skuList));
    dispatch(setLoad({ isSkuDataLoaded: false }));
  };
};
const getSkuListReq = async (reqParams) => {
  try {
    const apiRes = await GET(`/upc${reqParams}`);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result; //.slice(0, 100);
    } else {

      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getSkuListReq(reqParams);
      }
    }
    return [];
  } catch (err) {
    return [];
  }
};
export const setSkuList = (skuList) => {
  return {
    type: masterTypes.SET_SKU,
    payload: skuList,
  };
};

export const setDefaultRegionList = (regionDefaultList) => {
  return {
    type: masterTypes.SET_DEFAULT_REGION,
    payload: regionDefaultList,
  };
};
//19-Jan-2023 for alerts page
export const setDefaultRegionMultiList = (regionDefaultMultiList) => {
  return {
    type: masterTypes.SET_DEFAULT_REGION_MULTI_LIST,
    payload: regionDefaultMultiList,
  };
};
export const setDefaultCustomerList = (customerDefaultList) => {
  return {
    type: masterTypes.SET_DEFAULT_CUSTOMER,
    payload: customerDefaultList,
  };
};
//19-Jan-2023 for alerts page
export const setDefaultCustomerMultiList = (customerDefaultMultiList) => {
  return {
    type: masterTypes.SET_DEFAULT_CUSTOMER_MULTI_LIST,
    payload: customerDefaultMultiList,
  };
};
export const setDefaultDcList = (dcDefaultList) => {
  return {
    type: masterTypes.SET_DEFAULT_DC,
    payload: dcDefaultList,
  };
};
export const setDefaultBuList = (buDefaultList) => {
  return {
    type: masterTypes.SET_DEFAULT_BU,
    payload: buDefaultList,
  };
};

export const setDefaultBrandList = (brandDefaultList) => {
  return {
    type: masterTypes.SET_DEFAULT_BRAND,
    payload: brandDefaultList,
  };
};
export const setDefaultSkuList = (skuDefaultList) => {
  return {
    type: masterTypes.SET_DEFAULT_SKU,
    payload: skuDefaultList,
  };
};

export const setDefaultSearchValues = (defaultSearchValues) => {
  return {
    type: masterTypes.SET_DEFAULT_SEARCH_VALUES,
    payload: defaultSearchValues,
  };
};


