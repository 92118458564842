/*
Key Performance Indicator (KPI) component is created to display statistics data with same design

This component can handle below props:
- kpiTitle
- actualValue
- variance
- comparison

Currently this component got imported in below pages:
/src/js/screens/Cockpit/Components/KPI.js
/src/js/screens/Instock/Components/StockStatistics.js
/src/js/screens/Forecast/Components/ForecastKpi.js
*/

import React, { Component } from 'react'
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

// Import styles
import "./KPIComponent.scss";

export default function KPIComponent(props) {
    const { kpiData } = props;
    return (
        <div className="card aligner-wrapper">
            <div className="card-body-custom">
                {/* <div className="absolute left center h-50 v-strock-2 bg-danger"></div> */}
                <p className="mb-2 text-dark text-center text-dark-custom">{kpiData.kpiTitle}</p>
                <div className="align-items-center">
                    <h5 className="font-weight-medium mb-2 text-dark text-center">{kpiData.actualValue}</h5>
                </div>
                <h4 className="font-weight-medium text-success ml-2 text-center" >{kpiData.variance}</h4>
                {/* {kpiData.comparison ? 
                (<div className="d-flex align-items-center">
                    <div className="bg-danger dot-indicator"></div>
                    <p className="text-muted mb-0 ml-2 text-center">{kpiData.comparison}</p>
                </div>):(null)
                } */}
            </div>
        </div>
    )
}

