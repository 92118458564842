import React from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { DT_CellFormatterWithComma, DT_CellFormatterWithPerc, DT_CellFormatterWithDollar } from "../../../utils/common-functions";

const pagination_options = {
  // pageStartIndex: 0,
  sizePerPage: 5,
  hideSizePerPage: true,
  hidePageListOnlyOnePage: true,
};
const ExecutiveBrandKpi = (props) => {
  const { data, kpiData, isLoading, isLinkClicked } = props;

  const tbl_headers = [
    {
      dataField: "brand_name",
      text: "Brand",
      footer: "",
    },
    {
      dataField: "ontime_perc_from_ontime_data",
      text: "OT %",
      footer: columnData => DT_CellFormatterWithPerc(columnData.reduce((acc, item) => kpiData[0]?.ontime_perc_from_ontime_data, 0)),
      formatter: DT_CellFormatterWithPerc,
    },
    {
      dataField: "fill_rate_original",
      text: "IF %",
      headerStyle: {},
      footer: columnData => DT_CellFormatterWithPerc(columnData.reduce((acc, item) => kpiData[0]?.fill_rate_original, 0)),
      formatter: DT_CellFormatterWithPerc
    },
    {
      dataField: "otif",
      text: "OTIF %",
      footer: columnData => DT_CellFormatterWithPerc(columnData.reduce((acc, item) => kpiData[0]?.otif, 0)),
      formatter: DT_CellFormatterWithPerc,
    },
    {
      dataField: "total_pos_amount",
      text: "POS $",
      footer: columnData => DT_CellFormatterWithDollar(columnData.reduce((acc, item) => kpiData[0]?.total_pos_amount, 0)),
      formatter: DT_CellFormatterWithDollar,
    },
    {
      dataField: "total_pos_qty",
      text: "POS Eaches",
      footer: columnData => DT_CellFormatterWithComma(columnData.reduce((acc, item) => kpiData[0]?.total_pos_qty, 0)),
      formatter: DT_CellFormatterWithComma,
    },
    {
      dataField: "total_pos_qty_cases",
      text: "POS Cases",
      footer: columnData => DT_CellFormatterWithComma(columnData.reduce((acc, item) => kpiData[0]?.total_pos_qty_cases, 0)),
      formatter: DT_CellFormatterWithComma,
    },
    {
      dataField: "total_pos_qty_pallets",
      text: "POS Pallets",
      footer: columnData => DT_CellFormatterWithComma(columnData.reduce((acc, item) => kpiData[0]?.total_pos_qty_pallets, 0)),
      formatter: DT_CellFormatterWithComma,
    },
    {
      dataField: 'total_inventory_eaches',
      text: 'Inv Eaches',
      formatter: DT_CellFormatterWithComma
    },
    {
      dataField: 'total_inventory_cases',
      text: 'Inv Cases',
      formatter: DT_CellFormatterWithComma
    },
    {
      dataField: 'total_inventory_pallets',
      text: 'Inv Pallets',
      formatter: DT_CellFormatterWithComma
    },
    {
      dataField: 'instock_perc',
      text: 'InStock %',
      formatter: DT_CellFormatterWithPerc
    },
    {
      dataField: "oos_perc",
      text: "OOS %",
      footer: columnData => DT_CellFormatterWithPerc(columnData.reduce((acc, item) => kpiData[0]?.oos_perc, 0)),
      formatter: DT_CellFormatterWithPerc
    },
    {
      dataField: "active_stores",
      text: "Active Stores",
      footer: columnData => DT_CellFormatterWithComma(columnData.reduce((acc, item) => kpiData[0]?.active_stores, 0)),
      formatter: DT_CellFormatterWithComma,
    },
    {
      dataField: "woc",
      text: "WOC",
      footer: columnData => columnData.reduce((acc, item) => kpiData[0]?.woc, 0)
    },
    // {
    //   dataField: 'oos_perc_sku',
    //   text: 'OOS % SKU'
    // }
  ];

  if (isLoading) {
    return <DataLoader fullPage={false} message="Fetching data" />;
  }
  if (data?.length === 0) {
    return <NoDataFound message={"There is no data for selected filters!"} />;
  }
  return (
    <ToolkitProvider keyField="brand_name" data={data} columns={tbl_headers}>
      {(props) => (
        <div>
          <BootstrapTable
            striped
            hover
            condensed
            classes="table-responsive scrolling-lock-table-wrapper"
            {...props.baseProps}
            pagination={(isLinkClicked) ? null : paginationFactory(pagination_options)}
            noDataIndication="There is nos data for selected filters!"
          />
        </div>
      )}
    </ToolkitProvider>
  );
};
export default ExecutiveBrandKpi;
