import { commonTypes } from "../types/commonTypes";
import { analystLandingTypes } from "../types/analystLandingTypes";
import { POST } from '../../utils/axios';
import { getSocketStatus } from './socketActions';

export const setLoad = (isLoadingRequestIds) => {
    return {
        type: commonTypes.SET_LOADING,
        payload: isLoadingRequestIds
    }
}

//Analyst Landing KPI data
export const getAnalystKpiData = ( postData ) => {
    return async (dispatch) => {
        dispatch(setLoad({ "isKpiDataLoaded":true }));
        const analystKpiData = await getAnalystKpiDataReq( postData );
        dispatch(setAnalystKpiData(analystKpiData));
        dispatch(setLoad({ "isKpiDataLoaded":false }));
    }
}
const getAnalystKpiDataReq = async ( postData ) => {
    try {
        const apiRes = await POST(`/stockkpi`, postData);
        if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
            return apiRes.data?.result;
        }else{
            let socketRetData = await getSocketStatus(apiRes.key);
            if (socketRetData.jobState === "COMPLETED") {
                  return await getAnalystKpiDataReq(postData);
            }else if(socketRetData.jobState === "FAILED") {
                  return socketRetData.errorMessage;
            }
          }
        return [];
    } catch (err) {
        console.log("Analyst Stock KPI ERROR ", err);
        return [];
    }
}
export const setAnalystKpiData = (analystKpiData) => {
    return {
        type: analystLandingTypes.SET_ANALYST_KPI,
        payload: analystKpiData
    }
}

//Analyst POS Chart data
export const getAnalystPOSChartData = ( postData ) => {
    return async (dispatch) => {
        dispatch(setLoad({ "isPOSChartDataLoaded": true }));
        const analystPOSChartData = await getAnalystPOSChartDataReq( postData );
        dispatch(setAnalystPOSChartData(analystPOSChartData));
        dispatch(setLoad({ "isPOSChartDataLoaded": false }));
    }
}
const getAnalystPOSChartDataReq = async ( postData ) => {
    try {
        const apiRes = await POST(`/posOrders`, postData);
        if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
            return apiRes.data?.result;
        }else{
            let socketRetData = await getSocketStatus(apiRes.key);
            if (socketRetData.jobState === "COMPLETED") {
                  return await getAnalystPOSChartDataReq(postData);
            }else if(socketRetData.jobState === "FAILED") {
                  return socketRetData.errorMessage;
            }
          }
        return [];
    } catch (err) {
        console.log("Analyst POS ERROR: ", err);
        return [];
    }
}
export const setAnalystPOSChartData = (analystTrendChartData) => {
    return {
        type: analystLandingTypes.SET_ANALYST_POS_CHART,
        payload: analystTrendChartData
    }
}

//Analyst OOS Chart data
export const getAnalystOOSChartData = ( postData ) => {
    return async (dispatch) => {
        dispatch(setLoad({ "isOOSChartDataLoaded": true }));
        const analystOosChartData = await getAnalystOOSChartDataReq( postData );
        dispatch(setAnalystOOSChartData(analystOosChartData));
        dispatch(setLoad({ "isOOSChartDataLoaded": false }));
    }
}
const getAnalystOOSChartDataReq = async ( postData ) => {
    try {
        const apiRes = await POST(`/oosStores`, postData);
        if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
            return apiRes.data?.result;
        }else{
            let socketRetData = await getSocketStatus(apiRes.key);
            if (socketRetData.jobState === "COMPLETED") {
                  return await getAnalystOOSChartDataReq(postData);
            }else if(socketRetData.jobState === "FAILED") {
                  return socketRetData.errorMessage;
            }
          }
        return [];
    } catch (err) {
        console.log("Analyst Out of Stock ERROR: ", err);
        return [];
    }
}
export const setAnalystOOSChartData = (analystOOSChartData) => {
    return {
        type: analystLandingTypes.SET_ANALYST_OOS_CHART,
        payload: analystOOSChartData
    }
}

//Analyst OTIF Chart data
export const getAnalystOTIFChartData = ( postData ) => {
    return async (dispatch) => {
        dispatch(setLoad({ "isOTIFChartDataLoaded": true }));
        const analystOTIFChartData = await getAnalystOTIFChartDataReq( postData );
        dispatch(setAnalystOTIFChartData(analystOTIFChartData));
        dispatch(setLoad({ "isOTIFChartDataLoaded": false }));
    }
}
const getAnalystOTIFChartDataReq = async ( postData ) => {
    try {
        const apiRes = await POST(`/otifOrders`, postData);
        if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
            return apiRes.data?.result;
        }else{
            let socketRetData = await getSocketStatus(apiRes.key);
            if (socketRetData.jobState === "COMPLETED") {
                  return await getAnalystOTIFChartDataReq(postData);
            }else if(socketRetData.jobState === "FAILED") {
                  return socketRetData.errorMessage;
            }
          }
        return [];
    } catch (err) {
        console.log("Analyst Otif Orders ERROR: ", err);
        return [];
    }
}
export const setAnalystOTIFChartData = (analystOTIFChartData) => {
    return {
        type: analystLandingTypes.SET_ANALYST_OTIF_CHART,
        payload: analystOTIFChartData
    }
}

//Analyst Landing Page Aggregation distribution data
export const getAnalystLandingAggregateData = ( postData ) => {
    return async (dispatch) => {
        dispatch(setLoad({"isAnalystAggregateDataLoaded":true}));
        const apiRes = await getAnalystLandingAggregateDataReq(postData);
        dispatch(setAnalystLandingAggregateData(apiRes));
        dispatch(setLoad({ "isAnalystAggregateDataLoaded":false }));
    }
}
const getAnalystLandingAggregateDataReq = async ( postData ) => {
    try {
        const apiRes = await POST(`/aggregatedata`, postData);
        if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
            return apiRes.data?.result;
        }else{
            let socketRetData = await getSocketStatus(apiRes.key);
            if (socketRetData.jobState === "COMPLETED") {
                  return await getAnalystLandingAggregateDataReq(postData);
            }else if(socketRetData.jobState === "FAILED") {
                  return socketRetData.errorMessage;
            }
          }
        return [];
    } catch (err) {
        console.log("Analyst Aggregatedata ERROR: ", err);
        return [];
    }
}
export const setAnalystLandingAggregateData = (analystLandingAggregateData) => {
    return {
        type: analystLandingTypes.SET_ANALYST_LANDING_AGGREGATE,
        payload: analystLandingAggregateData
    }
}

export const setGlobalSearchValues = (searchValues) => {
    return {
        type: analystLandingTypes.SET_GLOBAL_SEARCH_VALUES,
        payload: searchValues
    }
}

export const setDateRangeSearchValues = (dateSearchValues) => {
    return {
        type: analystLandingTypes.SET_DATERANGE_SEARCH_VALUES,
        payload: dateSearchValues
    }
}
