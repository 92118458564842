import React from "react";
// Import styles
import "./NoDataFound.scss";

function NoDataFound( props ) {
    const { message } = props;
    return (
        <div>
            <div className="">
                <h5 className="noDataTxt"> {(message)?message:"No Data Found to display!"} </h5>
            </div>
        </div>
    );
}

export default NoDataFound;