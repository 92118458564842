import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";
import { stateData } from "./stateData";

const initialState = stateData;

//const store = applyMiddleware(logger)(createStore)(rootReducer, initialState);

const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunk)
);

export default store;
