import React from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { DT_CellFormatterWithComma, DT_CellFormatterWithDollar } from "../../../utils/common-functions";

const tbl_headers = [
  {
    dataField: "region",
    text: "Region",
  },
  {
    dataField: "retailer_id",
    text: "Retailer ID",
  },
  {
    dataField: "retailer_outlet_id",
    text: "Retailer Outlet ID",
  },
  {
    dataField: "store_type",
    text: "Store Type",
  },
  {
    dataField: "dc_code",
    text: "DC Code",
  },
  {
    dataField: "alert",
    text: "Alert",
  }
];
const pagination_options = {
  // pageStartIndex: 0,
  sizePerPage: 5,
  hideSizePerPage: true,
  hidePageListOnlyOnePage: true,
};
const MissingStoreMapping = (props) => {
  const { data, isLoading } = props;
  if (isLoading) {
    return <DataLoader fullPage={false} message="Fetching data" />;
  }
  if (data?.length === 0) {
    return <NoDataFound message={"There is no data for selected filters!"} />;
  }
  return (
    <ToolkitProvider keyField="sku" data={data} columns={tbl_headers}>
      {(props) => (
        <div>
          <BootstrapTable
            striped
            hover
            condensed
            classes="table-responsive"
            {...props.baseProps}
            pagination={paginationFactory(pagination_options)}
            noDataIndication="There is nos data for selected filters!"
          />
        </div>
      )}
    </ToolkitProvider>
  );
};
export default MissingStoreMapping;
