import React, { Component } from "react";
import { CSVLink } from "react-csv";

import { connect } from "react-redux";
import { getSegmentKpiDataForExport } from "../../../redux/actions/executivePageActions";

import { getFilterValuesForExport } from './GetFilterForExport';

const headers = [
  {
    key: "segment",
    label: "Segment",
  },
  {
    key: 'brand_name',
    label: 'Brand',
  },
  {
    key: "ontime_perc_from_ontime_data",
    label: "OT %",
  },
  {
    key: "fill_rate_original",
    label: "IF %",
  },
  {
    key: "otif",
    label: "OTIF %",
  },
  {
    key: "total_pos_amount",
    label: "POS $",
  },
  {
    key: "total_pos_qty",
    label: "POS Eaches",
  },
  {
    key: "total_pos_qty_cases",
    label: "POS Cases",
  },
  {
    key: "total_pos_qty_pallets",
    label: "POS Pallets",
  },
  {
    key: "oos_perc",
    label: "OOS %",
  },
  {
    key: "active_stores",
    label: "Active Stores",
  },
  {
    key: "woc",
    label: "WOC",
  },
  // {
  //   key: 'oos_perc_sku',
  //   label: 'OOS % SKU'
  // }
];

class ExportSegmentCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      IsDataLoading: false,
    };
    this.csvLinkEl = React.createRef();
  }

  fetchDataFromServer = async () => {
    var filterItems = getFilterValuesForExport(this.props.executivePageObj);
    const apiRes = await getSegmentKpiDataForExport(filterItems);
    return apiRes;
  };

  downloadReport = async () => {
    if (!this.state.IsDataLoading) {
      this.setState({ IsDataLoading: true });
      const data = await this.fetchDataFromServer();
      this.setState({ data: data, IsDataLoading: false }, () => {
        setTimeout(() => {
          this.csvLinkEl.current.link.click();
        });
      });
    }
  };

  render() {
    const { data } = this.state;
    return (
      <div>
        <input
          type="button"
          value={
            this.state.IsDataLoading ? "Loading csv..." : "Export All Data"
          }
          className="btn btn-gradient-info label-right font-weight-bold px-lg-4 px-3"
          onClick={this.downloadReport}
        />
        <CSVLink
          headers={headers}
          filename={"ExportedSegmentData.csv"}
          asyncOnClick={true}
          data={data}
          ref={this.csvLinkEl}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    executivePageObj: state.executivePageReducer,
    //inStockDataObj: state.instockReducer
  };
}
const mapDispatchToProps = { getSegmentKpiDataForExport };
export default connect(mapStateToProps, mapDispatchToProps)(ExportSegmentCSV);
