import React, { } from 'react';
import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { dateFormatter, DT_CellFormatterWithComma, DT_CellFormatterWithPerc, DT_CellFormatterWithDollar } from "../../../utils/common-functions";
import { useDispatch, useSelector } from "react-redux";
import _  from 'lodash';

import '../Cockpit.scss';
const AnalystAggregate = (props) => { 

  const customerFilter = useSelector(
    ({ masterReducer }) => masterReducer.defaultSearchValues
  );
  const retailer = customerFilter.customer
  const { data, isLoading } = props;
  const tblFirstColumn = ['POS Eaches','POS Cases','POS Pallets','Orders', 'Total Inventory', 'On time orders Actual %', 'In Full orders Actual %', 'OTIF Actual %', 'OOS Stores','Instock Stores'];
    
  //const retailerOriginalUnitCases = ["KROGER.COM", "Lowes.Com", "CVS_US", "RITE AID","Safeway","BJs","Amazon_Geo","Family Dollar","Walgreens","Sams Club","Lowes","HomeDepot","Food Lion","CostCo_US","Dollar General","Walgreens.com","CVS","Kroger"]
  const retailerOriginalUnitEaches = ['WALMART','TARGET','WALGREENS'];  
  
  // If retailer exixts in retailerOriginalUnitEaches array flip the Aggregate KPI title as POS eaches (Original Units) else POS cases (Original Units)
  retailerOriginalUnitEaches.indexOf(retailer) >=0 ? tblFirstColumn[0] = tblFirstColumn[0]+" (Original Units)" : tblFirstColumn[1] = tblFirstColumn[1]+" (Original Units)" 

  if (isLoading) {
    return (
      <DataLoader
        fullPage={false}
        message="Fetching data"
      />
    );
  }
  if (Object.keys(data).length === 0) {
    return <NoDataFound message={"There is no data for selected filters!"} />;
  }
  return (
    <table className="table table-bordered table-hover">
      <thead>
        <tr>
          <th>KPI</th>
          {data.tbl_head.map((obj, index) => {
            return (<th key={index}>{dateFormatter(obj, 'MM/DD/YYYY')}</th>)
          })}
        </tr>
      </thead>
      <tbody>
        {data.tbl_data.map((obj, index) => {
          return (
            <tr key={index}>
              <td key={index}>
                {tblFirstColumn[index]}
              </td>
              {obj.map((sub_obj,sub_index) => 
                (
                  // <td key={sub_index}>{(index===0 || index===1 || index===2 || index===6)?DT_CellFormatterWithComma(sub_obj):DT_CellFormatterWithPerc(sub_obj)}</td>
                  <td key={sub_index}>{(index===0 || index===1 || index===2 || index===3|| index===4 ||index===8 || index==9 )?DT_CellFormatterWithComma(sub_obj):DT_CellFormatterWithPerc(sub_obj)}</td>
                )
              )}
            </tr>
          )
        })}


      </tbody>
    </table>
  );

}
export default AnalystAggregate;