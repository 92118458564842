export const masterTypes = {
  SET_REGION: "SET_REGION",
  SET_CUSTOMER: "SET_CUSTOMER",
  SET_REGION_MULTI_LIST: "SET_REGION_MULTI_LIST",
  SET_CUSTOMER_MULTI_LIST: "SET_CUSTOMER_MULTI_LIST",
  SET_DC: "SET_DC",
  SET_ITEM: "SET_ITEM",
  SET_STORE: "SET_STORE",
  SET_SEGMENT: "SET_SEGMENT",
  SET_SKU: "SET_SKU",
  SET_BRAND: "SET_BRAND",
  SET_BU: "SET_BU",
  SET_DEFAULT_REGION: "SET_DEFAULT_REGION",
  SET_DEFAULT_CUSTOMER: "SET_DEFAULT_CUSTOMER",
  SET_DEFAULT_DC: "SET_DEFAULT_DC",
  SET_DEFAULT_ITEM: "SET_DEFAULT_ITEM",
  SET_DEFAULT_STORE: "SET_DEFAULT_STORE",
  SET_DEFAULT_SEGMENT: "SET_DEFAULT_SEGMENT",
  SET_DEFAULT_SKU: "SET_DEFAULT_SKU",
  SET_DEFAULT_BRAND: "SET_DEFAULT_BRAND",
  SET_DEFAULT_BU: "SET_DEFAULT_BU",
  SET_DEFAULT_SEARCH_VALUES: "SET_DEFAULT_SEARCH_VALUES",
  SET_DEFAULT_CUSTOMER_MULTI_LIST: "SET_DEFAULT_CUSTOMER_MULTI_LIST",
  SET_DEFAULT_REGION_MULTI_LIST: "SET_DEFAULT_REGION_MULTI_LIST",
};
