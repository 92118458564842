import { commonTypes } from "../types/commonTypes";
import { masterTypes } from "../types/masterTypes";

export default function (state = {}, action) {
  switch (action.type) {
    case commonTypes.SET_LOADING:
      var key = Object.keys(action.payload)[0];
      var value = action.payload[Object.keys(action.payload)[0]];
      return {
        ...state,
        isLoadingRequestIds: value
          ? [...state.isLoadingRequestIds, key]
          : removeItemOnce(state.isLoadingRequestIds, key), //state.isLoadingRequestIds.splice(state.isLoadingRequestIds.indexOf(key)).slice()
      };
    case masterTypes.SET_REGION:
      return {
        ...state,
        regionList: action.payload,
      };
      case masterTypes.SET_REGION_MULTI_LIST:
      return {
        ...state,
        regionMultiList: action.payload,
      };
    case masterTypes.SET_SEGMENT:
      return {
        ...state,
        segmentList: action.payload,
      };
    case masterTypes.SET_CUSTOMER:
      return {
        ...state,
        customerList: action.payload,
      };
      case masterTypes.SET_CUSTOMER_MULTI_LIST:
        return {
          ...state,
          customerMultiList: action.payload,
        };
    case masterTypes.SET_DC:
      return {
        ...state,
        dcList: action.payload,
      };
    case masterTypes.SET_ITEM:
      return {
        ...state,
        itemList: action.payload,
      };
    case masterTypes.SET_STORE:
      return {
        ...state,
        storeList: action.payload,
      };
    case masterTypes.SET_SKU:
      return {
        ...state,
        skuList: action.payload,
      };
    case masterTypes.SET_BRAND:
      return {
        ...state,
        brandList: action.payload,
      };
    case masterTypes.SET_BU:
      return {
        ...state,
        buList: action.payload,
      };

    case masterTypes.SET_DEFAULT_REGION:
      return {
        ...state,
        regionDefaultList: action.payload,
      };
      case masterTypes.SET_DEFAULT_REGION_MULTI_LIST:
        return {
          ...state,
          regionDefaultMultiList: action.payload,
      };
    case masterTypes.SET_DEFAULT_CUSTOMER:
      return {
        ...state,
        customerDefaultList: action.payload,
      };
    //19-Jan-2023 for alerts page
    case masterTypes.SET_DEFAULT_CUSTOMER_MULTI_LIST:
      return {
        ...state,
        customerDefaultMultiList: action.payload,
    };   
    case masterTypes.SET_DEFAULT_DC:
      return {
        ...state,
        dcDefaultList: action.payload,
      };
    case masterTypes.SET_DEFAULT_BU:
      return {
        ...state,
        buDefaultList: action.payload,
      };
    case masterTypes.SET_DEFAULT_BRAND:
      return {
        ...state,
        brandDefaultList: action.payload,
      };
    case masterTypes.SET_DEFAULT_SKU:
      return {
        ...state,
        skuDefaultList: action.payload,
      };
    case masterTypes.SET_DEFAULT_SEARCH_VALUES:
      var key = Object.keys(action.payload)[0];
      var value = action.payload[Object.keys(action.payload)[0]];
      const newArray = { ...state.defaultSearchValues };
      newArray[key] = value;
      return {
        ...state,
        defaultSearchValues: newArray,
      };
      
      

    default:
      return state;
  }
}

function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}
