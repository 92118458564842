import { userManagementTypes } from "../types/userManagementTypes";
import { GET, POST, PUT, DELETE } from '../../utils/axios';

//Dummy Data from json file
import userListData from "../../../assets/data/usermanagement/userList.json";

const setLoad = ( isLoadingRequestIds ) => {
    return {
        type: userManagementTypes.SET_LOADING,
        payload: isLoadingRequestIds
    }
}


//User lists
export const getUserList = () => {
  return async ( dispatch ) => {
      dispatch(setLoad(true)); 
      const apiRes = await getUserListReq(); 
      dispatch(setUserList(apiRes)); 
      dispatch(setLoad(false)); 
  }
}
const getUserListReq = async () => {
  try {
      //const apiRes = await GET(`/users`);
      const apiRes = userListData;
      if( apiRes.status.statusCode === "200" ){
          return apiRes.data;
      }
      return [];
  } catch (err) {
      return [];
  }
}
export const setUserList = ( data ) => {
  return {
      type: userManagementTypes.SET_LIST_USER,
      payload: data
  }
}


// ADD User
export const addUser = (postData) => {
    return async (dispatch) => {
      dispatch(setLoad({ isUserDataLoaded: true }));
      const userData = await addUserDataReq(postData);
      dispatch(setUserData(userData));
      dispatch(setLoad({ isUserDataLoaded: false }));
    };
  };
  const addUserDataReq = async (postData) => {
    try {
      const userData = await POST(`/user`, postData);      
      if (userData.status.statusCode === "200") {
        const msg="User added successfully";
        return msg;
        //return userData.data;
      }
      return [];
    } catch (err) {
      return [];
    }
  };
  export const setUserData = (addUserData) => {
    return {
      type: userManagementTypes.SET_ADD_USER,
      payload: addUserData,
    };
  };

  





//Delete User
export const deleteUserData = (id) => {
  return async ( dispatch ) => {
      dispatch(setLoad( { "isUserDataDeleted" : true } )); 
      const deleteUser = await deleteUserReq(id); 
      dispatch(deleteUser(deleteUser)); 
      dispatch(setLoad({ "isUserDataDeleted" : false })); 
  }
}
const deleteUserReq = async (id) => {
  try {
      const apiResult = await DELETE(`/users/${id}`);
      if( apiResult.status.statusCode === "200" ){
        const msg ="User deleted successfully";
          return msg;
          //return apiResult.data;
      }
      return [];
  } catch (err) {
      return [];
  }
}
export const deleteUser = ( deleteUser ) => {
  return {
      type: userManagementTypes.SET_DELETE_USER,
      payload: deleteUser
  }
}



//Update User
export const updateUserData = (id) => {
  return async ( dispatch ) => {
      dispatch(setLoad( { "isUserDataUpdated" : true } )); 
      const updateUser = await updateUserReq(id); 
      dispatch(setUpdateUser(updateUser)); 
      dispatch(setLoad({ "isUserDataUpdated" : false })); 
  }
}
const updateUserReq = async (id) => {
  try {
      const apiResult = await PUT(`/users/${id}`);
      if( apiResult.status.statusCode === "200" ){
          const msg ="User updated successfully";
          return msg;
          //return apiResult.data;
      }
      return [];
  } catch (err) {
      return [];
  }
}
export const setUpdateUser = ( updateUser ) => {
  return {
      type: userManagementTypes.SET_UPDATE_USER,
      payload: updateUser
  }
}
