import React, { } from 'react';

import { AreaLineChart as LineChart } from "../../../components/Charts/index";
import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { dateFormatter } from "../../../utils/common-functions";

const SkuLevelTrend = (props) => {
  const { data, isLoading } = props;
  if (isLoading) {
    return (
      <DataLoader
        fullPage={false}
        message="Fetching data"
      />
    );
  }
  if (data?.length === 0) {
    return <NoDataFound message={"There is no data for selected filters!"} />;
  }

  const changeLabelDateFormat = [];
  if (data.x_label.length > 0) {
    for (var index = 0; index < data.x_label.length; index++) {
      const element = dateFormatter(data.x_label[index], 'MM/DD/YYYY');
      changeLabelDateFormat.push(element);
    }
  }

  return (
    <LineChart
      chartLabels={changeLabelDateFormat}
      chartDataSet={
        [
          {
            type:'bar',
            //label: 'WOC (POS 4W AVG)',
            label: 'POS Last week',
            data: data.y_weeks_of_coverage,
            borderColor: 'rgba(31, 124, 17)',
            backgroundColor: 'rgba(31, 124, 17, 0.56)',
            yAxisID: 'y1',
            borderWidth: 1,
            fill: false,
          },
          {
            label: 'In Stock %',
            data: data.y_in_stock,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            yAxisID: 'y',
            borderWidth: 1,
            fill: false,
          },
          {
            label: 'In Full %',
            data: data.y_full_perc,
            backgroundColor: [ 'rgba(255, 99, 132, 0.5)' ],
            borderColor: [ 'rgb(255,99,132)'],
            yAxisID: 'y',
            borderWidth: 1,
            fill: false,
          },
        ]
      }
    />
  );
}
export default SkuLevelTrend;