import React, { Component } from 'react';
import { connect } from "react-redux";

// Import config
import { config } from "../../config/config";
import { getCurrentYear } from "../../utils/utils";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between py-2">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © <a href="#" target="_blank" rel="noopener noreferrer">{config.footerText} </a>{getCurrentYear()}</span>
          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">{config.footerLink} </span>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps)(Footer);