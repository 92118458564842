import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import sidenavData from "../../../assets/sidenav/sidenavData.json";

class Sidebar extends Component {

    state = {};

    toggleMenuState(menuState) {
        if (this.state[menuState]) {
            this.setState({ [menuState]: false });
        } else if (Object.keys(this.state).length === 0) {
            this.setState({ [menuState]: true });
        } else {
            Object.keys(this.state).forEach(i => {
                this.setState({ [i]: false });
            });
            this.setState({ [menuState]: true });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        document.querySelector('#sidebar').classList.remove('active');
        Object.keys(this.state).forEach(i => {
            this.setState({ [i]: false });
        });
    }

    render() {
        return (
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="nav">
                    {sidenavData.map((obj, index) => (
                        <li className={this.isPathActive(`/home/${obj.id}`) ? 'nav-item active' : 'nav-item'} key={index} >
                            <Link className="nav-link" to={`/home/${obj.id}`}>
                                <span className="menu-title"><Trans>{obj.name}</Trans></span>
                                <i className={`${obj.materialUiClassName}`}></i>
                            </Link>
                        </li>
                    ))}
                    {/* <li className={this.isPathActive('/general-pages') ? 'nav-item active' : 'nav-item'}>
                        <div className={this.state.generalPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('generalPagesMenuOpen')} data-toggle="collapse">
                            <span className="menu-title"><Trans>General Pages</Trans></span>
                            <i className="menu-arrow"></i>
                            <i className="mdi mdi-medical-bag menu-icon"></i>
                        </div>
                        <Collapse in={this.state.generalPagesMenuOpen}>
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className={this.isPathActive('/general-pages/blank-page') ? 'nav-link active' : 'nav-link'} to="/general-pages/blank-page"><Trans>Blank Page</Trans></Link></li>
                            </ul>
                        </Collapse>
                    </li> */}
                </ul>
            </nav>
        );
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }

    componentDidMount() {
        this.onRouteChanged();
        // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
        const body = document.querySelector('body');
        document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

            el.addEventListener('mouseover', function () {
                if (body.classList.contains('sidebar-icon-only')) {
                    el.classList.add('hover-open');
                }
            });
            el.addEventListener('mouseout', function () {
                if (body.classList.contains('sidebar-icon-only')) {
                    el.classList.remove('hover-open');
                }
            });
        });
    }

}

export default withRouter(Sidebar);