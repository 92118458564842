// Import required libraries
import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

// Import utils
import { config } from "../../app/config/config";

//Import custom components
import Loader from "../components/Loader/Loader";

import LayoutTopSideBottom from "../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import PageHeader from "../components/PageHeader/PageHeader";

function ProtectedRoute(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const { path, component: Component } = props;

  return user && user.isAuth ? (
    <LayoutTopSideBottom match={props.computedMatch}>
      <PageHeader match={props.computedMatch} pageTitle={props.pageTitle} />
      <Route
        path={path}
        render={(ownProps) => (
          <Component
            user={props.user}
            location={props.location}
            match={ownProps.match}
          />
        )}
      />
    </LayoutTopSideBottom>
  ) : (
    <Redirect to="/unauthorised" />

  );
}

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};


export default ProtectedRoute;
