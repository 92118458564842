/*
VeriticleBarChart is used to display Vertical bar graphs

This component can handle below props:
- chartLabels
- chartDataSet
- chartHeading

Currently this component got imported in below pages:
/src/js/screens/Otif/Components/OnTimeBarChart.js
/src/js/screens/Otif/Components/InFullBarChart.js
/src/js/screens/Forecast.js
*/


import React, { Component } from 'react'
// Import chart Js
import {
    Chart as Chartjs,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';

  Chartjs.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );

export default function VeriticleBarChart ( props ) {
    const { chartHeading, chartDataSet, chartLabels } = props;
    const barChartOptions = {
        barThickness:(chartLabels.length > 10 ) ? 20: 20,
        plugins: {
          title: {
            display: false,
            text: chartHeading,
          },
        },
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      
      };
    
    const  barChartData = {
        labels: chartLabels, //['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: chartDataSet,
      };

    return (
        <Bar data={barChartData} options={barChartOptions} height="190" />
    )
}