import React, { Component } from "react";
import { CSVLink } from "react-csv";

import { connect } from "react-redux";
import { getMissingStoreMapExportData } from "../../../redux/actions/alertActions";

import { getFilterValuesForExport } from './GetFilterForExport';

const headers = [
  {
    key: "region",
    label: "Region",
  },
  {
    key: "retailer_id",
    label: "Retailer ID",
  },
  {
    key: "retailer_outlet_id",
    label: "Retailer Outlet ID",
  },
  {
    key: "store_type",
    label: "Store Type",
  },
  {
    key: "dc_code",
    label: "DC Code",
  },
  {
    key: "alert",
    label: "Alert",
  },
];

class ExportMissingStoreMapCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      IsDataLoading: false,
    };
    this.csvLinkEl = React.createRef();
  }

  fetchDataFromServer = async () => {
    var filterItems = getFilterValuesForExport(this.props.alertsDataObj);
    const apiRes = await getMissingStoreMapExportData(filterItems);
    return apiRes;
  };

  downloadReport = async () => {
    if (!this.state.IsDataLoading) {
      this.setState({ IsDataLoading: true });
      const data = await this.fetchDataFromServer();
      this.setState({ data: data, IsDataLoading: false }, () => {
        setTimeout(() => {
          this.csvLinkEl.current.link.click();
        });
      });
    }
  };


  render() {
    const { data } = this.state;
    return (
      <div>
        <input
          type="button"
          value={
            this.state.IsDataLoading ? "Loading csv..." : "Export All Data"
          }
          className="btn btn-gradient-info label-right font-weight-bold px-lg-4 px-3"
          onClick={this.downloadReport}
        />
        <CSVLink
          headers={headers}
          filename={"ExportedMissingDCStoreMapping.csv"}
          asyncOnClick={true}
          data={data}
          ref={this.csvLinkEl}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alertsDataObj: state.alertReducer,
  };
}
const mapDispatchToProps = { getMissingStoreMapExportData };
export default connect(mapStateToProps, mapDispatchToProps)(ExportMissingStoreMapCSV);