import { commonTypes } from "../types/commonTypes";
import { otifTypes } from "../types/otifTypes";
import { POST } from '../../utils/axios';
import { getSocketStatus } from './socketActions';
// Import data & utils
// import otifKpiOnstockTableData from "../../../assets/data/otifKPIOntimeTable.json";
// import otifKPIInfullTableData from "../../../assets/data/otifKPIInfullTable.json";
// import ontimeBarChartData from "../../../assets/data/otifOntimeBarChart.json";
// import infullBarChartData from "../../../assets/data/otifInfullBarChart.json";

const setLoad = (isLoadingRequestIds) => {
    return {
        type: commonTypes.SET_LOADING,
        payload: isLoadingRequestIds
    }
}

export const setGlobalSearchValues = (otifGlobalSearchValues) => {
    return {
        type: otifTypes.SET_GLOBAL_SEARCH_VALUES,
        payload: otifGlobalSearchValues
    }
}

//Get on time accountability data
export const getOnTimeAccountabilityData = (postData) => {
    return async (dispatch) => {
        dispatch(setLoad({ "isOnTimeAccountabilityDataLoaded": true }));
        const apiRes = await getOnTimeAccountabilityDataReq(postData);
        dispatch(setOnTimeAccountabilityData(apiRes));
        dispatch(setLoad({ "isOnTimeAccountabilityDataLoaded": false }));
    }
}
const getOnTimeAccountabilityDataReq = async (postData) => {
    try {
        const apiRes = await POST(`/ontimeAccountability`, postData);
        if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
            return apiRes.data?.result;
        } else {
            let socketRetData = await getSocketStatus(apiRes.key);
            if (socketRetData.jobState === "COMPLETED") {
                return await getOnTimeAccountabilityDataReq(postData);
            }
        }
        return [];
    } catch (err) {
        console.log("ontimeAccountability ERROR: ", err);
        return [];
    }
}

export const setOnTimeAccountabilityData = (ontimeaccountability) => {
    return {
        type: otifTypes.SET_ON_TIME_ACCOUNTABILITY,
        payload: ontimeaccountability,
    }
}

//Get on time Statistics data
export const getOnTimeStatsData = (postData) => {
    return async (dispatch) => {
        dispatch(setLoad({ "isOnTimeStatsDataLoaded": true }));
        const apiRes = await getOnTimeStatsDataReq(postData);
        dispatch(setOnTimeStatsData(apiRes));
        dispatch(setLoad({ "isOnTimeStatsDataLoaded": false }));
    }
}
const getOnTimeStatsDataReq = async (postData) => {
    try {
        const apiRes = await POST(`/ontimeStatistics`, postData);
        if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
            return apiRes.data?.result;
        } else {
            //subscribeToEvent(apiRes.key);
            let socketRetData = await getSocketStatus(apiRes.key);
            if (socketRetData.jobState === "COMPLETED") {
                return await getOnTimeStatsDataReq(postData);
            }
        }
        return [];
    } catch (err) {
        console.log("Otif Ontime Statistics ERROR: ", err);
        return [];
    }
}
export const setOnTimeStatsData = (onTimeStats) => {
    return {
        type: otifTypes.SET_ON_TIME_STATS,
        payload: onTimeStats
    }
}

//Get In Full Accountability data
export const getInFullAccountabilityData = (postData) => {
    return async (dispatch) => {
        dispatch(setLoad({ "isInFullAccountabilityDataLoaded": true }));
        const apiRes = await getInFullAccountabilityDataReq(postData);
        dispatch(setInFullAccountabilityData(apiRes));
        dispatch(setLoad({ "isInFullAccountabilityDataLoaded": false }));
    }
}
const getInFullAccountabilityDataReq = async (postData) => {
    try {
        const apiRes = await POST(`/infullAccountability`, postData);
        if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
            return apiRes.data?.result;
        } else {
            //subscribeToEvent(apiRes.key);
            let socketRetData = await getSocketStatus(apiRes.key);
            if (socketRetData.jobState === "COMPLETED") {
                return await getInFullAccountabilityDataReq(postData);
            }
        }
        return [];
    } catch (err) {
        console.log("InFull Accountability ERROR: ", err);
        return [];
    }
}
export const setInFullAccountabilityData = (infullaccountability) => {
    return {
        type: otifTypes.SET_IN_FULL_ACCOUNTABILITY,
        payload: infullaccountability
    }
}

//Get In Full Statistics data
export const getInFullStatsData = (postData) => {
    return async (dispatch) => {
        dispatch(setLoad({ "isInFullStatsDataLoaded": true }));
        const apiRes = await getInFullStatsDataReq(postData);
        dispatch(setInFullStatsData(apiRes));
        dispatch(setLoad({ "isInFullStatsDataLoaded": false }));
    }
}
const getInFullStatsDataReq = async (postData) => {
    try {
        const apiRes = await POST(`/infullStatistics`, postData);
        if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
            return apiRes.data?.result;
        } else {
            //subscribeToEvent(apiRes.key);
            let socketRetData = await getSocketStatus(apiRes.key);
            if (socketRetData.jobState === "COMPLETED") {
                return await getInFullStatsDataReq(postData);
            }
        }
        return [];
    } catch (err) {
        console.log("Infull Statistics ERROR: ", err);
        return [];
    }
}
export const setInFullStatsData = (inFullStats) => {
    return {
        type: otifTypes.SET_IN_FULL_STATS,
        payload: inFullStats
    }
}

//OTIF Chart data
export const getOTIFChartData = (postData) => {
    return async (dispatch) => {
        dispatch(setLoad({ "isOTIFChartDataLoaded": true }));
        const analystOTIFChartData = await getOTIFChartDataReq(postData);
        dispatch(setOTIFChartData(analystOTIFChartData));
        dispatch(setLoad({ "isOTIFChartDataLoaded": false }));
    }
}
const getOTIFChartDataReq = async (postData) => {
    try {
        const apiRes = await POST(`/otifTrends`, postData);
        if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
            return apiRes.data?.result;
        } else {
            //subscribeToEvent(apiRes.key);
            let socketRetData = await getSocketStatus(apiRes.key);
            if (socketRetData.jobState === "COMPLETED") {
                return await getOTIFChartDataReq(postData);
            }
        }
        return [];
    } catch (err) {
        console.log("Otif Chart Data ERROR: ", err);
        return [];
    }
}
export const setOTIFChartData = (OTIFChartData) => {
    return {
        type: otifTypes.SET_OTIF_CHART_DATA,
        payload: OTIFChartData
    }
}

//Otif Ontime BarChart  graph data
export const getOntimeBarChartData = (postData) => {
    return async (dispatch) => {
        dispatch(setLoad({ "isOntimeBarChartLoading": true }));
        const apiRes = await getOntimeBarChartDataReq(postData);
        dispatch(setOntimeBarChartData(apiRes));
        dispatch(setLoad({ "isOntimeBarChartLoading": false }));
    }
}
const getOntimeBarChartDataReq = async (postData) => {
    try {
        const apiRes = await POST(`/ontimecategorywise`, postData);
        if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
            return apiRes.data?.result;
        } else {
            //subscribeToEvent(apiRes.key);
            let socketRetData = await getSocketStatus(apiRes.key);
            if (socketRetData.jobState === "COMPLETED") {
                return await getOntimeBarChartDataReq(postData);
            }
        }
        return [];
    } catch (err) {
        console.log("Otif Ontime Categorywise ERROR: ", err);
        return [];
    }
}
export const setOntimeBarChartData = (otifOntimeBarChartData) => {
    return {
        type: otifTypes.SET_OTIF_ONTIME_BAR_CHART,
        payload: otifOntimeBarChartData
    }
}

//Otif Infull BarChart  graph data
export const getInfullBarChartData = (postData) => {
    return async (dispatch) => {
        dispatch(setLoad({ "isInfullBarChartLoading": true }));
        const apiRes = await getInfullBarChartDataReq(postData);
        dispatch(setInfullBarChartData(apiRes));
        dispatch(setLoad({ "isInfullBarChartLoading": false }));
    }
}
const getInfullBarChartDataReq = async (postData) => {
    try {
        const apiRes = await POST(`/infullcategorywise`, postData);
        if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
            return apiRes.data?.result;
        } else {
            //subscribeToEvent(apiRes.key);
            let socketRetData = await getSocketStatus(apiRes.key);
            if (socketRetData.jobState === "COMPLETED") {
                return await getInfullBarChartDataReq(postData);
            }
        }
        return [];
    } catch (err) {
        console.log("Otif Infull Categorywise ERROR: ", err);
        return [];
    }
}
export const setInfullBarChartData = (otifInfullBarChartData) => {
    return {
        type: otifTypes.SET_OTIF_INFULL_BAR_CHART,
        payload: otifInfullBarChartData
    }
}

export const setDateRangeSearchValues = (otifDateRangeSearchValues) => {
    return {
        type: otifTypes.SET_DATERANGE_SEARCH_VALUES,
        payload: otifDateRangeSearchValues
    }
}
