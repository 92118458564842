import { userManagementTypes } from "../types/userManagementTypes";

export default function (state = {}, action) { 
  switch (action.type) {
    case userManagementTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
    };
      case userManagementTypes.SET_LIST_USER:
      return {
        ...state,
        userList: action.payload,
      }
    case userManagementTypes.SET_ADD_USER:
      return {
        ...state,
        addUserData: action.payload,
      }    
    case userManagementTypes.SET_UPDATE_USER:
      return {
        ...state,
        updateUser: action.payload,
      }
    case userManagementTypes.SET_DELETE_USER:
      return {
        ...state,
        deleteUser: action.payload,
      }
    default:
      return state;
  }
}
