import React, { } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import DataLoader from "../../../components/Loader/DataLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { DT_CellFormatterWithComma, DT_CellFormatterWithPerc, DT_CellFormatterWithDollar } from '../../../utils/common-functions';

const tbl_headers = [
  {
    dataField: 'customer_name',
    text: 'Customer',
  },
  {
    dataField: 'ontime_perc_from_ontime_data',
    text: 'OT %',
    formatter: DT_CellFormatterWithPerc,
  },
  {
    dataField: 'fill_rate_original',
    text: 'IF %',
    headerStyle: {
    },
    formatter: DT_CellFormatterWithPerc
  },
  {
    dataField: 'otif',
    text: 'OTIF %',
    formatter: DT_CellFormatterWithPerc,
  },
  {
    dataField: 'total_pos_amount',
    text: 'POS $',
    formatter: DT_CellFormatterWithDollar,
  },
  {
    dataField: 'total_pos_qty',
    text: 'POS Eaches',
    formatter: DT_CellFormatterWithComma
  },
  {
    dataField: 'total_pos_qty_cases',
    text: 'POS Cases',
    formatter: DT_CellFormatterWithComma
  },
  {
    dataField: 'total_pos_qty_pallets',
    text: 'POS Pallets',
    formatter: DT_CellFormatterWithComma
  },
  {
    dataField: 'total_inventory_eaches',
    text: 'Inv Eaches',
    formatter: DT_CellFormatterWithComma
  },
  {
    dataField: 'total_inventory_cases',
    text: 'Inv Cases',
    formatter: DT_CellFormatterWithComma
  },
  {
    dataField: 'total_inventory_pallets',
    text: 'Inv Pallets',
    formatter: DT_CellFormatterWithComma
  },
  {
    dataField: 'instock_perc',
    text: 'InStock %',
    formatter: DT_CellFormatterWithPerc
  },
  {
    dataField: 'oos_perc',
    text: 'OOS %',
    formatter: DT_CellFormatterWithPerc
  },
  {
    dataField: 'active_stores',
    text: 'Active Stores',
    formatter: DT_CellFormatterWithComma
  },
  {
    dataField: 'woc',
    text: 'WOC'
  }
  // {
  //   dataField: 'oos_perc_sku',
  //   text: 'OOS % SKU'
  // }
];
const pagination_options = {
  // pageStartIndex: 0,
  //sizePerPage: 5,
  hideSizePerPage: true,
  hidePageListOnlyOnePage: true
};
const ExecutiveCustomerKpi = (props) => {
  const { data, isLoading, isLinkClicked } = props;
  if (isLoading) {
    return (
      <DataLoader
        fullPage={false}
        message="Fetching data"
      />
    );
  }
  if (data?.length === 0) {
    return <NoDataFound message={"There is no data for selected filters!"} />;
  }
  return (
    <div>
      <ToolkitProvider
        keyField="customer_name"
        data={data}
        columns={tbl_headers}
      >
        {
          props => (
            <div>
              <BootstrapTable
                striped
                hover
                condensed
                classes='table-responsive scrolling-lock-table-wrapper'
                {...props.baseProps}
                pagination={(isLinkClicked) ? null : paginationFactory(pagination_options)}
                noDataIndication="There is nos data for selected filters!" />
            </div>
          )
        }
      </ToolkitProvider>
    </div>

  );
}
export default ExecutiveCustomerKpi;