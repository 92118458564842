import { userRoleTypes } from "../types/userRoleTypes";

export default function (state = {}, action) {
    switch (action.type) {
        case userRoleTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case userRoleTypes.SET_ROLE_DATA:
            return {
                ...state,
                userRoleList: action.payload,
            };
        case userRoleTypes.SET_MENU_ITEMS:
            return {
                ...state,
                menuItems: action.payload,
            };
        default:
            return state;
    }
}