// Import Required Libraries
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Import Pages
import Register from "../screens/Auth/Register";
import Login from "../screens/Auth/Login";
import Unauthorised from "../screens/Unauthorised/Unauthorised";
import PageNotFound from "../screens/PageNotFound/PageNotFound";
import AIDEApp from "../screens/AIDEApp/AIDEApp";
import Forecast from "../screens/Forecast/Forecast";
import Instock from "../screens/InStock/InStock";
import Otif from "../screens/Otif/Otif";
import Cockpit from "../screens/Cockpit/Cockpit";
import Alerts from "../screens/Alerts/Alerts";
import UserRole from "../screens/UserRole";
import AddRole from "../screens/UserRole/AddRole";
import UserManagement from "../screens/UserManagement";
import AddUser from "../screens/UserManagement/AddUser";
import EditUser from "../screens/UserManagement/EditUser";

// Import Custom Components
import ProtectedRoute from "./ProtectedRoute";

function Routes() {
  return (
    <Switch>
      <Redirect from="/" to="/home" exact />
      <ProtectedRoute path="/home" component={AIDEApp} exact />
      {/* <ProtectedRoute path="/home/:tab" component={AIDEApp} exact /> */}
      <ProtectedRoute
        path="/home/forecast"
        component={Forecast}
        pageTitle="Forecast Analysis"
        exact
      />
      <ProtectedRoute
        path="/home/Instock"
        component={Instock}
        pageTitle="Stock Analysis"
        exact
      />
      <ProtectedRoute
        path="/home/Otif"
        component={Otif}
        pageTitle="OTIF Analysis"
        exact
      />
      <ProtectedRoute
        path="/home/Cockpit"
        component={Cockpit}
        pageTitle="Cockpit"
        exact
      />
      <ProtectedRoute
        path="/home/Alerts"
        component={Alerts}
        pageTitle="Errors and Alerts"
        exact
      />
      <ProtectedRoute
        path="/home/user-role"
        component={UserRole}
        pageTitle="Manage User Roles"
        exact
      />
      <ProtectedRoute
        path="/home/add-role"
        component={AddRole}
        pageTitle="Add User Roles"
        exact
      />
      <ProtectedRoute
        path="/home/user-management"
        component={UserManagement}
        pageTitle="Manage Users"
        exact
      />
      <ProtectedRoute
        path="/home/add-user"
        component={AddUser}
        pageTitle="Manage Users"
        exact
      />
      <ProtectedRoute
        path="/home/edit-user"
        component={EditUser}
        pageTitle="Manage Users"
        exact
      />
      <Route path="/register" component={Register} exact />
      <Route path="/login" component={Login} exact />
      <Route path="/unauthorised" component={Unauthorised} exact />
      <Route path="/home/add-user" component={AddUser} exact />
      <Route path="/home/edit-user" component={EditUser} exact />
      <Route component={PageNotFound} />
    </Switch>
  );
}

export default Routes;
