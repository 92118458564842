import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setDateRangeSearchValues, getSalesBySkuData, getSalesByDcData, getSalesByStoreData } from '../../../redux/actions/instockActions';
import { cleanArrObject, todayDate, getLastnthWeeksDate } from '../../../utils/common-functions';

export default function InventoryFilters(props) {
    //const { filterTitle } = props;
    const [fromDate, setfromDate] = useState(null);
    const [toDate, settoDate] = useState(null);

    const instockGlobalSearchValues = useSelector(({ instockReducer }) => instockReducer.instockGlobalSearchValues);
    const dateSearchValues = useSelector(({ instockReducer }) => instockReducer.instockDateRangeSearchValues);
    const dispatch = useDispatch();

    useEffect(() => {
        if (dateSearchValues.from_date !== "" && dateSearchValues.to_date !== "") {
            setfromDate(new Date(dateSearchValues.from_date));
            settoDate(new Date(dateSearchValues.to_date)); 
        }
    }, [dateSearchValues])

    const myCustomhandleResetFn = () => {
        const fromResetDate = getLastnthWeeksDate(13);
        const toResetDate = todayDate();
        dispatch(setDateRangeSearchValues({'from_date':fromResetDate, 'to_date':toResetDate}));
    }

    return (
        <Formik 
            initialValues={{
                from_date:getLastnthWeeksDate(13),
                to_date: todayDate(),
              }}
            validate={(values) => {
                const errors = {};
                const fromDateNew = new Date(values.from_date);
                const toDateNew = new Date(values.to_date);

                if (!values.from_date) {
                    errors.from_date_error = 'This field is required!';
                }
                if (!values.to_date) {
                    errors.to_date_error = 'This field is required!';
                }
                if ((values.from_date !== "" && values.to_date !== "") && (fromDateNew > toDateNew)) {
                    errors.from_date_error = 'To date should be greater than from date!';
                }
                return errors;
            }}
            onSubmit={values => {
                const { from_date, to_date } = values;
                dispatch(setDateRangeSearchValues(values));
                const searchValue = { ...values, ...cleanArrObject(instockGlobalSearchValues) }
                
                //Important 27 April 2023 commented the dispatch method as these are calling dremio queries 
                //UnComment when connected to connector dataource 
                var salesBySkuFilterVar = { 'customer':searchValue.customer, 'sku': searchValue.sku, 'dc': searchValue.dc, 'from_date': from_date, 'to_date': to_date, limit:5 };
                //dispatch(getSalesBySkuData(salesBySkuFilterVar));
                var salesByDcFilterVar = { 'sku': searchValue.sku, 'dc': searchValue.dc, 'from_date': from_date, 'to_date': to_date, limit:5 };
                //dispatch(getSalesByDcData(salesByDcFilterVar));
                var salesByStoreFilterVar = { 'sku': searchValue.sku, 'dc': searchValue.dc, 'brand': searchValue.brand, 'from_date': from_date, 'to_date': to_date, limit:5 };
                //dispatch(getSalesByStoreData(salesByStoreFilterVar));
           
            }}

            onReset={(values) => {

            }}

        >
            {({ values, errors, setFieldValue, handleSubmit, handleReset, setErrors }) => (
                <form className="form-sample" onSubmit={handleSubmit} onReset={handleReset}>
                    {/* <div className="d-flex justify-content-between align-items-center"> */}

                    <div className='row col-rows-5'>
                        <div className="col">
                            <h4 className="card-title">Total Sales</h4>
                        </div>
                        <div className="col">
                            {/* <Form.Group className="row">
                                <div className="col-sm-12">
                                    <label className="sr-only" htmlFor="inlineFormInputName2">From Date</label> */}
                                    <DatePicker className="form-control w-100"
                                        placeholderText='From Date'
                                        selected={fromDate}
                                        onChange={(val) => { setfromDate(val); setFieldValue('from_date', (val) ? val.toLocaleDateString('en-GB').replaceAll('/', '-').split('-').reverse().join('-') : '') }}
                                    />
                                    <div className='error'>{errors.from_date_error}</div>
                                {/* </div>

                            </Form.Group> */}

                        </div>
                        <div className="col">
                            <Form.Group className="row">
                                <div className="col-sm-12">
                                    <label className="sr-only" htmlFor="inlineFormInputName2">To Date</label>
                                    <DatePicker className="form-control w-100"
                                        placeholderText='To Date'
                                        selected={toDate}
                                        onChange={(val) => { settoDate(val); setFieldValue('to_date', (val) ? val.toLocaleDateString('en-GB').replaceAll('/', '-').split('-').reverse().join('-') : '') }}
                                    />
                                    <div className='error'>{errors.to_date_error}</div>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col d-flex mb-3 justify-content-between align-items-center">
                            <button type="button" className="btn btn-outline-secondary btn-sm"
                                onClick={() => { handleReset(); myCustomhandleResetFn(); }}
                            > Reset </button>
                            <button type="button" className="btn btn-gradient-danger btn-sm"
                                onClick={() => { handleSubmit(); }}
                            > Filter </button>
                        </div>
                    </div>
                    {/* </div> */}

                </form>
            )}
        </Formik>
    )
}   