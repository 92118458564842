import { commonTypes } from "../types/commonTypes";
import { instockTypes } from "../types/instockTypes";
import { removeItemOnce } from "../../utils/common-functions";

export default function (state = {}, action) {
  switch (action.type) {
    case commonTypes.SET_LOADING:
      var key = Object.keys(action.payload)[0];
      var value = action.payload[Object.keys(action.payload)[0]];
      return {
        ...state,
        isLoadingRequestIds: value
          ? [...state.isLoadingRequestIds, key]
          : removeItemOnce(state.isLoadingRequestIds, key), //state.isLoadingRequestIds.splice(state.isLoadingRequestIds.indexOf(key)).slice()
      };
    case instockTypes.SET_INVENTORY:
      return {
        ...state,
        inventoryData: action.payload,
      };
    case instockTypes.SET_SKU_LEVEL_DATA:
      return {
        ...state,
        skuLevelData: action.payload,
      };
    case instockTypes.SET_SEGMENT_LEVEL_DATA:
      return {
        ...state,
        segmentLevelData: action.payload,
      };
    case instockTypes.SET_DC_LEVEL_DATA:
      return {
        ...state,
        dcLevelData: action.payload,
      };
    case instockTypes.SET_STORE_LEVEL_DATA:
      return {
        ...state,
        storeLevelData: action.payload,
      };
    case instockTypes.SET_SALES_BY_SKU:
      return {
        ...state,
        salesBySkuData: action.payload,
      };
    case instockTypes.SET_SALES_BY_DC:
      return {
        ...state,
        salesByDcData: action.payload,
      };
    case instockTypes.SET_SALES_BY_STORE:
      return {
        ...state,
        salesByStoreData: action.payload,
      };
    case instockTypes.SET_GLOBAL_SEARCH_VALUES:
      return {
        ...state,
        instockGlobalSearchValues: action.payload,
      };
    case instockTypes.SET_INVENTORY_SEARCH_VALUES:
      return {
        ...state,
        instockInventorySearchValues: action.payload,
      };
    case instockTypes.SET_DATERANGE_SEARCH_VALUES:
      return {
        ...state,
        instockDateRangeSearchValues: action.payload,
      };
    case instockTypes.SET_MIN_MAX_SEARCH_VALUES:
      return {
        ...state,
        instockMinMaxSearchValues: action.payload,
      };
    case instockTypes.SET_SKU_LEVEL_TRENDS_DATA:
      return {
        ...state,
        skuLevelTrends: action.payload,
      };
    case instockTypes.SET_STOCK_STATISTICS:
      return {
        ...state,
        stockStatistics: action.payload,
      };
    default:
      return state;
  }
}
