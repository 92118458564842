import { commonTypes } from "../types/commonTypes";
import { instockTypes } from "../types/instockTypes";
import { POST } from "../../utils/axios";
import { getSocketStatus } from './socketActions';

export const setLoad = (isLoadingRequestIds) => {
  return {
    type: commonTypes.SET_LOADING,
    payload: isLoadingRequestIds,
  };
};


//  Feb 7 2023 - Handle inventory issue by pagination
export const getInventoryData = (postData,batch) => {
  return async (dispatch) => {
    dispatch(setLoad({ isInventoryDataLoaded: true }));
    const inventoryData = await getInventoryDataReq(postData, batch);
    dispatch(setInventoryData(inventoryData));
    dispatch(setLoad({ isInventoryDataLoaded: false }));
  };
};
const getInventoryDataReq = async (postData, queryParams) => {
  try {
    
    //const apiRes = await POST(`/inventory`, postData);
    //feb 7 2023 Not able to handle huge volume and hence limit pagination in tables 
    //const apiRes = await POST(`/inventory?queryParams=` + queryParams, postData);  

    //feb 9 2023 Not able to handle huge volume and hence limit pagination in tables 
    const queryString = '?batch=' + queryParams.batch + '&batchSize=' + queryParams.batchSize + '&download=' + queryParams.download;
    const apiRes = await POST(`/inventory` + queryString , postData);
    
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {  
      if(queryParams.download == false && apiRes.data?.result)
          return apiRes.data?.result;
      else if(queryParams.download == true && apiRes.data?.downloadUrl)
          return apiRes.data?.downloadUrl;
    }else if (apiRes.status.statusCode !== "200") {
      return apiRes.errorMessage;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getInventoryDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("Inventory API ERROR: ", err);
    return [];
  }
};
export const setInventoryData = (inventoryData) => {
  return {
    type: instockTypes.SET_INVENTORY,
    payload: inventoryData,
  };
};


//Inventory data
// export const getInventoryData = (postData) => {
//   console.log("postData is",postData)
//   return async (dispatch) => {
//     dispatch(setLoad({ isInventoryDataLoaded: true }));
//     const inventoryData = await getInventoryDataReq(postData);
//     dispatch(setInventoryData(inventoryData));
//     dispatch(setLoad({ isInventoryDataLoaded: false }));
//   };
// };
// const getInventoryDataReq = async (postData) => {
//   try {
//     const apiRes = await POST(`/inventory`, postData);
//     if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
//       return apiRes.data?.result;
//     } else {
//       let socketRetData = await getSocketStatus(apiRes.key);
//       if (socketRetData.jobState === "COMPLETED") {
//         return await getInventoryDataReq(postData);
//       } else if (socketRetData.jobState === "FAILED") {
//         return socketRetData.errorMessage;
//       }
//     }
//     return [];
//   } catch (err) {
//     console.log("Inventory API ERROR: ", err);
//     return [];
//   }
// };
// export const setInventoryData = (inventoryData) => {
//   return {
//     type: instockTypes.SET_INVENTORY,
//     payload: inventoryData,
//   };
// };

export const getInventoryDataForExport = async (postData, queryParams) => {
  const inventoryData = await getInventoryDataReq(postData, queryParams);
  return inventoryData;
};

//Sku level data
export const getSkuLevelData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isSkuLevelDataLoaded: true }));
    const skuData = await getSkuLevelDataReq(postData);
    dispatch(setSkuLevelData(skuData));
    dispatch(setLoad({ isSkuLevelDataLoaded: false }));
  };
};
const getSkuLevelDataReq = async (postData) => {
  try {
    const apiRes = await POST(`/skulevel`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getSkuLevelDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("Skulevel API ERROR: ", err);
    return [];
  }
};
export const setSkuLevelData = (skuLevelData) => {
  return {
    type: instockTypes.SET_SKU_LEVEL_DATA,
    payload: skuLevelData,
  };
};

export const getSkuDataForExport = async (postData) => {
  const skuData = await getSkuLevelDataReq(postData);
  return skuData;
};

//Segment Level data
export const getSegmentLevelData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isSegmentLevelDataLoaded: true }));
    const apires = await getSegmentLevelDataReq(postData);
    dispatch(setSegmentLevelData(apires));
    dispatch(setLoad({ isSegmentLevelDataLoaded: false }));
  };
};
const getSegmentLevelDataReq = async (postData) => {
  try {
    const apiRes = await POST(`/segmentlevel`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getSegmentLevelDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("Segmentlevel ERROR: ", err);
    return [];
  }
};
export const setSegmentLevelData = (segmentLevelData) => {
  return {
    type: instockTypes.SET_SEGMENT_LEVEL_DATA,
    payload: segmentLevelData,
  };
};

export const getSegmentLevelDataForExport = async (postData) => {
  const data = await getSegmentLevelDataReq(postData);
  return data;
};

//DC level data
export const getDcLevelData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isDcLevelDataLoaded: true }));
    const serverRes = await getDcLevelDataReq(postData);
    dispatch(setDcLevelData(serverRes));
    dispatch(setLoad({ isDcLevelDataLoaded: false }));
  };
};
const getDcLevelDataReq = async (postData) => {
  try {
    const apiResult = await POST(`/dclevel`, postData);
    if (apiResult.status.statusCode === "200") {
      return apiResult.data;
    }
    return [];
  } catch (err) {
    return [];
  }
};
export const setDcLevelData = (payloadData) => {
  return {
    type: instockTypes.SET_DC_LEVEL_DATA,
    payload: payloadData,
  };
};

export const getDcDataForExport = async (postData) => {
  const apiResult = await getDcLevelDataReq(postData);
  return apiResult;
};

//Store level data
export const getStoreLevelData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isStoreLevelDataLoaded: true }));
    const serverRes = await getStoreLevelDataReq(postData);
    dispatch(setStoreLevelData(serverRes));
    dispatch(setLoad({ isStoreLevelDataLoaded: false }));
  };
};
const getStoreLevelDataReq = async (postData) => {
  try {
    const apiResult = await POST(`/storelevel`, postData);
    if (apiResult.status.statusCode === "200") {
      return apiResult.data;
    }
    return [];
  } catch (err) {
    return [];
  }
};
export const setStoreLevelData = (payloadData) => {
  return {
    type: instockTypes.SET_STORE_LEVEL_DATA,
    payload: payloadData,
  };
};

export const getStoreDataForExport = async (postData) => {
  const apiResult = await getStoreLevelDataReq(postData);
  return apiResult;
};

//Sales by SKU
export const getSalesBySkuData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isSalesBySkuDataLoaded: true }));
    const salesbyskuData = await getSalesBySkuDataReq(postData);
    dispatch(setSalesBySkuData(salesbyskuData));
    dispatch(setLoad({ isSalesBySkuDataLoaded: false }));
  };
};
const getSalesBySkuDataReq = async (postData) => {
  try {
    const apiRes = await POST(`/salesbysku`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getSalesBySkuDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("Salesbysku ERROR: ", err);
    return [];
  }
};
export const setSalesBySkuData = (salesBySkuData) => {
  return {
    type: instockTypes.SET_SALES_BY_SKU,
    payload: salesBySkuData,
  };
};

export const setSalesBySkuDataForFilter = (salesBySkuData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isSalesBySkuDataLoaded: true }));
    dispatch(setSalesBySkuData(salesBySkuData));
    setTimeout(() => {
      dispatch(setLoad({ isSalesBySkuDataLoaded: false }));
    }, 3000);
  };
};
//Sales by DC
export const getSalesByDcData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isSalesByDcDataLoaded: true }));
    const salesbydcData = await getSalesByDcDataReq(postData);
    dispatch(setSalesByDcData(salesbydcData));
    dispatch(setLoad({ isSalesByDcDataLoaded: false }));
  };
};
const getSalesByDcDataReq = async (postData) => {
  try {
    const apiRes = await POST(`/salesbydc`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getSalesByDcDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("Salesbydc ERROR: ", err);
    return [];
  }
};
export const setSalesByDcData = (salesByDcData) => {
  return {
    type: instockTypes.SET_SALES_BY_DC,
    payload: salesByDcData,
  };
};

export const setSalesByDcDataForFilter = (salesByDcData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isSalesByDcDataLoaded: true }));
    dispatch(setSalesByDcData(salesByDcData));
    setTimeout(() => {
      dispatch(setLoad({ isSalesByDcDataLoaded: false }));
    }, 3000);
  };
};
//Sales by Store
export const getSalesByStoreData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isSalesByStoreDataLoaded: true }));
    const salesbystoreData = await getSalesByStoreDataReq(postData);
    dispatch(setSalesByStoreData(salesbystoreData));
    dispatch(setLoad({ isSalesByStoreDataLoaded: false }));
  };
};
const getSalesByStoreDataReq = async (postData) => {
  try {
    // const postData = {
    //     "sku": [
    //         "3112625"
    //     ],
    //     "store": [
    //         "TG_2254",
    //         "TG_2548",
    //         "TG_1105"
    //     ],
    //     "from_date": "01-04-2022",
    //     "to_date": "01-05-2022"
    // }
    const apiRes = await POST(`/salesbystore`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getSalesByStoreDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("Salesbystore ERROR: ", err);
    return [];
  }
};
export const setSalesByStoreData = (salesByStoreData) => {
  return {
    type: instockTypes.SET_SALES_BY_STORE,
    payload: salesByStoreData,
  };
};

export const setSalesByStoreDataForFilter = (salesByStoreData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isSalesByStoreDataLoaded: true }));
    dispatch(setSalesByStoreData(salesByStoreData));
    setTimeout(() => {
      dispatch(setLoad({ isSalesByStoreDataLoaded: false }));
    }, 3000);
  };
};

export const setGlobalSearchValues = (searchValues) => {
  return {
    type: instockTypes.SET_GLOBAL_SEARCH_VALUES,
    payload: searchValues,
  };
};

export const setDateRangeSearchValues = (instockDateRangeSearchValues) => {
  return {
    type: instockTypes.SET_DATERANGE_SEARCH_VALUES,
    payload: instockDateRangeSearchValues,
  };
};

export const setInventorySearchValues = (instockInventorySearchValues) => {
  return {
    type: instockTypes.SET_INVENTORY_SEARCH_VALUES,
    payload: instockInventorySearchValues,
  };
};

export const setMinMaxSearchValues = (instockMinMaxSearchValues) => {
  return {
    type: instockTypes.SET_MIN_MAX_SEARCH_VALUES,
    payload: instockMinMaxSearchValues,
  };
};

// SKU Level Trends Data
export const getSkuLevelTrendData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isSkuLevelTrendLoaded: true }));
    const SkuLevelTrendData = await getSkuLevelTrendDataReq(postData);
    dispatch(setSkuLevelTrendData(SkuLevelTrendData));
    dispatch(setLoad({ isSkuLevelTrendLoaded: false }));
  };
};
const getSkuLevelTrendDataReq = async (postData) => {
  try {
    const apiRes = await POST(`/avgsalespw`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getSkuLevelTrendDataReq(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    console.log("Avgsalespw KPI ERROR: ", err);
    return [];
  }
};
export const setSkuLevelTrendData = (skuLevelTrends) => {
  return {
    type: instockTypes.SET_SKU_LEVEL_TRENDS_DATA,
    payload: skuLevelTrends,
  };
};

// Stock Statistics Data
export const getStockStatisticsData = (postData) => {
  return async (dispatch) => {
    dispatch(setLoad({ isStockStatisticsLoaded: true }));
    const StockStatisticsData = await getStockStatisticsDataReq(postData);
    dispatch(setStockStatisticsData(StockStatisticsData));
    dispatch(setLoad({ isStockStatisticsLoaded: false }));
  };
};
const getStockStatisticsDataReq = async (postData) => {
  try {
    
    const StockStatisticsData1 = await getStockStatisticsDataReq1(postData);
    const StockStatisticsData2 = await getStockStatisticsDataReq2(postData);
    const finalData = { ...StockStatisticsData1, ...StockStatisticsData2 }
    return finalData;
  } catch (err) {
    return [];
  }
};

const getStockStatisticsDataReq1 = async (postData) => {
  try {
    const apiRes = await POST(`/storeInventoryKpi`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getStockStatisticsDataReq1(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    return [];
  }
};

const getStockStatisticsDataReq2 = async (postData) => {
  try {
    const apiRes = await POST(`/inventoryDistributionKpi`, postData);
    if (apiRes.status.statusCode === "200" && apiRes.socketStatus === "COMPLETED") {
      return apiRes.data?.result;
    } else {
      let socketRetData = await getSocketStatus(apiRes.key);
      if (socketRetData.jobState === "COMPLETED") {
        return await getStockStatisticsDataReq2(postData);
      } else if (socketRetData.jobState === "FAILED") {
        return socketRetData.errorMessage;
      }
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const setStockStatisticsData = (stockStatistics) => {
  return {
    type: instockTypes.SET_STOCK_STATISTICS,
    payload: stockStatistics,
  };
};
